import { ILeaderboardWithWinnerDto } from "interfaces";
import { useAppContext } from "contexts";
import { useQuery } from "@tanstack/react-query";

interface useLeaderboardWithWinnersParams {
    casinoProvider: string,
    take?: number,
    skip?: number
}

export default function useLeaderboardWithWinners({ casinoProvider, take = 3, skip = 0 }: useLeaderboardWithWinnersParams) {
    const { accessFetch } = useAppContext()

    return useQuery<{ items: ILeaderboardWithWinnerDto[], total: number }>({
        queryKey: ['leaderboards-with-winners', { casinoProvider, skip, take }],
        queryFn: async () => {
            try {
                const response = await accessFetch(`/leaderboard/list-winner/${casinoProvider}/public?skip=${skip}&take=${take}`);

                const responseData = await response?.json();

                return responseData?.items ? responseData : { items: [], total: 0 }

            } catch (error) {
                console.error('Error fetching data:', error)
                throw new Error('Error fetching data')
            }
        },
        retry: false,
        enabled: !!casinoProvider,
        placeholderData: (previousData) => previousData ?? { items: [], total: 0 },
        initialData: { items: [], total: 0 },
    })
}


