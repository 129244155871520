import React from "react";
import styled, { css } from "styled-components";
import Link from "next/link";
import { TheLeaderboardSourceKeys } from "interfaces";
import Image from "next/image";

import { useDisclosure } from "@chakra-ui/react";
import ChakraModal from "components/TheComponents/Modal";
import LeaderboardLearnMoreLayout from "./LeaderboardLearnMoreLayout";

interface RandomPrize {
  amount: number;
  prizeNumber: number;
}

interface LeaderboardReferralBlockProps {
  casinoProvider: string;
  prizeSum?: number;
  randomWinnersCount?: number;
  randomPrizeThreshold?: number;
  randomPrizes: RandomPrize[];
  additionalPrizesCount: number;
}

const getDynamicValues = (provider: string) => {
  switch (provider) {
    case TheLeaderboardSourceKeys.hypedrop:
      return {
        wgtvHref: "https://hypedrop.com/r/WGTV",
        watchGamesTVHref: "https://hypedrop.com/r/watchgamestv",
        providerHref: "https://hypedrop.com/",
        providerName: "Hypedrop",
        wagerName: "Deposit",
      };
    case TheLeaderboardSourceKeys.gamdom:
      return {
        wgtvHref: "https://gamdom.com/r/wgtv",
        watchGamesTVHref: "https://gamdom.com/r/watchgamestv",
        providerHref: "https://gamdom.com/",
        providerName: "PackDraw",
        wagerName: "Wager",
      };
    case TheLeaderboardSourceKeys.packdraw:
      return {
        wgtvHref: "https://packdraw.com/?ref=wgtv",
        watchGamesTVHref: "https://packdraw.com/?ref=watchgamestv",
        providerHref: "https://packdraw.com/",
        providerName: "Gamdom",
        wagerName: "Wager",
      };

    default:
      return {
        wgtvHref: "/",
        watchGamesTVHref: "/",
        providerHref: "/",
        providerName: "WRewards",
        wagerName: "Wager",
      };
  }
};

const LeaderboardReferralBlock: React.FC<LeaderboardReferralBlockProps> = ({
  casinoProvider,
  prizeSum = 0,
  randomWinnersCount = 0,
  randomPrizeThreshold = 0,
  randomPrizes,
  additionalPrizesCount,
}) => {
  const { watchGamesTVHref, wgtvHref, wagerName } = getDynamicValues(casinoProvider);
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Block>
      <Wrapper>
        <Title>
          WORRIED YOU WILL NOT QUALIFY
          <br />
          <b>FOR OUR TOP PLAYERS?</b>
        </Title>
        <ContentBox>
          <div>
            <ImageFrame>
              <Image src="/images/leaderboard/referal_content_frame.png" fill alt="image" />
            </ImageFrame>
          </div>
          <div>
            <ContentText>
              WE ARE GIVING AWAY A TOTAL OF
              <br />
              <b>${prizeSum}</b>
              <br />
              at random and SPLIT <br /> BETWEEN  <span>{randomWinnersCount} USERS</span> WHO 
              {wagerName} <br /> AT LEAST <span>${randomPrizeThreshold}</span>
            </ContentText>
          </div>
        </ContentBox>
        {casinoProvider !== TheLeaderboardSourceKeys.wrewards ? (
          <div>
            <ContentText>
              Using code
              <Link
                target="_blank"
                href={wgtvHref}
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(wgtvHref);
                }}
              >
                WGTV
                <Image src="/images/icons/copy.svg" height={14} width={14} alt="image" />
              </Link>
              {` or `}
              <Link
                target="_blank"
                href={watchGamesTVHref}
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(watchGamesTVHref);
                }}
              >
                WatchGamesTV
                <Image src="/images/icons/copy.svg" height={14} width={14} alt="image" />
              </Link>
            </ContentText>
          </div>
        ) : null}
        <Button onClick={() => onOpen()}>
          <Image src="/images/icons/question.svg" width={24} height={24} alt="image" />
          Learn More
        </Button>
        <ChakraModal
          title="How it works"
          isOpen={isOpen}
          onClose={onClose}
          content={
            <LeaderboardLearnMoreLayout
              prizeSum={prizeSum}
              randomPrizes={randomPrizes}
              additionalPrizesCount={additionalPrizesCount}
              randomWinnersCount={randomWinnersCount}
              randomPrizeThreshold={randomPrizeThreshold}
              isHypedrop={casinoProvider === TheLeaderboardSourceKeys.hypedrop}
            />
          }
        />
      </Wrapper>
    </Block>
  );
};

const Block = styled.div`
  background: linear-gradient(351.86deg, rgba(48, 38, 23, 0.25) 7.08%, rgba(23, 19, 12, 0) 111.81%),
    url("/images/leaderboard/referal_bg.svg");
  background-size: cover;
  @media (max-width: 900px) {
    background-size: contain;
  }
`;

const Wrapper = styled.div`
  max-width: 1008px;
  margin: 0 auto;
  padding: 5rem 0;
`;

const Title = styled.h5`
  font-family: Roboto;
  font-size: 2rem;
  margin: 0px 0px 80px;
  text-transform: uppercase;
  font-style: italic;
  font-weight: 900;
  line-height: 150%;
  text-align: center;
  color: #e9f4ff;
  & b {
    background: radial-gradient(
      106.62% 263.76% at 51.89% 59.34%,
      #ebb52a 0%,
      #f4da4f 30%,
      #ffec87 50%,
      #f4da4f 70%,
      rgba(224, 151, 40, 0.15) 100%
    );

    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const ContentBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 3rem;
  margin-bottom: 5rem;
`;

const ContentText = styled.p`
  font-family: "Golos Text";
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 150%;
  text-align: center;
  & b {
    font-family: "Roboto";
    padding: 0 1rem;
    font-style: italic;
    font-size: 144px;
    line-height: normal;
    background: radial-gradient(
      106.62% 263.76% at 51.89% 59.34%,
      #ebb52a 0%,
      #f4da4f 30%,
      #ffec87 50%,
      #f4da4f 70%,
      rgba(224, 151, 40, 0.15) 100%
    );

    background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 600px) {
      font-size: 80px;
    }
  }

  & span {
    color: #ecbe49;
  }

  & a {
    margin: 0.375rem;
    text-transform: uppercase;
    color: #3b8be9;
    display: inline-flex;
    align-items: center;
    border: 1px solid #e9f4ff06;
    border-radius: 4px;
    background: #e9f4ff0d;
    padding: 0 0.5rem;
    gap: 6px;
    &:hover {
      opacity: 0.8;
    }
  }
`;

const ImageFrame = styled.div`
  position: relative;
  max-width: 500px;
  min-width: 300px;
  min-height: 270px;
  width: 100%;
  height: auto;
  & > img {
    width: 100%;
    object-fit: contain;
  }
`;

const Button = styled.button`
  margin: 2.5rem auto 0;
  padding: 0.875rem 1.5rem;
  background: #e9f4ff0d;
  border: 1px solid #e9f4ff0d;
  text-transform: uppercase;
  font-family: "Golos Text";
  font-weight: 600;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #e9f4ffbf;
  &:hover {
    opacity: 0.8;
  }
`;

export default LeaderboardReferralBlock;
