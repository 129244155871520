import React, { useState } from "react";
import styled, { css } from "styled-components";
import {
  $stylesReset,
  IStyles,
  Ident,
  LeaderboardWinnerCardList,
  LeaderboardEndsIn,
  LeaderboardUsersTable,
} from "components";
import { IDtoLeaderboard, IDtoLeaderboardUser, TheLeaderboardSourceKeys } from "interfaces";
import Image from "next/image";
import { Button, ButtonBlue, Flex, NoEntitiesText } from "components/common";
import { useRouter } from "next/router";
import { ShowMoreButton } from "./LeaderboardRandomWinnersDisplay";
import { PRIZES_OUTSIDE_ADDITIONAL_PRIZES_COUNT } from "helpers/constants";
import { scrollToElement } from "helpers/scrollToElement";
import { Fade } from "react-awesome-reveal";
import { SlideSwitcher } from "components";
import LeaderboardReferralBlock from "./LeaderboardReferralBlock";
import LeaderboardDisclaimer from "./LeaderboardDisclaimer";

import CoinsBG from "../../../public/images/leaderboard/LeaderboardWinnerBlock/bg-wrewards-coins.svg";
import ClashBG from "../../../public/images/leaderboard/LeaderboardWinnerBlock/bg-clash.svg";
import CasesBG from "../../../public/images/leaderboard/LeaderboardWinnerBlock/bg-cases.svg";
import CasesBG2 from "../../../public/images/leaderboard/LeaderboardWinnerBlock/bg-cases-2.svg";
import { formatNumber } from "./utils/formatNumbers";
import { useAppContext } from "contexts";
import { Spinner } from "@chakra-ui/react";
import { animated, useSpring } from "@react-spring/web";

export const casinoProviderBackgrounds = {
  [TheLeaderboardSourceKeys.gamdom]: css`
    background: linear-gradient(180deg, rgba(11, 14, 22, 0) 0%, rgba(22, 101, 36, 0.2) 100%),
      url("/images/leaderboard/LeaderboardWinnerBlock/bg-gambom.svg");
    background-size: 100% auto;
    @media (min-width: 3200px) {
      background-size: cover;
    }
  `,
  [TheLeaderboardSourceKeys.hypedrop]: css`
    background: linear-gradient(
        180deg,
        rgba(9, 12, 23, 0.2) 1.84%,
        rgba(48, 71, 106, 0.2) 91.27%,
        rgba(19, 24, 40, 0.2) 139.18%,
        rgba(19, 24, 40, 0.2) 139.18%
      ),
      url("/images/leaderboard/LeaderboardWinnerBlock/bg-hypedrop.svg");
    background-size: 100% auto;
  `,
  [TheLeaderboardSourceKeys.packdraw]: css`
    background: linear-gradient(to bottom, rgba(29, 22, 51, 1) 0%, rgba(29, 22, 51, 0) 100%),
      url("/images/leaderboard/LeaderboardWinnerBlock/bg-packdraw.png");
    background-size: 100% auto;
    @media (max-width: 900px) {
      background-size: contain;
    }
  `,
  [TheLeaderboardSourceKeys.wrewards]: css`
    background: radial-gradient(
        100.64% 130.44% at 51.78% -11.91%,
        rgba(29, 34, 52, 0.46) 0%,
        rgba(29, 34, 52, 0) 57.53%
      ),
      url("/images/leaderboard/LeaderboardWinnerBlock/bg-wrewards-2.png"),
      url("/images/leaderboard/LeaderboardWinnerBlock/bg-wrewards-3.png");
    background-size: initial;
    background-position: center center;
    z-index: -1;
  `,
  [TheLeaderboardSourceKeys.clash]: css`
    background: linear-gradient(180deg, rgba(255, 168, 0, 0.12) 0%, rgba(255, 168, 0, 0) 100%);
    background-size: contain;
    z-index: -1;
  `,
};

export interface ILeaderboardWinnersBlock {
  title?: string;
  switcher: string;
  setSwitcher: (switcher: string) => void;
  setTake?: React.Dispatch<React.SetStateAction<number>>;
  leaderboard?: IDtoLeaderboard | null;
  leaderboardWinners: IDtoLeaderboardUser[];
  setLeaderboardUsers?: React.Dispatch<React.SetStateAction<IDtoLeaderboardUser[]>>;
  leaderboardUsers: IDtoLeaderboardUser[];
  showBlockHeader?: boolean;
  showSwitcher?: boolean;
  isEnded?: boolean;
  take?: number;
  showLeaderboardEndsIn?: boolean;
  showLeaderboardUserList?: boolean;
  showIntervalUpdate?: boolean;
  showActiveLeaderboardButton?: boolean;
  showTableSpinner?: boolean;
  currentUserPosition?: IDtoLeaderboardUser;
}

export const LeaderboardWinnersBlock: React.FC<ILeaderboardWinnersBlock> = ({
  switcher = TheLeaderboardSourceKeys.gamdom,
  setSwitcher,
  leaderboard,
  isEnded,
  leaderboardWinners,
  leaderboardUsers,
  currentUserPosition,
  setTake,
  take,
  showBlockHeader = false,
  showSwitcher = true,
  showLeaderboardEndsIn = false,
  showLeaderboardUserList = false,
  showActiveLeaderboardButton = false,
  showTableSpinner,
}): React.ReactElement => {
  const { isFeatureEnabled } = useAppContext();
  const router = useRouter();

  const randomPrizes = leaderboard?.randomPrizes;
  const randomPrizesCount = randomPrizes?.length;
  const randomPrizeThreshold = leaderboard?.randomPrizeThreshold;
  const maxPublicUsersCount = leaderboard?.maxPublicUsersCount;

  const additionalPrizesCount =
    (leaderboard?.additionalPrizes?.length || 0) + PRIZES_OUTSIDE_ADDITIONAL_PRIZES_COUNT;

  const maxPublicUsersToDisplay =
    (maxPublicUsersCount || 0) > additionalPrizesCount
      ? maxPublicUsersCount
      : additionalPrizesCount;

  const prizeSum =
    randomPrizes?.reduce(
      (sum: number, prize: { amount: number; prizeNumber: number }) => sum + prize.amount,
      0
    ) || 0;

  const onSeeMore = () => {
    if (setTake && maxPublicUsersToDisplay) {
      setTake(maxPublicUsersToDisplay);
    }
  };

  const leaderboardUsersTableBoxItemId = "leaderboardUsersTableBoxItem";

  const collapseWinners = () => {
    if (setTake) {
      setTake(10);
      scrollToElement(leaderboardUsersTableBoxItemId);
    }
  };

  const isAllWinnersShown = take && leaderboard?.maxPublicUsersCount ? take > 10 : false;

  const toShowCollapseButton = (leaderboard?.maxPublicUsersCount ?? 0) >= 10;

  return (
    <>
      <WrapperBG>
        <ProviderAnimatedBG casinoProvider={switcher} />

        <Box casinoProvider={switcher}>
          {showBlockHeader ? (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Ident height={54} />
              <BoxItem>
                <Image
                  style={{ marginTop: 2 }}
                  src="/images/HeaderComponent/logo.svg"
                  height={32}
                  width={160}
                  alt="image"
                />
              </BoxItem>
              <Ident height={16} />
              <TextGradient>Wager Leaderboard</TextGradient>
              {leaderboard ? (
                <Fade direction="left" delay={330} triggerOnce>
                  <TotalPrize>
                    <span>$</span>
                    <AnimatedNumber number={leaderboard?.totalPrizePool ?? 0} />
                    {/* {formatNumber()} */}
                  </TotalPrize>
                  <Subtitle>Total Prize pool</Subtitle>
                </Fade>
              ) : null}
              <Ident height={40} />
            </div>
          ) : null}
          <BoxItem>
            {showSwitcher ? (
              <>
                <Fade direction="right" delay={330} triggerOnce>
                  <SlideSwitcher
                    title={<>CHOOSE LEADERBOARD</>}
                    value={switcher}
                    onChange={setSwitcher}
                    centerSwitcher={true}
                    isGridView={true}
                    styles={css`
                      margin: 0 auto;
                      `}
                  />
                </Fade>
              </>
            ) : null}
          </BoxItem>
          <Ident height={37} />

          <BoxItem>
            <LeaderboardDisclaimer casinoProvider={switcher} />
          </BoxItem>

          <BoxItem>
            {leaderboard && leaderboardWinners?.length ? (
              <LeaderboardWinnerCardList
                leaderboard={leaderboard}
                leaderboardUsers={leaderboardWinners}
                customWinnerRewardText={
                  switcher === TheLeaderboardSourceKeys.wrewards &&
                  isFeatureEnabled("ENABLE_CUSTOM_WINNER_REWARD") ? (
                    <CustomRewards>
                      <small>Spend my</small>
                      <span>$30,000 </span>
                      <small>(keep 25%) video</small>
                    </CustomRewards>
                  ) : null
                }
              />
            ) : (
              // hide coming soon for hypedrop when disaclaimer shown
              <>
                {switcher !== TheLeaderboardSourceKeys.hypedrop ? (
                  <NoEntitiesText>Coming soon</NoEntitiesText>
                ) : null}
              </>
            )}
          </BoxItem>

          <Ident height={40} />
          {showLeaderboardEndsIn && leaderboard ? (
            <BoxItem styles={cssList}>
              <LeaderboardEndsIn value={leaderboard?.endDate ?? null} />
              <Ident height={38} />
            </BoxItem>
          ) : null}
          {showLeaderboardUserList && leaderboard && leaderboardUsers?.length ? (
            <BoxItem id={leaderboardUsersTableBoxItemId} styles={cssList}>
              <LeaderboardUsersTable
                $keys={["#", "displayName", "value", "prize"]}
                leaderboard={leaderboard}
                leaderboardUsers={leaderboardUsers}
                usersSkip={3}
                usersTake={12}
              />
              {currentUserPosition && switcher === TheLeaderboardSourceKeys.wrewards ? (
                <LeaderboardUsersTable
                  $keys={["#", "displayName", "value", "prize"]}
                  leaderboard={leaderboard}
                  leaderboardUsers={[currentUserPosition]}
                  isSingleUser
                />
              ) : null}
              {/* TODO fix more item button */}
              <FlexWrapper>
                {showTableSpinner ? <Spinner size={"xl"} /> : null}
                {toShowCollapseButton ? (
                  <ShowMoreButton
                    onClick={() => {
                      if (isAllWinnersShown) {
                        collapseWinners();
                      } else {
                        onSeeMore();
                      }
                    }}
                  >
                    <Image
                      src="/images/LeaderboardComponent/eye.png"
                      alt="plus"
                      width={20}
                      height={20}
                    />
                    <span>{isAllWinnersShown ? "Hide" : "Show More"}</span>
                  </ShowMoreButton>
                ) : null}
              </FlexWrapper>
            </BoxItem>
          ) : showLeaderboardUserList ? (
            <Ident height={70} />
          ) : null}

          {showActiveLeaderboardButton ? (
            <>
              <Ident height={27} />
              <BoxItem>
                <ExploreText>Explore the entire</ExploreText>
                <br />
                <ExploreText>leaderboard and potential prizes!</ExploreText>
              </BoxItem>
              <Ident height={24} />
              <BoxItem></BoxItem>
              <Flex width="100%" justify="center">
                <Button
                  isDark
                  style={{ height: 50, width: 150, whiteSpace: "nowrap" }}
                  onClick={(event: React.MouseEvent) => {
                    event.preventDefault();
                    router.push("/leaderboards/active");
                  }}
                >
                  <ButtonBlue isDark style={{ whiteSpace: "nowrap" }} transparent>
                    Open leaderboard
                  </ButtonBlue>
                </Button>
              </Flex>
              <Ident height={20} />
            </>
          ) : null}
          <Ident height={23} />
        </Box>
      </WrapperBG>
      {!isEnded && randomPrizes && randomPrizesCount ? (
        <LeaderboardReferralBlock
          casinoProvider={switcher}
          prizeSum={prizeSum}
          randomWinnersCount={randomPrizesCount}
          randomPrizeThreshold={randomPrizeThreshold}
          randomPrizes={randomPrizes}
          additionalPrizesCount={additionalPrizesCount}
        />
      ) : null}
    </>
  );
};

export const AnimatedNumber: React.FC<{ number: number }> = ({ number }) => {
  const { animatedNumber } = useSpring({
    from: { animatedNumber: 0 },
    animatedNumber: number,
    delay: 0,
    config: {
      mass: 1,
      tension: 10,
      friction: 100,
      duration: 1500,
    },
  });

  return <animated.b>{animatedNumber.to((n) => formatNumber(+n.toFixed(0)))}</animated.b>;
};

const ProviderAnimatedBG: React.FC<{ casinoProvider: string }> = ({ casinoProvider }) => {
  switch (casinoProvider) {
    case TheLeaderboardSourceKeys.wrewards:
      return (
        <AnimationWrapper>
          <CoinsBG />
        </AnimationWrapper>
      );
    case TheLeaderboardSourceKeys.clash:
      return (
        <AnimationWrapper>
          <ClashBG />
        </AnimationWrapper>
      );
    case TheLeaderboardSourceKeys.cases:
      return (
        <AnimationWrapper>
          <CasesBG />
          <CasesBG2 />
        </AnimationWrapper>
      );

    default:
      return <></>;
  }
};

const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem 0;
  position: relative;
`;

const CustomRewards = styled.div`
  padding: 10px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
  line-height: normal;
  small {
    font-size: 14px;
  }
  & > span {
    font-size: 32px;
    &::first-letter {
      color: #5ebd3d;
    }
  }
`;

const AnimationWrapper = styled.div`
  width: 100%;
  height: auto;
  position: absolute;
  inset: 0;
  /* max-width: 1440px; */
  margin: 0 auto;
  svg {
    position: absolute;
    inset: 0;
    width: 100%;
  }

  & g {
    z-index: 1;
    animation: 4.3s ease-in-out 0s infinite normal none running float;

    &:nth-child(odd) {
      animation-duration: 3s;
    }
  }

  & g.pulse {
    z-index: 1;
    animation: 4.3s ease-in-out 0s infinite normal none running smoothlyDisappear;

    &:nth-child(odd) {
      animation-duration: 3s;
    }
  }
  /* TODO replace object */
  /* b-r */
  /* & g[data-id="1"] {
    offset-path: path(M169.434628,85.98385 C302.674368,57.724682 515.410679,111.53943 604,179);
    animation: 4s cubic-bezier(0.42, 0, 0.58, 1) 0s infinite normal none running float;
  } */
  /* b-l */
  /* & g[data-id="2"] {
    animation: 4s ease-in-out 0s infinite normal none running float;
  } */
  /* m-l */
  /* & g[data-id="3"] {
    animation: 4s ease-in-out 0s infinite normal none running float;
  } */
  /* s-r */
  /* & g[data-id="4"] {
    animation: 4s ease-in-out 0s infinite normal none running float;
  } */
  /* s-l */
  /* & g[data-id="5"] {
    animation: 4s ease-in-out 0s infinite normal none running float;
  } */
  /* m-r */
  /* & g[data-id="6"] {
    animation: 4s ease-in-out 0s infinite normal none running float;
  } */
`;

const WrapperBG = styled.div`
  position: relative;
`;

const cssList = css`
  max-width: 1008px;
  align-items: left;
  margin: 0 auto;
`;

const Box = styled.div<IStyles & { casinoProvider?: string }>`
  box-sizing: border-box;
  ${({ casinoProvider }) => casinoProvider && casinoProviderBackgrounds[casinoProvider]}
  background-position: top center;
  background-repeat: no-repeat;
  background-clip: border-box;
  z-index: 1;
  ${({ styles }) => styles}
`;

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  text-align: center;
  ${({ styles }) => styles}
`;

const ExploreText = styled.div`
  color: #d2eaff;
  font-family: "Roboto";
  font-size: 18px;
  font-style: italic;
  font-weight: 800;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
`;

const Text = styled.div`
  ${$stylesReset}
  color: rgba(210, 234, 255, 0.75);
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: center;
`;

const Subtitle = styled(Text)`
  text-transform: uppercase;
  font-size: 14px;
`;

const TotalPrize = styled(Text)`
  font-size: 6rem;
  font-style: italic;
  color: #e9f4ff;

  span {
    background: linear-gradient(
      88.51deg,
      #2b7ae2 -11.93%,
      #4fc9f4 32.84%,
      #87e9ff 44.22%,
      #4fd1f4 55.23%,
      #2884e0 100%
    );

    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const TextGradient = styled(Text)`
  text-transform: uppercase;
  font-weight: 900;
  font-size: 48px;
  font-style: italic;
  background: linear-gradient(
    88.51deg,
    #2b7ae2 -11.93%,
    #4fc9f4 32.84%,
    #87e9ff 44.22%,
    #4fd1f4 55.23%,
    #2884e0 100%
  );

  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

// const HowToPlayButton = styled.button`
//   margin: 2.5rem auto 0;
//   padding: 0.875rem 1.5rem;
//   background: #e9f4ff0d;
//   border: 1px solid #e9f4ff0d;
//   text-transform: uppercase;
//   font-family: "Golos Text";
//   font-weight: 600;
//   border-radius: 6px;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   gap: 0.5rem;
//   color: #e9f4ffbf;
//   &:hover {
//     opacity: 0.8;
//   }
// `;
