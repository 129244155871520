import React from "react";
import styled from "styled-components";
import { $stylesReset, IStyles } from "./$styles";
import { fixed, fixedRound } from "helpers";

export interface ITotalGivenReward {
  img?: JSX.Element;
  title?: string;
  value?: number;
}

export const TotalGivenReward: React.FC<ITotalGivenReward & IStyles> = ({
  img,
  title = "?",
  value = 0,
  styles,
}): JSX.Element => {
  const rounded = React.useMemo(() => {
    const format = "0,0";
    const rounded = fixedRound(value);
    return fixed(format, rounded, 1);
  }, [value]);

  return (
    <Box styles={styles}>
      <Icon>{img}</Icon>
      <Data>
        <Title>{title}</Title>
        <Value>{rounded}</Value>
      </Data>
    </Box>
  );
};

const Box = styled.div<IStyles>`
  ${$stylesReset}
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;
  gap: 24px;
  width: 291px;
  height: 138px;
  padding: 32px;
  overflow: hidden;
  background: radial-gradient(50% 50% at 50% 50%, rgba(101, 101, 101, 0.05), rgba(101, 101, 101, 0)),
    #101622;
  border-radius: 8px;
  font-family: Roboto;
  ${({ styles }) => styles}
`;

const Icon = styled.div`
  ${$stylesReset}
`;

const Data = styled.div`
  ${$stylesReset}
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 9px;
`;

const Title = styled.div`
  ${$stylesReset}
  color: rgba(210, 234, 255, 0.5);
  font-size: 16px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.032px;
`;

const Value = styled.div`
  ${$stylesReset}
  color: rgb(210, 234, 255); // #d2eaff;
  font-size: 32px;
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.96px;
  text-transform: uppercase;

  &::before {
    content: "$";
    color: #3b8be9;
  }

  &::after {
    content: "+";
  }
`;
