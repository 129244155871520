import React from "react";
import styled, { css } from "styled-components";
import { $styles, IStyles, ITheTableCell, TheTable } from "components";
import { DualRingInner, DualRingLoader, Flex } from "components/common";
import { TopTenUser, PropsExtended } from "../types";
import { formatNumber } from "../Prizes";

export type TopTenTableItem = TopTenUser;

export type TopTenTableOptions = {};

export interface TopTenTableProps {
  $keys?: string[];
  users?: TopTenUser[];
  isLoading: boolean;
}

export const TopTenTable: React.FC<TopTenTableProps> = ({
  $keys = ["place", "username", "totalPrize", "createdAt"],
  users,
  isLoading,
}): React.ReactElement | null => {
  const setup = React.useMemo(
    () => [
      {
        $key: "place",
        render: (props: ITheTableCell<TopTenTableItem, TopTenTableOptions>) => {
          const place: number | undefined = props.cell;
          return <RowId>{place}</RowId>;
        },
        title: <Title textAlign="left">Place</Title>,
      },
      {
        $key: "username",
        render: (props: ITheTableCell<TopTenTableItem, TopTenTableOptions>) => {
          const name: string | undefined = props.cell;
          return name;
        },
        title: <Title textAlign="left">User</Title>,
      },
      {
        $key: "totalPrize",
        render: (props: ITheTableCell<TopTenTableItem, TopTenTableOptions>) => {
          const totalPrize: number | undefined = props.cell;
          return (
            <Prize data-value style={{ textAlign: "center" }}>
              {typeof totalPrize !== "undefined" ? formatNumber(totalPrize.toFixed(2)) : 0}
            </Prize>
          );
        },
        title: <Title>Prize</Title>,
      },
    ],
    []
  );

  const rowProps = ({ row }: { row: TopTenUser | null }) => {
    if (!row) {
      return {};
    }
    return {
      styles: css`
        ${(row.place === 1 &&
          `                  
        background-image: url("/images/PachinkoComponent/first-place-faded.png");  
        background-size:cover;

 @media (max-width: 600px) {
            td:first-child {
                background:#5e500f;
                }
            td:nth-child(2) {
                background: radial-gradient(100% 842.42% at 0% -147%, #5e500f 50%, #181F2E 100%);
                }

            td:last-child {
                background: radial-gradient(152.76% 130.7% at 50% 90%, #181F2E 95%, rgba(101, 101, 101, 0) 100%), #151C2C;
                }
          }
      `) ||
        (row.place === 2 &&
          `
  background-image: url("/images/PachinkoComponent/second-place-faded.png");  
  background-size:cover;

 @media (max-width: 600px) {
            td:first-child {
                background:#373A41;
                }
            td:nth-child(2) {
                background: radial-gradient(100% 842.42% at 0% -147%, #373A41 50%, #181F2E 100%);
                }

            td:last-child {
                background: radial-gradient(152.76% 130.7% at 50% 90%, #181F2E 95%, rgba(101, 101, 101, 0) 100%), #151C2C;
                }
          }
      `) ||
        (row.place === 3 &&
          `
          background-image: url("/images/PachinkoComponent/third-place-faded.png");  
          background-size:cover;

 @media (max-width: 600px) {
            td:first-child {
                background:rgba(71, 61, 53, 1);
                }
            td:nth-child(2) {
                background: radial-gradient(100% 842.42% at 0% -147%, rgba(71, 61, 53, 1) 50%, #181F2E 100%);
                }

            td:last-child {
                background: radial-gradient(152.76% 130.7% at 50% 90%, #181F2E 95%, rgba(101, 101, 101, 0) 100%), #151C2C;
                }
          }
      `)};
      `,
    };
  };

  return (
    <Box>
      {isLoading ? (
        <Flex align="center" justify="center" padding="60px 0px">
          <DualRingLoader>
            <DualRingInner />
          </DualRingLoader>
        </Flex>
      ) : (
        <div style={{ overflowX: "auto" }}>
          <TheTable<TopTenTableItem, TopTenTableOptions>
            $keys={$keys}
            $setup={setup}
            rows={users ?? []}
            RowProps={rowProps}
          />
        </div>
      )}
    </Box>
  );
};

const Box = styled.div<IStyles>`
  box-sizing: border-box;
  text-align: left;
  ${$styles}
`;

const RowId = styled.div<IStyles>`
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 8px;
  background: rgba(35, 163, 255, 0.05);
  border: 2px solid #0b101b;
  border-radius: 41px;
  ${$styles}
`;

const Title = styled.div<PropsExtended>`
  color: rgba(210, 234, 255, 0.75);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  text-align: ${({ textAlign }) => textAlign || "center"};
  ${$styles}
`;

const Prize = styled.div<IStyles>`
  font-family: "Rubik";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  &[data-value] {
    color: #5ebd3d;
  }
  &[data-value]::before {
    content: "$";
  }
  ${$styles}
`;
