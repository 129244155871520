import { Button, ButtonBlue, Flex, Ident, IStyles } from "components/common";
import { TheIcon } from "components/TheComponents";
import { TrackGoogleAnalyticsEvent } from "helpers/gaEvent";
import Image from "next/image";
import { useRouter } from "next/router";
import React from "react";
import { useMediaQuery } from "react-responsive";
import styled, { css } from "styled-components";
import {
  BoxItem,
  CardLinks,
  CopyCode,
  CopyLink,
  IReward,
  onCopy,
} from "./StartEarningRewardsComponent/StartEarningRewardsComponent";
import StartEarningRewardsComponentCard, {
  Text,
} from "./StartEarningRewardsComponent/StartEarningRewardsComponentCard";

const FeaturedCasinoComponent = ({ featuredReward }: { featuredReward: IReward }) => {
  const isMobile = useMediaQuery({
    query: "(max-width: 800px)",
  });
  const router = useRouter();
  return !isMobile ? (
    <FeaturedCasinoBox>
      <FeaturedCasino
        desktopBg={featuredReward?.imageUrl}
        mobileBg={featuredReward?.mobileImageUrl}
      >
        <BoxItem
          styles={css`
            width: 280px;
            text-align: left;
            display: flex;
            flex-direction: column;
            align-items: left;
            justify-content: center;
            @media (max-width: 1280px) {
              align-items: center;
            }
          `}
        >
          <FeaturedCasinoTitle>
            <TheIcon icon="star:blue" size={16} />
            FEATURED CASINO
          </FeaturedCasinoTitle>
          <Image alt="logo" width={250} height={120} src={featuredReward.logoUrl} />
        </BoxItem>
        <BoxItem styles={css``}>
          <CardLinks>
            <CopyCode featuredCasino>
              <CopyLink
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  onCopy(featuredReward);
                }}
              >
                <TheIcon icon="copy" size={50} />
              </CopyLink>
              {featuredReward.refCode}
            </CopyCode>
            <Button
              onClick={() => {
                TrackGoogleAnalyticsEvent({
                  action: "bonuses_link",
                  category: "bonuses",
                  value: featuredReward.refUrl,
                  label: "All bonuses",
                });
                router.push(featuredReward.refUrl);
              }}
            >
              <ButtonBlue>Redeem bonuses</ButtonBlue>
            </Button>
          </CardLinks>
          <ExtendedFlex>
            <Flex style={{ gap: 7 }} column margin="auto">
              <Flex
                justify="flex-start"
                width="100%"
                style={{
                  display: featuredReward.hasLeaderboard ? "flex" : "none",
                }}
                align="center"
              >
                <Image
                  alt="logo"
                  width={24}
                  height={24}
                  style={{ marginRight: 8 }}
                  src="/images/icons/check-with-border.png"
                />
                <Text>LEADERBOARD</Text>
              </Flex>

              <Flex
                justify="flex-start"
                style={{
                  display: featuredReward.hasDiscordBonuses ? "flex" : "none",
                }}
                width="100%"
                align="center"
              >
                <Image
                  alt="logo"
                  width={24}
                  height={24}
                  style={{ marginRight: 8 }}
                  src="/images/icons/check-with-border.png"
                />
                <Text>DISCORD REWARDS</Text>
              </Flex>
            </Flex>
          </ExtendedFlex>
        </BoxItem>
      </FeaturedCasino>
      <Ident height={40} />
    </FeaturedCasinoBox>
  ) : (
    <div style={{ marginBottom: 12, width: "100%" }}>
      <StartEarningRewardsComponentCard isFeatured width="100%" item={featuredReward} />
    </div>
  );
};

export default FeaturedCasinoComponent;

const ExtendedFlex = styled(Flex)`
  padding-top: 20px;
  height: 55px;
  @media (max-width: 768px) {
    padding-top: 5px;
  }
`;
const FeaturedCasinoBox = styled.div`
  display: block;
  width: 100%;
`;

const FeaturedCasino = styled.div<IStyles & { desktopBg: string | null; mobileBg: string | null }>`
  box-sizing: border-box;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  height: 290px;
  padding: 24px 48px 66px 24px;
  background: radial-gradient(
      152.76% 130.7% at 50% 0%,
      rgba(101, 101, 101, 0.05) 0%,
      rgba(101, 101, 101, 0) 100%
    ),
    #101622;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* Desktop background */
  ${({ desktopBg }) =>
    desktopBg &&
    `
    @media (min-width: 769px) {
      background-image: url(${desktopBg});      
    }
  `}

  /* Mobile background */
  ${({ mobileBg }) =>
    mobileBg &&
    `
    @media (max-width: 768px) {
      background-image: url(${mobileBg});
    }
  `}
  
  border: 1px solid rgba(210, 234, 255, 0.1);
  border-radius: 8px;
  @media (max-width: 768px) {
    flex-flow: row;
    flex-direction: column;
    height: 450px;
    margin: 0 auto;
    padding: 24px;
  }
  ${({ styles }) => styles}
`;

export const FeaturedCasinoTitle = styled.div<IStyles>`
  box-sizing: border-box;
  display: inline-flex;
  flex-flow: row;
  align-items: center;
  gap: 11px;
  padding: 12px 16px;
  background: radial-gradient(
      152.76% 130.7% at 50% 0%,
      rgba(101, 101, 101, 0.05) 0%,
      rgba(101, 101, 101, 0) 100%
    ),
    rgb(21, 28, 44, 0.5);

  border: 1px solid rgba(36, 39, 70, 0.5);
  border-radius: 8px;
  color: #d2eaff;
  font-family: "Roboto";
  font-size: 16px;
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.03em;
  line-height: 19px;
  text-transform: uppercase;
  ${({ styles }) => styles}
`;
