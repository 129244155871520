import React from "react";
import styled, { css } from "styled-components";
import {
  IStyles,
  //  LeaderboardWinnerCard
} from "components";
import { IDtoLeaderboard, IDtoLeaderboardUser } from "interfaces";
import { Zoom } from "react-awesome-reveal";

import { LeaderboardWinnerCard } from "components/leaderboard/LeaderboardWinnerCard";

export interface ILeaderboardWinnerCardList {
  leaderboard: IDtoLeaderboard;
  leaderboardUsers: IDtoLeaderboardUser[];
  customWinnerRewardText?: React.ReactNode;
}

export const LeaderboardWinnerCardList: React.FC<ILeaderboardWinnerCardList> = ({
  leaderboard,
  leaderboardUsers,
  customWinnerRewardText,
}): React.ReactElement => {
  if (!leaderboardUsers.length) {
    return <></>;
  }

  function getValueByIndex(index: number) {
    let value;

    switch (index) {
      case 0:
        value = 100;
        break;
      case 1:
        value = 200;
        break;
      case 2:
        value = 300;
        break;
      default:
        value = 400; // Default value if index doesn't match any case
    }

    return value;
  }

  return (
    <Box>
      <List gap={24}>
        {leaderboardUsers.map((leaderboardUser, index) => {
          const place = index + 1;
          const order = index === 0 ? 2 : index === 1 ? 1 : index + 1;

          return (
            <ZoomStyled
              direction="down"
              order={order}
              delay={getValueByIndex(index)}
              key={index}
              triggerOnce
            >
              <Item>
                <LeaderboardWinnerCard
                  leaderboard={leaderboard}
                  leaderboardUser={leaderboardUser}
                  place={place}
                  customWinnerRewardText={place === 1 && customWinnerRewardText}
                />
              </Item>
            </ZoomStyled>
          );
        })}
      </List>
    </Box>
  );
};

const Box = styled.div<IStyles>`
  box-sizing: border-box;
  ${({ styles }) => styles}
`;

const ZoomStyled = styled(Zoom)<{ order: number }>`
  @media (min-width: 1024px) {
    ${({ order }) =>
      css`
        order: ${order};
      `}
  }
`;

const List = styled.div<IStyles & { gap?: number }>`
  --gap: ${({ gap }) => gap ?? 24}px;
  --card-width: 315px;
  --cards-amount: 3;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: var(--gap);
  max-width: calc((var(--card-width) + var(--gap)) * var(--cards-amount));
  margin: 0 auto;
  // scrolling
  @media (max-width: calc( 1024px - 1px )) {
    margin: 0 24px;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  ${({ styles }) => styles}
`;

const Item = styled.div<IStyles>`
  box-sizing: border-box;
  display: inline-block;
  ${({ styles }) => styles}
`;
