import React from "react";
import styled, { css } from "styled-components";
import { IStyles } from "components";
import { ITheScreenBox } from "interfaces";

const Box = styled.div<IStyles>`
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  min-height: 100vh;

  // background
  background-color: #0b0e16;

  ${({ styles }) => styles}
`;

const Screen = styled.section<ITheScreenBox>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  /* height: 980px; */
  /* width: 1920px; */
  margin: 0 auto;
  overflow: hidden;

  // text
  color: #d2eaff;
  /* color: rgba(210, 234, 255, 0.75); */
  font-family: Roboto;
  font-size: 16px;
  text-align: left;

  // background
  background-origin: border-box;
  background-position: center;
  background-repeat: no-repeat;

  ${({ maxHeight }) =>
    Number.isInteger(maxHeight) &&
    css`
      max-height: ${maxHeight}px;
    `}
  ${({ minHeight }) =>
    Number.isInteger(minHeight) &&
    css`
      min-height: ${minHeight}px;
    `}
	${({ maxWidth }) =>
    Number.isInteger(maxWidth) &&
    css`
      max-width: ${maxWidth}px;
    `}
	${({ minWidth }) =>
    Number.isInteger(minWidth) &&
    css`
      min-width: ${minWidth}px;
    `}
	${({ height }) =>
    Number.isInteger(height) &&
    css`
      height: ${height}px;
    `}
	${({ width }) =>
    Number.isInteger(width) &&
    css`
      width: ${width}px;
    `}
	${({ styles }) => styles}
`;

export function TheScreenBox({
  id,
  maxHeight,
  minHeight,
  maxWidth,
  minWidth,
  height,
  width,
  boxProps,
  ...props
}: ITheScreenBox): React.ReactElement {
  const boxPropsNew = {
    id,
    ...boxProps,
  };

  const screenProps = {
    maxHeight,
    minHeight,
    height,
    maxWidth,
    minWidth,
    width,
    ...props,
  };
  return (
    <Box {...boxPropsNew}>
      <Screen {...screenProps} />
    </Box>
  );
}
