import Image from "next/image";
import React from "react";
import styled, { css } from "styled-components";
import { $stylesReset, IStyles } from "components";
import { TheMessageProps, ScreenProps } from "./types";
import { Button, ButtonBlue, Flex } from "components/common";
import Link from "next/link";
import { useRouter } from "next/router";
import { Fade } from "react-awesome-reveal";

const Screen = styled.div<ScreenProps>`
  ${$stylesReset}
  position: relative;
  max-width: 1920px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  overflow-x: clip;
  margin: 0 auto;
  padding-top: 80px;
  ${({ mainOnly }) =>
    !mainOnly &&
    css`
      background-image: url("/images/PachinkoComponent/Pachinko.png");
      background-position: top;
      background-repeat: no-repeat;
      @media (min-width: 1600px) {
        margin-bottom: 100px;
      }
    `};
`;

const Content = styled.div`
  ${$stylesReset}
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  margin: 24px;
  @media (min-width: 1600px) {
    & [data-pic] {
      order: 1;
    }
    & [data-text] {
      order: 2;
    }
    flex-direction: row;
    gap: 48px;
    max-width: 1152px; // 1068px;
    max-height: 630px;
    padding-top: calc(82px - 24px);
    padding-bottom: calc(90px - 24px);
  }
`;

const ExtendedFlex = styled(Flex)`
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 1280px) {
    justify-content: center;
  }
`;

const Side = styled.div<IStyles>`
  --width: 556px;
  --height: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &[data-pic] {
    height: var(--height);
  }
  // &[data-text] {
  //   /* text-align: center; */
  // }
  @media (max-width: 600px) {
    &[data-text] {
      width: 100%;
    }
  }
  @media (max-width: 1600px) {
    max-width: var(--width);
    width: 100%;
  }
  @media (min-width: 1600px) {
    width: var(--width);
    &[data-text] {
      align-items: flex-start;
      height: var(--height);
      text-align: left;
    }
  }
  ${({ styles }) => styles}
`;

const TheTitle = styled.div`
  box-sizing: border-box;
  position: relative;
  /* width: 512px; */
  font-size: 24px;
  @media (min-width: 1068px) {
    font-size: 40px;
  }
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.03em;
  line-height: 130%;
  text-transform: uppercase;

  & [data-main-light] {
    color: #d1e9ff;

    background-position: center;
    background-repeat: no-repeat;
    background-size: 619.36px 270.19px;
    @media (max-width: 600px) {
      font-size: 30px;
    }
  }
  @media (max-width: 1280px) {
    text-align: center;
  }

  & [data-main] {
    background: linear-gradient(265.52deg, #4796f2, #47d3f2);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 100px;
    @media (max-width: 600px) {
      font-size: 50px;
    }
    line-height: 130%;
  }

  & [data-star] {
    padding: 0 0 0 20px;
    color: #f2c94c;
    font-size: 20px;
    line-height: 130%;
    white-space: nowrap;
    @media (max-width: 600px) {
      font-size: 16px;
    }
  }
`;

const TheMessage = styled.div<TheMessageProps>`
  /* width: 512px; */
  margin: ${({ secondRow }) => (secondRow ? "32px 0 8px" : "32px 0 48px")};
  letter-spacing: 0.02em;
  line-height: 150%;
  font-weight: 500;
  color: rgba(210, 234, 255, 0.75);
  @media (max-width: 1280px) {
    margin-top: 60px;
  }
  @media (max-width: 600px) {
    text-align: ${({ secondRow }) => (secondRow ? "center" : "left")};
  }
`;

export const KickButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 16px 50px;
  margin: 50px 0px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  white-space: nowrap;
  background: #55a83a;
  color: #000000;
  font-family: Roboto;
  font-size: 16px;
  font-style: italic;
  font-weight: 900;
  line-height: 19px;
  letter-spacing: 0.03em;
  text-align: left;
  @media (max-width: 1280px) {
    align-items: center;
    justify-center: center;
    width: 90%;
  }

  &:hover {
    background-color: #38632a;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px black;
  }

  &:active {
    background-color: #38632a;
  }
`;

const AdaptedPachinkoImage = styled(Image)`
  @media (min-width: 1620px) {
    width: 500px;
    height: 600px;
  }
  @media (max-width: 1280px) {
    width: 500px;
    height: 600px;
  }
  @media (max-width: 620px) {
    width: 350px;
    height: 400px;
  }
  @media (max-width: 480px) {
    width: 300px;
    height: 360px;
  }
`;

export const MainSection = ({ mainOnly }: { mainOnly: boolean | undefined }) => {
  const router = useRouter();
  return (
    <>
      <Screen mainOnly={!!mainOnly}>
        <Content>
          <Side
            styles={css`
              animation: float 4s ease-in-out infinite;
            `}
            style={{ zIndex: 1 }}
          >
            <AdaptedPachinkoImage
              src="/images/PachinkoComponent/wgtvpachinnnok 2.png"
              width={500}
              height={600}
              alt="pic"
            />
          </Side>
          <div style={{ position: "absolute", top: 0, left: "-40%" }}>
            <Image
              src="/images/PachinkoComponent/gradient.png"
              width={1500}
              height={1000}
              alt="pic"
            />
          </div>
          <Side data-text>
            <TheTitle>
              <div data-main-light>
                <Fade damping={0.1} cascade>
                  Welcome to
                </Fade>
              </div>
              <div data-main>Pachinko</div>
              <ExtendedFlex>
                <Image
                  src="/images/PachinkoComponent/vector7.svg"
                  width={20}
                  height={20}
                  alt="pic"
                />
                <div data-star>WGTV kick EXCLUSIVE</div>
              </ExtendedFlex>
            </TheTitle>
            <TheMessage>
              The Crazy Pachinko game can be found on WatchGamesTV&apos;s Kick channel. This game
              mode randomly shows up during streams and viewers can win a wide range of truly
              amazing prizes!
            </TheMessage>
            <TheMessage secondRow>Follow now and never miss a chance to win!</TheMessage>
            {mainOnly ? (
              <Button
                onClick={(event: React.MouseEvent) => {
                  event.preventDefault();
                  router.push("/pachinko");
                }}
                style={{ height: 50, cursor: "pointer", width: 209 }}
              >
                <ButtonBlue style={{ whiteSpace: "nowrap" }} transparent>
                  EXPLORE
                </ButtonBlue>
              </Button>
            ) : (
              <Link target="_blank" href="https://kick.com/watchgamestv">
                <KickButton>
                  <Image
                    width={18}
                    height={18}
                    style={{ marginRight: 15 }}
                    alt="Twitch"
                    src="/images/PachinkoComponent/kick.svg"
                  />
                  Follow WGTV Kick
                </KickButton>
              </Link>
            )}
          </Side>
        </Content>
      </Screen>
    </>
  );
};
