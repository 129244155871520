import React from "react";
import styled from "styled-components";
import { $stylesReset, IStyles } from "components";
import Image from "next/image";
import Link from "next/link";
import { Flex, PulsingIndicator } from "components/common";
import { IUserDto } from "interfaces";
import { Stream } from "helpers/types";
import { DEFAULT_AVATAR } from "helpers/constants";
import { ThePointsBalance } from "components/TheComponents/ThePointsBalance";

export interface ITop {
  id?: string;
  linkTarget?: string;
  linkToLiveStream?: string;
  profile: IUserDto | null;
  liveStreams: Stream[];
  menuItems?: Array<string | JSX.Element>;
  postItem?: JSX.Element | null;
  preItem?: JSX.Element | null;
  showMobileMenu: boolean;
  handleCheckboxChange?: () => void;
}

export const Top: React.FC<ITop> = ({
  id,
  showMobileMenu,
  handleCheckboxChange,
  linkTarget = "_self",
  linkToLiveStream = "/",
  menuItems = [],
  liveStreams = [],
  profile,
  postItem = null,
  preItem = null,
}): JSX.Element => {
  return (
    <Box id={id}>
      <TopNavigation>
        {preItem ? <div data-pre>{preItem}</div> : null}
        {menuItems?.length ? (
          <>
            <MobileFlex
              style={{
                justifyContent: "flex-end",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Link target={linkTarget} href={linkToLiveStream}>
                {/* <Card> */}
                <PulsingIndicator live={!!liveStreams?.length} />
                {/* </Card> */}
              </Link>
              {profile ? (
                <ModalProfileWrapper>
                  <Link href="/profile">
                    <Image
                      style={{ borderRadius: "50%" }}
                      src={profile?.profileImageUrl ? profile?.profileImageUrl : DEFAULT_AVATAR}
                      height={28}
                      width={28}
                      alt="image"
                    />
                  </Link>
                  <MobilePointsBalanceWrapper>
                    <Image
                      src="/images/PointShopComponent/coin.png"
                      height={20}
                      width={20}
                      alt="image"
                    />
                    <MobilePointsBalanceText>
                      <ThePointsBalance value={profile?.pointsBalance} />
                    </MobilePointsBalanceText>
                  </MobilePointsBalanceWrapper>
                </ModalProfileWrapper>
              ) : null}
            </MobileFlex>
            <input
              data-toggle
              onChange={handleCheckboxChange}
              checked={showMobileMenu}
              type="checkbox"
              id="top-menu-toggler"
            />
            <label data-button-container htmlFor="top-menu-toggler">
              <div data-button />
            </label>

            <ul data-list>
              {menuItems.map((item, index) => {
                return (
                  <li data-item key={index}>
                    {item}
                  </li>
                );
              })}
            </ul>
          </>
        ) : null}

        {postItem ? <div data-post>{postItem}</div> : null}
      </TopNavigation>
    </Box>
  );
};

const Box = styled.div`
  --top-menu-bg: radial-gradient(
      152.76% 130.7% at 50% 0%,
      rgba(101, 101, 101, 0.05) 0%,
      rgba(101, 101, 101, 0) 100%
    ),
    #101622;
  & {
    background: var(--top-menu-bg);
  }
  @media (max-width: 1280px) {
    & {
      position: sticky;
      top: 0;
      z-index: 10000;
    }
  }
`;

const MobileFlex = styled(Flex)`
  @media (max-width: 1280px) {
    display: flex;
  }
  display: none;
`;

const TopNavigation = styled.section<IStyles>`
  --top-menu-nav-max-height: 92px;
  --top-menu-nav-min-height: 66px;
  --top-menu-nav-height: var(--top-menu-nav-max-height);
  --top-menu-toggle-width: 40px;
  --top-menu-bg-burger: #0a0f16;

  // nav
  & {
    ${$stylesReset}
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // align-items: center;
    gap: 1.6em;
    margin: 0 auto;
    height: var(--top-menu-nav-height);
    max-width: 1920px;
    padding: 1em;
  }
  @media (max-width: 480px) {
    padding: 0.5em;
  }
  @media (max-width: 1440px) {
    & {
      gap: 0.8em;
    }
  }
  @media (max-width: 1280px) {
    & {
      --top-menu-nav-height: var(--top-menu-nav-min-height);
      justify-content: space-between;
    }
  }

  // pre
  & div[data-pre] {
    ${$stylesReset}
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 1280px) {
    & div[data-pre] {
      flex-grow: 1;
      flex-shrink: 1;
    }
  }

  // post
  & div[data-post] {
    ${$stylesReset}
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 1280px) {
    & div[data-post] {
      display: none;
    }
  }

  // list
  & ul[data-list] {
    ${$stylesReset}
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    gap: 0.1em;
  }
  @media (max-width: 1280px) {
    & ul[data-list] {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      /* position: absolute; */
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      /* height: calc( 100% - var(--top-menu-nav-height) ); */
      /* min-height: calc( 100vh - var(--top-menu-nav-height) ); */
      margin-top: var(--top-menu-nav-height) !important;
    }
  }

  // item
  & li[data-item] {
    ${$stylesReset}
    /* margin: 0 1rem; */
		overflow: hidden;
  }
  @media (max-width: 1280px) {
    & li[data-item] {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
    }
  }

  // toggle
  & input[data-toggle] {
    display: none;
  }
  & input[data-toggle]:checked + label[data-button-container] > div[data-button]::before {
    margin-top: 0px;
    transform: rotate(405deg);
  }
  & input[data-toggle]:checked + label[data-button-container] > div[data-button] {
    background: transparent;
  }
  & input[data-toggle]:checked + label[data-button-container] > div[data-button]::after {
    margin-top: 0px;
    transform: rotate(-405deg);
  }
  & label[data-button-container] {
    position: relative;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    padding: 8px;
    cursor: pointer;
  }
  & div[data-button],
  & div[data-button]::after,
  & div[data-button]::before {
    display: block;
    position: absolute;
    height: 3.84px;
    width: 24px;
    border-radius: 2px;
    transition: transform 400ms cubic-bezier(1, 0, 0, 1); // cubic-bezier(0.23, 1, 0.32, 1)
    background-color: rgba(210, 234, 255, 0.75);
  }
  & div[data-button]::before {
    content: "";
    margin-top: calc(-2 * 3.84px);
  }
  & div[data-button]::after {
    content: "";
    margin-top: calc(2 * 3.84px);
  }
  @media (max-width: 1280px) {
    & label[data-button-container] {
      display: flex;
    }
    & input[data-toggle] ~ ul[data-list] {
      background-color: transparent;
      height: 0;
      min-height: unset;
      transition-delay: 0;
      transition-duration: 400ms, 400ms, 400ms;
      transition-property: background-color, height, min-height;
      transition-timing-function: cubic-bezier(0.5, 0, 0.5, 1), cubic-bezier(0.5, 0, 0.5, 1),
        step-start; // cubic-bezier(0.42, 0, 0.58, 1), cubic-bezier(0.23, 1, 0.32, 1), step-end;
    }
    & input[data-toggle]:checked ~ label[data-button-container] {
      background: rgba(35, 163, 255, 0.05);
      border-radius: 8px;
    }
    /* & input[data-toggle]:checked ~ label[data-button-container] > div[data-button], */
    & input[data-toggle]:checked ~ label[data-button-container] > div[data-button]::after,
    & input[data-toggle]:checked ~ label[data-button-container] > div[data-button]::before {
      background-color: #3b8be9;
    }
    & input[data-toggle]:checked ~ ul[data-list] {
      background-color: var(--top-menu-bg-burger);
      height: calc(100% - var(--top-menu-nav-height));
      min-height: calc(100vh - var(--top-menu-nav-height));
      transition-timing-function: cubic-bezier(0.5, 0, 0.5, 1), cubic-bezier(0.5, 0, 0.5, 1),
        step-end;
    }
  }

  ${({ styles }) => styles}
`;

const ModalProfileWrapper = styled.div`
  display: none;
  @media (max-width: 1280px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const MobilePointsBalanceWrapper = styled.div`
  display: flex;
  margin-left: 5px;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  background: #1b1f35;
  border-radius: 12px;
`;

const MobilePointsBalanceText = styled.div`
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 10px;
  color: rgba(210, 234, 255, 0.75);
`;
