import React from "react";
import styled, { css } from "styled-components";
import Image from "next/image";
import { PropsExtended } from "../types";
import { STREAMING_PLATFORMS } from "helpers/constants";

const AbsoluteBox = styled.div<PropsExtended>`
  position: absolute;
  ${({ first }) =>
    first &&
    css`
      left: 20%;
      top: 20%;
      @media (max-width: 1280px) {
        left: 20%;
        top: 11%;
      }
    `};
  ${({ second }) =>
    second &&
    css`
      left: 12%;
      @media (max-width: 1280px) {
        left: 20%;
        top: 2%;
      }
    `};
  ${({ third }) =>
    third &&
    css`
      left: 36%;
      top: 12%;
      @media (max-width: 1280px) {
        display: none;
      }
    `};
  ${({ fourth }) =>
    fourth &&
    css`
      left: 20%;
      bottom: 12%;
      @media (max-width: 1280px) {
        display: none;
      }
    `};
  ${({ fifth }) =>
    fifth &&
    css`
      right: 18%;
      bottom: 12%;
      @media (max-width: 1280px) {
        display: none;
      }
    `};
  ${({ sixth }) =>
    sixth &&
    css`
      right: 33%;
      bottom: 15%;
      @media (max-width: 1280px) {
        right: 28%;
        top: 2%;
      }
    `};
  ${({ seventh }) =>
    seventh &&
    css`
      right: 13%;
      @media (max-width: 1280px) {
        display: none;
      }
    `};
  ${({ eighth }) =>
    eighth &&
    css`
      right: 18%;
      top: 20%;
      @media (max-width: 1280px) {
        right: 20%;
        top: 14%;
      }
    `};
`;

function TheLiveplatformPictures({ platform }: { platform: string }) {
  return (
    <>
      <AbsoluteBox first>
        <Image
          alt="vector"
          width={56}
          height={58}
          src="/images/HuntTrackerComponent/vector-1.svg"
        />
      </AbsoluteBox>
      <AbsoluteBox second>
        <Image
          alt="platform"
          width={70}
          height={72}
          src={
            platform === STREAMING_PLATFORMS.TWITCH
              ? "/images/HuntTrackerComponent/twitch-logo.svg"
              : "/images/HuntTrackerComponent/kick-logo.png"
          }
        />
      </AbsoluteBox>
      <AbsoluteBox third>
        <Image
          alt="platform"
          width={56}
          height={58}
          src={
            platform === STREAMING_PLATFORMS.TWITCH
              ? "/images/HuntTrackerComponent/twitch-logo.svg"
              : "/images/HuntTrackerComponent/kick-logo-1.png"
          }
        />
      </AbsoluteBox>
      <AbsoluteBox fourth>
        <Image
          alt="platform"
          width={56}
          height={58}
          src="/images/HuntTrackerComponent/vector-2.svg"
        />
      </AbsoluteBox>

      {/* /// */}

      <AbsoluteBox fifth>
        <Image
          alt="vector"
          width={56}
          height={58}
          src="/images/HuntTrackerComponent/vector-1.svg"
        />
      </AbsoluteBox>
      <AbsoluteBox sixth>
        <Image
          alt="platform"
          width={70}
          height={72}
          src={
            platform === STREAMING_PLATFORMS.TWITCH
              ? "/images/HuntTrackerComponent/twitch-logo-1.svg"
              : "/images/HuntTrackerComponent/kick-logo.png"
          }
        />
      </AbsoluteBox>
      <AbsoluteBox seventh>
        <Image
          alt="platform"
          width={56}
          height={58}
          src={
            platform === STREAMING_PLATFORMS.TWITCH
              ? "/images/HuntTrackerComponent/twitch-logo-2.svg"
              : "/images/HuntTrackerComponent/kick-logo-3.png"
          }
        />
      </AbsoluteBox>
      <AbsoluteBox eighth>
        <Image
          alt="platform"
          width={56}
          height={58}
          src="/images/HuntTrackerComponent/vector-1.svg"
        />
      </AbsoluteBox>
    </>
  );
}

export default TheLiveplatformPictures;
