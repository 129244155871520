import React, { PropsWithChildren } from "react";

import { useAppContext } from "contexts";
import { useRouter } from "next/router";
import { ALL_ADMIN_ROLES, PATH_TO_ROLES_MAPPING } from "helpers/constants";
import { Role } from "interfaces";

type RoleType = keyof typeof ALL_ADMIN_ROLES;

export interface IRoleAccessContext {
  checkAccess: (requiredRoles: RoleType[]) => boolean;
}

export const RoleAccessContext = React.createContext<IRoleAccessContext>({
  checkAccess: () => false,
});

const RoleAccessProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const { profile, isProfileLoading } = useAppContext();

  const checkAccess = React.useCallback(
    (requiredRoles: RoleType[]) => {
      if (!profile && router?.pathname?.includes?.("admin")) {
        return false;
      }

      if (profile?.roles?.some?.((el: Role) => el.name === ALL_ADMIN_ROLES.SUPER_ADMIN)) {
        return true;
      }

      return (
        profile?.roles?.some?.((el: Role) => requiredRoles?.includes(el.name as RoleType)) ?? false
      );
    },
    [profile, router]
  );

  React.useEffect(() => {
    const pathname = router.pathname;
    const isAdminPage = pathname?.includes?.("admin");

    if (isProfileLoading) {
      return;
    }

    if (isAdminPage) {
      const pathSegments = pathname.split("/");
      const partOfPathToCheck =
        pathname === "/admin"
          ? "/admin"
          : `/${pathSegments[1]}/${pathSegments[2]}${pathSegments[3] ? `/${pathSegments[3]}` : ``}`;

      const requiredRoles = PATH_TO_ROLES_MAPPING?.[partOfPathToCheck] ?? [];

      const hasRequiredRole = checkAccess(requiredRoles as RoleType[]);
      if (!hasRequiredRole) {
        router.push("/");
      }
    }
  }, [profile, router, checkAccess, isProfileLoading]);

  return (
    <RoleAccessContext.Provider value={{ checkAccess }}>
      <>{children}</>
    </RoleAccessContext.Provider>
  );
};

export function useRoleAccessContext() {
  const context = React.useContext(RoleAccessContext);
  if (context === undefined) {
    throw new Error("useRoleAccessContext must be used within a RoleAccessProvider");
  }
  return context;
}

export default RoleAccessProvider;
