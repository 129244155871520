import { useRouter } from "next/router";
import Link from "next/link";
import React, { useCallback, useEffect } from "react";
import styled, { css } from "styled-components";
import { TheButton } from "components";
import { useAppContext, withApp } from "contexts";

import ChakraModal from "components/TheComponents/Modal";
import ProfileLayout from "components/public/ProfileLayout";
import { TwitchLoginLayout } from "components/public";
import { useDisclosure } from "@chakra-ui/react";
import LogoutLayout from "components/public/LogoutLayout";
import {
  AdaptiveLogo,
  DropNav,
  Logo,
  Profile,
  Header,
  Slide,
  Root,
  MenuToggler,
  DropDownBack,
  LogoutButton,
  MobileHeaderBlock,
  SignInButton,
} from "./styles";
import Calendar from "./components/icons/Calendar";
import NavLink from "./components/Navigation/NavLink";
import HeaderProfile from "./components/HeaderProfile";
import { filterNavItems } from "./utils/filterNavItems";
import StreamIndicator from "./components/StreamIndicator";
import { useUserClientActions } from "hooks/user/useCurrentUser";
import Image from "next/image";

import { ITopMenuItem, menuItems, addMenuItems, metaMenuItems } from "./dto";
import { useShowPageLayoutContext } from "contexts/ShowPageLayoutContext";
import { useMediaQuery } from "react-responsive";
import Burger from "./components/icons/Burger";

export interface IHeaderComponent {
  id?: string;
}

const HeaderComponentPure: React.FC<IHeaderComponent> = () => {
  const {
    profile,
    isAdmin,
    liveStreams,
    accessURL,
    loginModalProps,
    profileProps,
    isFeatureEnabled,
    featureFlags,
  } = useAppContext();

  const router = useRouter();

  const isTablet = useMediaQuery({
    query: "(min-width: 480px) and (max-width: 1399px)",
  });

  const { setShowNavSidebar } = useShowPageLayoutContext();

  const { logout, isLoadingUserInfo } = useUserClientActions();
  const { isLoginOpen, onOpenLogin, onCloseLogin } = loginModalProps;
  const { isProfileOpen, onOpenProfile, onCloseProfile } = profileProps;
  const { isOpen: isLogoutOpen, onOpen: onOpenLogout, onClose: onCloseLogout } = useDisclosure();
  const [items, setItems] = React.useState<ITopMenuItem[]>([]);
  const [itemKey, setItemKey] = React.useState<string>("");
  const [isOpenDropdown, setIsOpenDropdown] = React.useState<boolean>(false);
  const onToggle = () => {
    setIsOpenDropdown((prev) => !prev);
  };

  const onNavSidebarToggle = useCallback(() => {
    setShowNavSidebar((prev) => !prev);
  }, [setShowNavSidebar]);

  useEffect(() => {
    const newItems = filterNavItems(menuItems, isFeatureEnabled, profile, isAdmin);
    setItems(newItems);
  }, [profile, isFeatureEnabled, featureFlags, isAdmin]);

  const onActionClose = React.useMemo(() => {
    return (action: string) => {
      if (action === "login") {
        onCloseLogin();
      } else if (action === "logout") {
        onCloseLogout();
      } else if (action === "profile") {
        if (window) {
          window.history.replaceState(null, "", window.location.pathname);
        }
        onCloseProfile();
      }
    };
  }, [onCloseLogout, onCloseLogin, onCloseProfile]);

  const onLoginRedirect = React.useMemo(() => {
    return async () => {
      try {
        const url = accessURL(`/auth/twitch`) as URL;
        router.push(url.href);
      } catch (error) {
        console.log({ error });
      }
    };
  }, [accessURL, router]);

  const onLoginShow = React.useMemo(() => {
    return (event: React.MouseEvent) => {
      event.preventDefault();
      onOpenLogin();
    };
  }, [onOpenLogin]);

  const onLogoutRedirect = React.useMemo(() => {
    return async () => {
      try {
        router.push("/");
        logout();
      } catch (error) {}
    };
  }, [router, logout]);

  const handleLogoClick = () => {
    if (isTablet) {
      setShowNavSidebar((prev) => !prev);
    }
    router.push("/");
  };

  return (
    <>
      <Header fixed={true}>
        <Logo>
          <Link href={"/"} onClick={handleLogoClick}>
            <AdaptiveLogo
              src={"/images/HeaderComponent/logo.svg"}
              width={136}
              height={36}
              alt="logo"
            />
          </Link>
          <StreamIndicator liveStreams={liveStreams} />
        </Logo>

        <Profile>
          {isLoadingUserInfo ? (
            <ProfileLoader>
              <Image
                className="profile-loader"
                alt="profile-image"
                src="/images/Profile/watch.png"
                width={40}
                height={40}
              />
            </ProfileLoader>
          ) : !profile ? (
            <TheButton
              styles={css`
                @media (max-width: 1540px) {
                  margin-right: 15px;
                }
                @media (max-width: 1480px) {
                  display: none;
                }
              `}
              preset="dark-blue"
              onClick={onLoginShow}
            >
              Login
            </TheButton>
          ) : (
            <HeaderProfile
              // onOpenProfile={onOpenProfile}
              onOpenLogout={onOpenLogout}
              profile={profile}
            />
          )}
          {isTablet && (
            <NavSidebarToggleBtn onClick={onNavSidebarToggle}>
              <Burger />
            </NavSidebarToggleBtn>
          )}

          <MenuToggler>
            <input
              data-toggle
              onChange={onToggle}
              checked={isOpenDropdown}
              type="checkbox"
              id="top-menu-toggler"
            />
            <label data-button-container htmlFor="top-menu-toggler">
              <div data-button />
            </label>
          </MenuToggler>
        </Profile>
      </Header>
      <Slide in={isOpenDropdown} direction="top">
        <DropDownBack>
          {profile ? (
            <MobileHeaderBlock>
              <HeaderProfile
                // onOpenProfile={onOpenProfile}
                onOpenLogout={onOpenLogout}
                profile={profile}
              />
            </MobileHeaderBlock>
          ) : (
            <></>
          )}
          <MobileHeaderBlock>
            {profile ? (
              <LogoutButton onClick={onOpenLogout}>Log Out</LogoutButton>
            ) : (
              <SignInButton onClick={onLoginShow}>Sign in</SignInButton>
            )}
          </MobileHeaderBlock>
          <MobileHeaderBlock>
            <DropNav>
              {items.map((item, index) => {
                return (
                  <NavLink
                    key={index}
                    item={item}
                    handleCheckboxChange={onToggle}
                    itemKey={itemKey}
                    setItemKey={setItemKey}
                  />
                );
              })}
            </DropNav>
          </MobileHeaderBlock>
          <MobileHeaderBlock>
            <NavLink
              item={{
                name: "Calendar",
                href: "/advent-calendar",
                image: <Calendar />,
              }}
              handleCheckboxChange={onToggle}
              itemKey={itemKey}
              setItemKey={setItemKey}
            />
          </MobileHeaderBlock>
          <MobileHeaderBlock>
            <DropNav>
              {addMenuItems.map((item, index) => {
                return (
                  <NavLink
                    key={index}
                    item={item}
                    handleCheckboxChange={onToggle}
                    itemKey={itemKey}
                    setItemKey={setItemKey}
                  />
                );
              })}
            </DropNav>
          </MobileHeaderBlock>
          <MobileHeaderBlock
            style={{
              borderBottom: "none",
            }}
          >
            <DropNav>
              {metaMenuItems.map((item, index) => {
                return (
                  <NavLink
                    key={index}
                    item={item}
                    handleCheckboxChange={onToggle}
                    itemKey={itemKey}
                    setItemKey={setItemKey}
                  />
                );
              })}
            </DropNav>
          </MobileHeaderBlock>
        </DropDownBack>
      </Slide>
      <ChakraModal
        isOpen={isLoginOpen}
        onClose={() => onActionClose("login")}
        size="xl"
        content={
          <TwitchLoginLayout onClose={() => onActionClose("login")} onLogin={onLoginRedirect} />
        }
      />
      <ChakraModal
        size="md"
        isOpen={isLogoutOpen}
        onClose={() => onActionClose("logout")}
        content={
          <LogoutLayout onClose={() => onActionClose("logout")} onLogout={onLogoutRedirect} />
        }
      />
    </>
  );
};

const ProfileLoader = styled.div`
  display: flex;
  font-family: "Roboto";
  gap: 5px;
  align-items: center;
  justify-content: center;
  .profile-loader {
    animation: tilt-n-move-shaking 0.7s linear infinite;
    animation-fill-mode: forwards;
  }
  @media (max-width: 1480px) {
    display: none;
  }
  @keyframes tilt-n-move-shaking {
    0% {
      transform: translate(0, 0) rotate(0deg);
    }
    25% {
      transform: translate(5px, 5px) rotate(5deg);
    }
    50% {
      transform: translate(0, 0) rotate(0eg);
    }
    75% {
      transform: translate(-5px, 5px) rotate(-5deg);
    }
    100% {
      transform: translate(0, 0) rotate(0deg);
    }
  }
`;

const NavSidebarToggleBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  cursor: pointer;
  margin-left: 30px;

  svg {
    opacity: 0.25;
    transition: opacity 0.2s;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
`;

const HeaderComponent = withApp(HeaderComponentPure);

export default HeaderComponent;
