import numeral from "numeral";
import React from "react";
import styled, { css } from "styled-components";

import { IStyles, Ident, Hr } from "components";
import { IDtoLeaderboard, IDtoLeaderboardUser, TheLeaderboardSourceKeys } from "interfaces";
import { DEFAULT_AVATAR } from "helpers/constants";
import Image from "next/image";
import { formatNumber } from "./utils/formatNumbers";

export interface ILeaderboardWinnerCard {
  leaderboard: IDtoLeaderboard;
  leaderboardUser: IDtoLeaderboardUser;
  place: number;
  customWinnerRewardText?: React.ReactNode;
}

const getValueTitle = (provider: string): string | null => {
  switch (provider) {
    case TheLeaderboardSourceKeys.gamdom:
    case TheLeaderboardSourceKeys.packdraw:
    case TheLeaderboardSourceKeys.clash:
    case TheLeaderboardSourceKeys.cases:
      return "Wagered";
    case TheLeaderboardSourceKeys.hypedrop:
      return "Deposited";
    case TheLeaderboardSourceKeys.wrewards:
      return "Total Balance";
    default:
      return null;
  }
};

const getValue = (provider: string, user: IDtoLeaderboardUser) => {
  switch (provider) {
    case TheLeaderboardSourceKeys.gamdom:
    case TheLeaderboardSourceKeys.packdraw:
    case TheLeaderboardSourceKeys.clash:
    case TheLeaderboardSourceKeys.cases:
      return (
        <>
          <span>$</span>
          {formatNumber(user?.wageredTotal ? +user.wageredTotal : 0)}
        </>
      );
    case TheLeaderboardSourceKeys.wrewards:
      return (
        <>
          <Image src="/images/coins/g-coin.svg" width={20} height={20} alt="coin" />
          {formatNumber(user?.wageredTotal ? +user.wageredTotal : 0)}
        </>
      );
    case TheLeaderboardSourceKeys.hypedrop:
      return (
        <>
          <span>$</span>
          {formatNumber(user?.deposited ? +user.deposited : 0)}
        </>
      );
    default:
      return 0;
  }
};

export const LeaderboardWinnerCard: React.FC<ILeaderboardWinnerCard> = ({
  leaderboard,
  leaderboardUser,
  place = 1,
  customWinnerRewardText,
}): React.ReactElement => {
  // const avatar = leaderboardUser?.avatar || DEFAULT_AVATAR;

  const displayName = leaderboardUser?.displayName ?? "Xxxxxx";

  const value = getValue(leaderboard.casinoProvider, leaderboardUser);

  const valueTitle = getValueTitle(leaderboard.casinoProvider);

  // @ts-ignore
  const reward = formatNumber(+leaderboard?.[`prize${place}`] ?? 0);

  const avatarSize = 104;
  return (
    <Box>
      <Card place={place} avatarSize={avatarSize}>
        <AnimatedBackground place={place} />

        <AvatarFrame avatarSize={avatarSize} place={place}>
          <Avatar avatarSize={avatarSize}>
            {leaderboardUser?.avatar ? (
              <AvatarPicture src={leaderboardUser.avatar} alt="avatar" width={110} height={110} />
            ) : (
              <span>{place}</span>
            )}
          </Avatar>
        </AvatarFrame>
        <Ident height={16} />
        <Name place={place}>{displayName}</Name>
        <Ident height={place === 1 ? 53 : 35} />
        <Text>{valueTitle}</Text>
        <Ident height={place === 1 ? 16 : 8} />
        <Hr
          styles={css`
            position: relative;
          `}
          text={<Value data-first>{value}</Value>}
        />
        <Ident height={place === 1 ? 40 : 20} />
        <Text>Reward</Text>
        <Ident height={16} />
        <Reward>
          {customWinnerRewardText ? (
            <div className="custom-reward">{customWinnerRewardText}</div>
          ) : (
            <span>${reward}</span>
          )}
        </Reward>
      </Card>
    </Box>
  );
};

const Box = styled.div<IStyles>`
  ${({ styles }) => styles}
`;

const Card = styled.div<IStyles & { place?: number; avatarSize: number }>`
  --size: ${({ avatarSize }) => avatarSize ?? 104}px;
  background-color: #13171f;
  box-sizing: border-box;
  width: 320px;
  margin-top: calc((var(--size) + 5px * 2) / 2);
  border-radius: 26px 26px 6px 6px;
  text-align: center;
  position: relative;
  box-shadow: 0px 0px 2px #4a505c;

  ${({ styles }) => styles}
`;

const AnimatedBackground = styled.div<IStyles & { place?: number }>`
  --image: url("/images/leaderboard/LeaderboardWinnerCard/Vector_v2.svg");

  position: absolute;
  inset: 0;
  z-index: 0;
  border-radius: inherit;
  overflow: hidden;
  background-image: var(--gradient);

  &::before,
  &::after {
    content: "";
    position: absolute;
    inset: -200px;
    display: block;
    z-index: 0;
    border-radius: inherit;
    background-size: cover;
    background-image: var(--image);
    opacity: 0.4;
  }

  &::after {
    transform-origin: center center;
    animation: diagonalMove1 50s linear infinite;
  }
  /* fix rotation */
  &::before {
    margin-top: 4rem;
    transform-origin: center right;
    animation: diagonalMove2 50s linear infinite;
  }
  ${({ place }) => {
    switch (place) {
      case 1:
        return css`
          --gradient: linear-gradient(150deg, #bcad60 0%, #cdae43 30%, #79591a 80%, #a07d21 100%);
        `;
      case 2:
        return css`
          --gradient: radial-gradient(
              152.76% 130.7% at 50% 0%,
              rgba(101, 101, 101, 0.35) 0%,
              rgba(145, 145, 145, 0.35) 0%,
              rgba(120, 137, 163, 0) 100%
            ),
            linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 93.1%);
        `;
      case 3:
        return css`
          --gradient: radial-gradient(
              149.16% 127.62% at 50% -23.77%,
              #534a40 21.03%,
              #462a241c 100%
            ),
            linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 93.1%);
        `;
      default:
        return null;
    }
  }}
`;

const Text = styled.div<IStyles>`
  position: relative;
  box-sizing: border-box;
  color: rgba(210, 234, 255, 0.75);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);

  ${({ styles }) => styles}
`;

const Name = styled.div<IStyles & { place?: number }>`
  position: relative;
  box-sizing: border-box;
  color: #d2eaff;
  font-family: "Roboto";
  font-size: 32px;
  font-style: italic;
  font-weight: 800;
  letter-spacing: 0.02em;
  line-height: 38px;
  word-wrap: break-word;
  word-break: break-all;
  text-align: center;
  text-shadow: 0px 0px 24px rgba(40, 113, 255, 0.5), 0px 0px 6px rgba(58, 113, 254, 0.46),
    0px 2px 4px #0b0e23;
  text-transform: uppercase;

  // truncate
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px 20px;

  ${({ place }) =>
    place === 1
      ? css`
          font-size: 48px;
          line-height: 56px;
        `
      : null}
  ${({ styles }) => styles}
`;

const Value = styled.div<IStyles>`
  position: relative;
  color: #d2eaff;
  font-family: "Rubik";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 4px;

  & span {
    font-size: 20px;
    color: #5ebd3d;
  }
  ${({ styles }) => styles}
`;

const AvatarFrame = styled.div<IStyles & { avatarSize: number; place?: number }>`
  --size: ${({ avatarSize }) => avatarSize ?? 104}px;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* background-image: linear-gradient(219.74deg, #b48b23 -4.97%, #ffda7a 47.59%, #725a05 93.01%); */
  width: calc(var(--size) + 5px * 2);
  height: calc(var(--size) + 5px * 2);
  border-radius: calc((var(--size) + 5px * 2) / 2);
  box-shadow: 0px 0px 0px 4px #141721;
  position: relative;

  span {
    width: 100%;
    height: 100%;
    background-color: var(--fallback);
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-style: italic; */
    font-family: "Golos Text";
    font-weight: 600;
    font-size: 32px;
    opacity: 0.8;
  }

  &::before {
    content: "";
    border-radius: inherit;
    position: absolute;
    inset: 0;
    box-shadow: 0px 0px 40px 10px var(--drop-shadow);
  }

  ${({ place }) => {
    switch (place) {
      case 1:
        return css`
          --drop-shadow: rgba(255, 236, 135, 0.1);
          --fallback: #987b34;
          transform: translateY(-35%);
          background: linear-gradient(
            150deg,
            rgba(244, 218, 79, 1) 0%,
            #f4ec4f 6%,
            rgba(255, 236, 135, 1) 12%,
            rgba(235, 210, 42, 1) 50%,
            rgba(244, 218, 79, 1) 62%,
            rgba(232, 199, 22, 1) 92%,
            rgba(255, 236, 135, 1) 100%
          );
        `;
      case 2:
        return css`
          --drop-shadow: rgba(79, 79, 79, 0.3);
          --fallback: #1c1c22;
          transform: translateY(-50%);
          background: linear-gradient(
            150deg,
            rgba(79, 79, 79, 1) 0%,
            rgba(217, 215, 207, 1) 13%,
            rgba(215, 209, 193, 1) 18%,
            rgba(79, 79, 79, 1) 48%,
            rgba(206, 203, 188, 1) 79%,
            rgba(219, 207, 189, 1) 84%,
            rgba(79, 79, 79, 1) 100%
          );
        `;
      case 3:
        return css`
          --drop-shadow: rgba(250, 210, 187, 0.1);
          --fallback: #0c1617;
          transform: translateY(-50%);
          background: linear-gradient(
            150deg,
            rgba(105, 69, 61, 1) 0%,
            rgba(134, 91, 77, 1) 6%,
            rgba(250, 210, 187, 1) 12%,
            rgba(134, 91, 77, 1) 50%,
            rgba(105, 69, 61, 1) 78%,
            rgba(250, 210, 187, 1) 89%,
            rgba(134, 91, 77, 1) 100%
          );
        `;

      default:
        return css`
          transform: translateY(-50%);
        `;
    }
  }}
  ${({ styles }) => styles}
`;

const Avatar = styled.div<IStyles & { avatarSize: number }>`
  --size: ${({ avatarSize }) => avatarSize ?? 104}px;
  box-sizing: border-box;
  position: relevant;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: var(--size);
  height: var(--size);
  margin: 5px;
  overflow: hidden;
  /* background-color: white; */
  /* border: 4px solid rgba(16, 17, 25, 1); */
  border-radius: calc(var(--size) / 2);
  ${({ styles }) => styles}
`;

const AvatarPicture = styled.img<IStyles>``;

const Reward = styled.div<IStyles>`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 68px;
  background-color: #00000041;
  /* border-top: 1px solid rgba(210, 234, 255, 0.05); */
  border-radius: 0 0 6px 6px;
  /* box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.1); */
  font-family: "Roboto";
  font-size: 24px;
  /* font-style: italic; */
  font-weight: 600;
  letter-spacing: 0.03em;
  line-height: 36px;
  text-shadow: 0px 0px 20px rgba(94, 189, 61, 0.5);
  color: #d2eaff;

  text-transform: uppercase;
  &::after {
    content: "";
    display: block;
    position: absolute;
    inset: 0;
    background: transparent;
    z-index: 0;
    backdrop-filter: blur(12px);
    border-radius: inherit;
  }

  .custom-reward {
    width: 100%;
    z-index: 1;
  }

  & > span {
    z-index: 1;
    &::first-letter {
      color: #5ebd3d;
    }
  }
  ${({ styles }) => styles}
`;
