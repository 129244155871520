import Image from "next/image";
import React from "react";
import styled, { css } from "styled-components";
import { $stylesReset } from "components";
import { ExtendedFlexProps, AbsoluteBoxProps } from "./types";
import { Flex } from "components/common";

export interface IPachinkoComponent {
  id?: string;
}

const Screen = styled.div`
  ${$stylesReset}
  position: relative;
  max-width: 1920px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  overflow: hidden;
  width: 80%;
  @media (max-width: 1600px) {
    width: 90%;
  }
`;

const Content = styled.div`
  ${$stylesReset}
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
`;

const AbsoluteBox = styled.div<AbsoluteBoxProps>`
  position: absolute;
  ${({ left }) =>
    left &&
    css`
      left: 20%;
      top: 20%;
      @media (max-width: 1600px) {
        left: 30%;
        top: 31%;
      }
    `};
  ${({ top }) =>
    top &&
    css`
      top: 2%;
      left: 35%;
      @media (max-width: 1600px) {
        top: 5%;
        left: 45%;
      }
    `};
  ${({ right }) =>
    right &&
    css`
      right: 20%;
      top: 20%;
      @media (max-width: 1600px) {
        right: 30%;
        top: 20%;
      }
    `};
`;

const ExtendedFlex = styled(Flex)<ExtendedFlexProps>`
  justify-content: center;
  width: 100%;
  ${({ centered }) =>
    centered &&
    css`
      justify-content: flex-start;
      @media (max-width: 1600px) {
        justify-content: center;
      }
    `};
  ${({ trophy }) =>
    trophy &&
    css`
      justify-content: center;
      margin-top: 80px;
      // margin-left:
      justify-content: flex-start;
      @media (max-width: 1600px) {
        justify-content: center;
        margin-left: 0px;
      }
    `};
  ${({ stat }) =>
    stat &&
    css`
      align-items: flex-start;
      @media (max-width: 1600px) {
        align-items: center;
      }
    `};
  ${({ left }) =>
    left &&
    css`
      width: 70%;
      @media (max-width: 1600px) {
        width: 100%;
      }
    `};
  ${({ right }) =>
    right &&
    css`
      width: 30%;
      @media (max-width: 1600px) {
        width: 100%;
      }
    `};
  ${({ faq }) =>
    faq &&
    css`
      justify-content: flex-start;
      @media (max-width: 1600px) {
        justify-content: center;
      }
    `};
`;

export function formatNumber(value: number | string): string | number {
  if (!value) {
    return 0;
  }
  const strValue = value.toString();
  const parts = strValue.split(".");

  const wholeNumber = parts[0];
  const decimal = parts[1];

  const formattedWholeNumber = addCommasToThousandCount(Number(wholeNumber));

  if (decimal) {
    const formattedDecimal = decimal.replace(/0+$/, "");
    if (formattedDecimal.length > 0) {
      return `${formattedWholeNumber}.${formattedDecimal}`;
    }
  }

  return formattedWholeNumber;
}

function addCommasToThousandCount(value: number | string): string | number {
  const strValue = value.toString();
  const chars = strValue.split("");

  const reversedChars = chars.reverse();
  const formattedChars = [];

  for (let i = 0; i < reversedChars.length; i++) {
    if (i !== 0 && i % 3 === 0) {
      formattedChars.push(",");
    }
    formattedChars.push(reversedChars[i]);
  }

  const formattedValue = formattedChars.reverse().join("");

  return formattedValue;
}
export const TitleText = styled.h1`
  color: rgba(210, 234, 255, 1);
  font-family: Roboto;
  font-size: 18px;
  font-style: italic;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 23px;
  letter-spacing: 0.03em;
  text-align: left;
  text-shadow: 0px 2px 4px rgba(11, 14, 35, 1), 0px 0px 6px rgba(58, 113, 254, 0.46),
    0px 0px 24px rgba(40, 113, 255, 0.5);
`;

const Text = styled.p<{ staticHeight?: boolean }>`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: center;
  color: rgba(210, 234, 255, 0.75);
  ${({ staticHeight }) =>
    staticHeight &&
    css`
      @media (min-width: 1280px) {
        height: 63px;
      }
    `};
`;

const StatText = styled.p`
  font-family: Roboto;
  font-size: 32px;
  font-style: italic;
  font-weight: 900;
  line-height: 52px;
  letter-spacing: 0.03em;
  text-align: left;
  color: rgba(59, 139, 233, 1);
  @media (min-width: 1280px) {
    font-size: 26px;
  }
  @media (min-width: 1480px) {
    font-size: 33px;
  }
`;

const Card = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  gap: 8px;
  width: 236px;
  height: 262px;
  margin-top: 10px;
  margin-right: 10px;

  background: radial-gradient(
      152.76% 130.7% at 50% 0%,
      rgba(101, 101, 101, 0.05) 0%,
      rgba(101, 101, 101, 0) 100%
    ),
    #151c2c;
  border-radius: 8px;

  @media (max-width: 1280px) {
    width: 100%;
    margin-top: 20px;
    margin-right: 0px;
  }
`;

const cards = [
  {
    image: "/images/PachinkoComponent/slots.png",
    title: "BONUS BUY SLOTS",
    text: "Get up to $5,000 in slots bonuses and keep all the profits.",
    height: 110,
    width: 170,
  },
  {
    image: "/images/PachinkoComponent/coin.png",
    title: "CRYPTO PRIZE",
    text: "Hundreds of dollars in instant prizes that are cashed out to the winners preferred crypto address.",
    height: 110,
    width: 104,
  },
  {
    image: "/images/PachinkoComponent/coins.png",
    title: "W POINTS",
    text: "Wrewards points that can be spent in the points shop or used to join raffles and giveaways.",
    height: 90,
    width: 90,
  },
];

const BorderBottomDiv = styled.div`
  border-bottom: 3px solid #1d2431;
  margin: auto;
  width: 100%;
  height: 100%;
`;

export const Prizes = () => {
  const [prizes, setPrizes] = React.useState<any>({
    totalPrize: null,
    count: 0,
  });
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const urlOrigin: string = process.env.NEXT_PUBLIC_APP_BE_ORIGIN ?? "http://localhost:4000";
        const response = await fetch(`${urlOrigin}/pachinko-winner/total-and-count`);

        const data = await response.json();
        setPrizes(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Screen data-star>
      <Content>
        <BorderBottomDiv />
        <Flex switchToColumn width="100%" height="100%">
          <ExtendedFlex left align="center" column>
            <ExtendedFlex width="100%" centered align="center">
              <Image src="/images/PachinkoComponent/box.png" height={60} width={60} alt="image" />
              <TitleText>Potential Prizes</TitleText>
            </ExtendedFlex>
            <ExtendedFlex switchToColumn>
              {cards.map(({ image, title, text, height, width }) =>
                title === "W POINTS" ? (
                  <Card
                    style={{
                      width: "100%",
                      height: "310px",
                      position: "relative",
                    }}
                    key={title}
                  >
                    <Image src={image} height={100} width={100} alt="image" />
                    <AbsoluteBox left>
                      <Image
                        src="/images/PachinkoComponent/left-coin.png"
                        height={40}
                        width={40}
                        alt="image"
                      />
                    </AbsoluteBox>
                    <AbsoluteBox top>
                      <Image
                        src="/images/PachinkoComponent/top-coin.png"
                        height={30}
                        width={30}
                        alt="image"
                      />
                    </AbsoluteBox>
                    <AbsoluteBox right>
                      <Image
                        src="/images/PachinkoComponent/right-coin.png"
                        height={25}
                        width={25}
                        alt="image"
                      />
                    </AbsoluteBox>
                    <TitleText>{title}</TitleText>
                    <Text staticHeight>{text}</Text>
                  </Card>
                ) : (
                  <Card style={{ width: "100%", height: "310px" }} key={title}>
                    <div style={{ flexShrink: 0 }}>
                      <Image src={image} height={height} width={width} alt="image" />
                    </div>
                    <TitleText>{title}</TitleText>
                    <Text staticHeight>{text}</Text>
                  </Card>
                )
              )}
            </ExtendedFlex>
          </ExtendedFlex>
          <ExtendedFlex right style={{ height: "300px" }} column>
            <ExtendedFlex trophy centered align="center" justify="flex-start">
              <Image
                src="/images/PachinkoComponent/trophy.png"
                height={60}
                width={60}
                alt="image"
              />
              <TitleText>Total Pachinko wins</TitleText>
            </ExtendedFlex>
            <Card
              style={{
                width: "100%",
                margin: "10px 0px 10px",
                height: "50%",
                alignItems: "flex-start",
              }}
            >
              <Flex align="center">
                <Image
                  src="/images/PachinkoComponent/big-box.png"
                  height={100}
                  width={100}
                  alt="image"
                />
                <ExtendedFlex stat column>
                  <Text>Prizes Distributed</Text>
                  <StatText style={{ margin: 0 }}>
                    {prizes.totalPrize !== null && typeof prizes.totalPrize === "number"
                      ? `$${formatNumber(prizes.totalPrize.toFixed(2))}`
                      : "$0"}
                  </StatText>
                </ExtendedFlex>
              </Flex>
            </Card>
            <Card
              style={{
                width: "100%",
                margin: "0px",
                height: "50%",
                alignItems: "flex-start",
              }}
            >
              <Flex align="center">
                <Image
                  src="/images/PachinkoComponent/big-user.png"
                  height={100}
                  width={100}
                  alt="image"
                />
                <ExtendedFlex stat column>
                  <Text>Awarded Users</Text>
                  <StatText style={{ margin: 0 }}>{prizes.count ?? "-"}</StatText>
                </ExtendedFlex>
              </Flex>
            </Card>
          </ExtendedFlex>
        </Flex>
        <ExtendedFlex faq width="100%" style={{ marginTop: 50 }} align="center">
          <Image src="/images/PachinkoComponent/faq-1.png" height={60} width={60} alt="image" />
          <TitleText>HOW IT WORKS</TitleText>
        </ExtendedFlex>
        <Card style={{ width: "100%", padding: 40, margin: 0, height: "auto" }}>
          <Text style={{ fontSize: 18, textAlign: "left" }}>
            When it is time for a Pachinko round, WatchGamesTV will announce a giveaway on stream.
            Viewers will have the opportunity to type the keyword to enter, this will put them in a
            raffle for a chance to play. Players can win bonus buys, cash prizes & points to use in
            the stream store. Any form of inactivity, and or a breach of TOS will void the winner
            and reroll another person.
          </Text>
        </Card>
      </Content>
    </Screen>
  );
};
