import React from "react";
import styled, { css } from "styled-components";
import { $stylesReset, IStyles, Ident } from "components";
import { Button, ButtonBlue } from "components/common";
import { useRouter } from "next/router";

const BONUS_REWARDS: IReward[] = [
  {
    icon: "/images/AllRewardsComponent/Coin.png",
    title: "Points shop",
    description: "Choose your own bonuses and buy them using WRewards points.",
    button: "Buy Exclusive Bonuses",
    link: "/points-shop",
  },
  {
    icon: "/images/AllRewardsComponent/Tickets.png",
    title: "Raffles",
    description:
      "Enter into our exclusive raffles by participating under any of our supported codes! Prizes can take up to 15 days to receive.",
    button: "Win Amazing Prizes",
    link: "/raffles",
  },
  {
    icon: "/images/AllRewardsComponent/Crown.png",
    title: "Leaderboard",
    description:
      "Our exclusive leaderboard rewards the highest wagered users under the supported codes.",
    button: "Join Competition",
    link: "/leaderboards",
  },
];

interface IReward {
  icon: string;
  title: string;
  description: string;
  link: string;
  button: string;
}

export interface IAllRewardsComponent {
  id?: string;
}

export const AllRewardsComponent: React.FC<IAllRewardsComponent> = ({ id }): JSX.Element => {
  const [items, _] = React.useState<IReward[]>(BONUS_REWARDS);

  const router = useRouter();

  return (
    <Box id={id}>
      <Screen>
        <Content>
          <Ident height={80} />
          <TextTitle>
            All &nbsp;
            <span data-blue>Rewards</span>
          </TextTitle>
          <Text>
            <br />
            Earn amazing bonuses with WREWARDS!
            <br />
            <br />
            <br />
          </Text>
          <CardsBox>
            <Cards>
              {items.map((item, index) => {
                return (
                  <Card key={index}>
                    <CardIcon
                      data-hover
                      styles={css`
                        background-image: url("${item.icon}");
                      `}
                    />
                    <CardTitle>{item.title}</CardTitle>
                    <Text>{item.description}</Text>
                    <CardAction>
                      <Button
                        onClick={() => router.push(item.link)}
                      >
                        <ButtonBlue>Pick</ButtonBlue>
                      </Button>
                    </CardAction>
                  </Card>
                );
              })}
            </Cards>
          </CardsBox>
          <Ident height={40} />
        </Content>
      </Screen>
    </Box>
  );
};

const Box = styled.div`
  ${$stylesReset}
  background-color: #0b0e16;
`;

const Screen = styled.div`
  ${$stylesReset}
  position: relative;
  max-width: 1920px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  overflow: hidden;
`;

const Content = styled.div`
  ${$stylesReset}
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 1320px;
  margin: 24px;
  overflow: hidden;

`;

const Text = styled.div`
  ${$stylesReset}
  color: rgba(210, 234, 255, 0.75);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: center;
`;

const TextTitle = styled(Text)`
  color: rgb(210, 234, 255); 
  font-size: 40px;
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  & [data-blue] {
    color: #3b8be9;
  }
  @media (max-width: 800px) {
    color: #d2eaff;
    font-size: 28px;

  }
`;


const CardsBox = styled.div`
  display: block;
  width: 100%;
  overflow: hidden;

`;

const Cards = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 24px;

`;

const Card = styled.div`
  box-sizing: border-box;
  position: relative;
  max-width: 421px;
  min-width: 327px;
  height: 371px;
  width: 421px;
  margin-top: 104px;
  padding: 147px 24px 24px 24px;
  overflow: visible;

  background: radial-gradient(
      152.76% 130.7% at 50% 0%,
      rgba(101, 101, 101, 0.05) 0%,
      rgba(101, 101, 101, 0) 100%
    ),
    #151c2c;
  border: 1px solid rgba(210, 234, 255, 0.1);
  border-radius: 8px;

  text-align: center;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 421px;
    height: 123.9px;

    background-clip: border-box;
    background-image: url("/images/AllRewardsComponent/card-bg.svg");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 421px 123.9px;
    border-radius: 8px;
  }

  &:hover [data-hover]::before {
    --k: 0.5;
    content: "";
    position: absolute;
    top: 0px;
    left: -25%;
    width: calc(1338px * var(--k));
    height: calc(950px * var(--k));
    overflow: visible;
    background-clip: border-box;
    background-image: url("/images/AllRewardsComponent/Lights.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 
  }
`;

const CardAction = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
`;

const CardIcon = styled.div<IStyles>`
  box-sizing: border-box;
  position: absolute;
  top: -127px;
  left: 0;
  right: 0;
  height: 254.08px;
  display: flex;
  justify-content: center;
  align-items: center;


  background-clip: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 144px 144px;

  ${({ styles }) => styles}
`;

const CardTitle = styled.div<IStyles>`
  // text
  color: #d2eaff;
  font-family: "Roboto";
  font-size: 26px;
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.03em;
  line-height: 30px;
  text-align: center;
  text-shadow: 0px 0px 24px rgba(40, 113, 255, 0.5), 0px 0px 6px rgba(58, 113, 254, 0.46),
    0px 2px 4px #0b0e23;
  text-transform: uppercase;

  ${({ styles }) => styles}
`;
