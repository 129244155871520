import numeral from "numeral";
import * as luxon from "luxon";
import React from "react";
import styled, { css } from "styled-components";

import { IStyles } from "components";
import { ILeaderboardWithWinnerDto } from "interfaces";
import { useRouter } from "next/router";
import { formatNumber } from "./utils/formatNumbers";

export interface ILeaderboardHistoryCard {
  leaderboard?: ILeaderboardWithWinnerDto;
  doHref?: (leaderboard?: ILeaderboardWithWinnerDto) => string;
}

export const LeaderboardHistoryCard: React.FC<ILeaderboardHistoryCard> = ({
  leaderboard,
  doHref = (leaderboard?: ILeaderboardWithWinnerDto) => `/leaderboards/${leaderboard?.id ?? "xxx"}`,
}): React.ReactElement => {
  const router = useRouter();
  const value = formatNumber(leaderboard?.totalPrizePool ? leaderboard?.totalPrizePool : 0);
  const endDate = leaderboard?.endDate
    ? luxon.DateTime.fromISO(leaderboard?.endDate).toFormat("dd-MM-yyyy")
    : "?";
  const startDate = leaderboard?.startDate
    ? luxon.DateTime.fromISO(leaderboard?.startDate).toFormat("dd-MM-yyyy")
    : "?";
  const href = doHref(leaderboard); // `/leaderboards/${ leaderboard?.id ?? 'xxx' }`;

  return (
    <Box onClick={() => router.push(href)}>
      <AnimatedBackground />
      <BoxContent>
        <Header>Leaderboard</Header>

        <Content>
          <span>Prize Pool</span>
          <br />
          <Prize>
            <b>{`$`}</b>
            {value}
          </Prize>
        </Content>

        <Footer>
          <div>
            <FooterItemTitle>started</FooterItemTitle>
            {startDate}
          </div>
          <div>
            <FooterItemTitle>ended</FooterItemTitle>
            {endDate}
          </div>
        </Footer>
      </BoxContent>
    </Box>
  );
};

const Box = styled.div<IStyles>`
  box-sizing: border-box;
  width: 315px;
  height: 244px;
  position: relative;
  border-radius: 0.5rem;
  cursor: pointer;
  ${({ styles }) => styles}
`;

const BoxContent = styled.div<IStyles>`
  padding-top: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  font-family: "Golos Text", sans-serif;
`;

const AnimatedBackground = styled.div<IStyles & { place?: number }>`
  --image: url("/images/leaderboard/LeaderboardWinnerCard/Vector_v2.svg");
  --gradient: linear-gradient(180deg, rgba(57, 57, 80, 0.75) 0%, rgba(24, 28, 44, 0.75) 140.57%);

  position: absolute;
  inset: 0;
  z-index: 0;
  display: flex;

  border-radius: inherit;
  overflow: hidden;
  background-image: var(--gradient);

  &::before,
  &::after {
    content: "";
    position: absolute;
    inset: -100px;
    display: block;
    z-index: 0;
    width: 600px;
    height: 600px;
    border-radius: inherit;
    background-size: contain;
    background-image: var(--image);
    opacity: 0.1;
  }

  &::after {
    transform-origin: center top;
    animation: diagonalMove1 30s linear infinite;
  }
  /* fix rotation */
  &::before {
    margin-top: 3rem;
    transform-origin: center right;
    animation: diagonalMove2 30s linear infinite;
  }
  ${({ place }) => {
    switch (place) {
      case 1:
        return css`
          --gradient: linear-gradient(150deg, #bcad60 0%, #cdae43 30%, #79591a 80%, #a07d21 100%);
        `;
      case 2:
        return css`
          --gradient: radial-gradient(
              152.76% 130.7% at 50% 0%,
              rgba(101, 101, 101, 0.35) 0%,
              rgba(145, 145, 145, 0.35) 0%,
              rgba(120, 137, 163, 0) 100%
            ),
            linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 93.1%);
          background-color: #13171f;
        `;
      case 3:
        return css`
          --gradient: radial-gradient(
              149.16% 127.62% at 50% -23.77%,
              #534a40 21.03%,
              #462a241c 100%
            ),
            linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 93.1%);
        `;
      default:
        return null;
    }
  }}
`;

const Header = styled.h6<IStyles>`
  color: rgba(210, 234, 255, 0.75);
  margin: 0;
  font-family: "Golos Text", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    height: 1px;
    width: 100%;
    background: rgba(210, 234, 255, 0.05);
    z-index: 0;
  }
`;

const Content = styled.div<IStyles>`
  color: rgba(210, 234, 255, 0.75);

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: center;
  padding: 0 2rem;
`;

const Prize = styled.span<IStyles>`
  color: rgba(210, 234, 255, 0.75);

  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: center;
  padding: 0 2rem;
  font-weight: 600;
  font-size: 2.5rem;

  & > b {
    color: #5ebd3d;
  }
`;

const Footer = styled.div<IStyles>`
  color: rgba(210, 234, 255, 0.75);

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: center;
  border-top: 1px solid rgba(210, 234, 255, 0.05);
  display: flex;
  & > div {
    width: 50%;
    padding: 1.125rem;
    position: relative;
    /* height: 60px; */
  }
  & > div:first-child {
    border-right: 1px solid rgba(210, 234, 255, 0.05);
  }
`;

const FooterItemTitle = styled.div<IStyles>`
  position: absolute;
  top: 0;
  left: 50%;
  text-transform: uppercase;
  font-size: 0.75rem;
  transform: translate(-50%, -50%);
`;
