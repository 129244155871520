import React from "react";
import styled, { css } from "styled-components";
import { Flex, IStyles } from "components/common";
import Link from "next/link";
import { TheLeaderboardSourceKeys } from "interfaces";
import Image from "next/image";

interface LeaderboardHowToPlayProps {
  casinoProvider: string;
}

const Title = styled.h5`
  font-family: Roboto;
  font-size: 48px;
  margin: 0px 0px 80px;
  text-transform: uppercase;
  font-style: italic;
  font-weight: 900;

  text-align: center;
  color: #e9f4ff;
  & b {
    background: linear-gradient(
      88.51deg,
      #2b7ae2 -11.93%,
      #4fc9f4 32.84%,
      #87e9ff 44.22%,
      #4fd1f4 55.23%,
      #2884e0 100%
    );
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const Wrapper = styled.div`
  max-width: 1008px;
  padding: 0 1rem;
  margin: 1rem auto;
`;
const GridBox = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem 1rem;
`;

const Box = styled.div<IStyles & { number: number }>`
  min-width: 280px;
  flex: 1;
  padding: 2.5rem 1.5rem;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%233B8BE9FF' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='23' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 0.375rem;
  background-color: rgba(59, 140, 233, 0.05);
  font-family: "Golos Text";
  position: relative;
  &::before {
    content: "${({ number }) => number}";
    font-family: "Roboto";
    font-style: italic;
    font-weight: 900;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: #131620;
    border-radius: 40px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 0px 2px #1d202b;
    border: 2px solid #131620;
  }
`;

const BottomBox = styled.div`
  width: 100%;
  margin: 1rem auto 5rem;
  padding: 1.5rem;
  border: 1px dashed #e9f4ff40;
  border-radius: 0.375rem;
  background-color: rgba(59, 140, 233, 0.05);
  font-family: "Golos Text";
  font-weight: 400;
  font-size: 14px;
  position: relative;
  color: #e9f4ffbf;
  text-align: center;
`;

const BoxTitle = styled.h6`
  margin: 0 0 1rem;
  font-weight: 600;
  font-size: 1rem;
  color: #3b8be9;
  text-transform: uppercase;
  text-align: center;
`;

const BoxContent = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 21px;
  color: #e9f4ffbf;
  text-align: center;

  & a {
    text-transform: uppercase;
    color: #3b8be9;
    display: inline-flex;
    align-items: center;
    gap: 6px;
    &:hover {
      opacity: 0.8;
    }
  }
`;

const getDynamicValues = (provider: string) => {
  switch (provider) {
    case TheLeaderboardSourceKeys.hypedrop:
      return {
        wgtvHref: "https://hypedrop.com/r/WGTV",
        watchGamesTVHref: "https://hypedrop.com/r/watchgamestv",
        providerHref: "https://hypedrop.com/",
        providerName: "Hypedrop",
      };
    case TheLeaderboardSourceKeys.gamdom:
      return {
        wgtvHref: "https://gamdom.com/r/wgtv",
        watchGamesTVHref: "https://gamdom.com/r/watchgamestv",
        providerHref: "https://gamdom.com/",
        providerName: "Gamdom",
      };
    case TheLeaderboardSourceKeys.packdraw:
      return {
        wgtvHref: "https://packdraw.com/?ref=wgtv",
        watchGamesTVHref: "https://packdraw.com/?ref=watchgamestv",
        providerHref: "https://packdraw.com/",
        providerName: "PackDraw",
      };
    case TheLeaderboardSourceKeys.clash:
      return {
        wgtvHref: "https://clash.gg/r/WGTV",
        watchGamesTVHref: "https://clash.gg/r/watchgamestv",
        providerHref: "https://clash.gg",
        providerName: "Clash",
      };

    case TheLeaderboardSourceKeys.cases:
      return {
        wgtvHref: "https://cases.gg/r/WGTV",
        watchGamesTVHref: "https://cases.gg/r/watchgamestv",
        providerHref: "https://cases.gg",
        providerName: "Cases",
      };

    default:
      return {
        wgtvHref: "/",
        watchGamesTVHref: "/",
        providerHref: "/",
        providerName: "WRewards",
      };
  }
};

const LeaderboardHowToPlay: React.FC<LeaderboardHowToPlayProps> = ({ casinoProvider }) => {
  const { watchGamesTVHref, wgtvHref, providerName, providerHref } =
    getDynamicValues(casinoProvider);

  return (
    <Wrapper id="how-to-play">
      <Title>
        How it <b>works</b>
      </Title>
      <GridBox>
        <Box number={1}>
          <BoxTitle>
            {casinoProvider === TheLeaderboardSourceKeys.wrewards
              ? "Get points"
              : "Wager Using refferal Code"}
          </BoxTitle>

          {casinoProvider === TheLeaderboardSourceKeys.wrewards ? (
            <BoxContent>
              Gain points by attending the WRewards team streams and exchange them into GPoints to
              play.
            </BoxContent>
          ) : (
            <BoxContent>
              <Link
                target="_blank"
                href={wgtvHref}
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(wgtvHref);
                }}
              >
                WGTV
                <Image src="/images/icons/copy.svg" height={14} width={14} alt="image" />
              </Link>
              {` or `}
              <Link
                target="_blank"
                href={watchGamesTVHref}
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(watchGamesTVHref);
                }}
              >
                WatchGamesTV
                <Image src="/images/icons/copy.svg" height={14} width={14} alt="image" />
              </Link>
              <br />
              {` on `}
              <Link target="_blank" href={watchGamesTVHref}>
                {`${providerName}`}
              </Link>
              .
            </BoxContent>
          )}
        </Box>
        <Box number={2}>
          <BoxTitle>
            {casinoProvider === TheLeaderboardSourceKeys.wrewards
              ? "Provably fair"
              : "Do not hide your profile"}
          </BoxTitle>
          <BoxContent>
            {casinoProvider === TheLeaderboardSourceKeys.wrewards
              ? "All games are 100% provably fair and have an option to verify any round to make sure it was fair."
              : `Users with HIDDEN PROFILES will not be shown on leaderboard and will not earn a prize
            when leaderboard ends`}
          </BoxContent>
        </Box>
        <Box number={3}>
          <BoxTitle>monthly reset</BoxTitle>
          <BoxContent>
            Stay competitive! The Leaderboard is refreshed every{" "}
            {casinoProvider === TheLeaderboardSourceKeys.wrewards ? "10 minutes" : "12 hours"}
          </BoxContent>
        </Box>
      </GridBox>
      <BottomBox>
        {`Please note, to ensure a fair gaming environment, refrain from misusing ${providerName}'s reward
          system. Each user will be manually checked for complying with leaderboard rules, and
          abuse.${
            casinoProvider === TheLeaderboardSourceKeys.gamdom
              ? " Wagering on Gamdom's house games (Roulette, Dice.. etc) & games like Baccarat & more is not allowed. If any other abuse is found, on games offered by Gamdom you will be banned and not paid out."
              : ""
          }`}
      </BottomBox>
    </Wrapper>
  );
};

export default LeaderboardHowToPlay;
