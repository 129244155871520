import React from "react";
import styled, { css } from "styled-components";
import { $stylesReset, IStyles, TheButton } from "components";
import Image from "next/image";
import Tilt from "react-parallax-tilt";
import { Button, ButtonBlue, Flex } from "components/common";
import { useRouter } from "next/router";

export interface IHeroComponent {
  id?: string;
  hrefs?: {
    bonuses?: string;
    enjoy?: string;
  };
}

const AbsoluteDiv = styled.div`
  position: absolute;
  top: 0;
  display: block;
  width: 100vw;
  height: 100%;
  background-image: linear-gradient(to right, rgba(12, 14, 22, 0), rgba(12, 14, 22, 1)),
    url("/images/HeroComponent/vector.png");
  animation: bgScrollY 100s linear infinite;
`;

export const HeroComponent: React.FC<IHeroComponent> = ({
  id,
  hrefs = {
    bonuses: "/all-bonuses",
    enjoy: "https://www.youtube.com/@WatchGamesTV",
  },
}): JSX.Element => {
  const router = useRouter();
  return (
    <Box id={id}>
      <Screen>
          <Img
            src="/images/HeroComponent/lights.svg"
            priority
            width={1920}
            height={980}
            alt="light"
            styles={css`
            position: absolute;
            top: 0;
          `}
          />
          <AbsoluteDiv />
          <Tilt className="parallax-effect" perspective={600}>
            <Content>
              <Hero>
                <Img
                  src="/images/HeroComponent/gool-hv4-1@2x.png"
                  width={77}
                  height={78}
                  priority
                  alt="hv4"
                  styles={css`
                  position: absolute;
                  bottom: 35px;
                  left: 0;
                  @media (min-width: 800px) {
                    width: 81.24px;
                    height: 82.45px;
                  }
                  animation: float 3.5s ease-in-out infinite;
                `}
                />
                <Img
                  src="/images/HeroComponent/tigertooth-shadow.png"
                  width={223}
                  priority
                  height={173}
                  alt="tigertooth"
                  styles={css`
                  position: absolute;
                  top: -50px;
                  left: -40px;
                  @media (min-width: 800px) {
                    width: 277.73px;
                    height: 206.24px;
                  }
                `}
                />
                <Img
                  src="/images/HeroComponent/tigertooth-2@2x.png"
                  width={144}
                  height={87}
                  priority
                  alt="tigertooth"
                  styles={css`
                  position: absolute;
                  top: 0;
                  left: 10px;
                  @media (min-width: 800px) {
                    width: 177.73px;
                    height: 106.24px;
                  }
                  animation: float 2s ease-in-out infinite;
                `}
                />
                <Img
                  src="/images/HeroComponent/scatter-shadow.png"
                  width={210}
                  height={218}
                  priority
                  alt="scatter"
                  styles={css`
                  position: absolute;
                  top: -50px;
                  right: -40px;
                  @media (min-width: 800px) {
                    width: 292.08px;
                    height: 285.85px;
                  }
                `}
                />
                <Img
                  src="/images/HeroComponent/gool-scatter-win-1-1@2x.png"
                  width={104}
                  height={101}
                  priority
                  alt="scatter"
                  styles={css`
                  position: absolute;
                  top: 0;
                  right: 10px;
                  @media (min-width: 800px) {
                    width: 132.08px;
                    height: 125.85px;
                  }
                  animation: float 3s ease-in-out infinite;
                `}
                />
                <Img
                  src="/images/HeroComponent/coin-shadow.png"
                  width={223}
                  priority
                  height={288}
                  alt="coin"
                  styles={css`
                  position: absolute;
                  bottom: -100px;
                  right: -100px;
                  @media (min-width: 800px) {
                    width: 322px;
                    height: 322px;
                  }
                `}
                />
                <Img
                  src="/images/HeroComponent/23-1@2x.png"
                  width={88}
                  height={88}
                  priority
                  alt="coin"
                  styles={css`
                  position: absolute;
                  bottom: -10px;
                  right: 0;
                  @media (min-width: 800px) {
                    width: 122px;
                    height: 122px;
                  }
                  animation: float 4s ease-in-out infinite;
                `}
                />
                <Img
                  src="/images/HeroComponent/vector5@2x.png"
                  width={190}
                  priority
                  height={181}
                  alt="hero"
                  styles={css`
                  position: absolute;
                  bottom: 0;
                  @media (min-width: 800px) {
                    width: 235px;
                    height: 220px;
                  }
                  animation: float 5s ease-in-out infinite;
                `}
                />
              </Hero>
              <Img
                src="/images/HeroComponent/wrewards.svg"
                width={643}
                priority
                height={127}
                alt="wr"
                styles={css`
                @media (max-width: 643px) {
                  width: 100%;
                }
              `}
              />
            </Content>
          </Tilt>
          <ExtendedFlex column align="center" justify="center">
            <TextWGTV>
              BY WGTV
              <br />
              <br />
            </TextWGTV>
            <TextVIP>
              A VIP experience through personalized rewards, leaderboards, raffles & more! Earn
              rewards on your favorite casinos everyday!
              <br />
              <br />
              <br />
            </TextVIP>
            <Buttons>
              <Button
                onClick={(event: React.MouseEvent) => {
                  event.preventDefault();
                  router.push(hrefs?.bonuses ?? "#");
                }}
                style={{ height: 50, cursor: "pointer", width: 209 }}
              >
                <ButtonBlue style={{ whiteSpace: "nowrap" }} transparent>
                  All Bonuses
                </ButtonBlue>
              </Button>
              <TheButton
                styles={css`
                transition: 0.5s;

                &:after {
                  content: "»";
                  position: absolute;
                  opacity: 0;
                  top: 14px;
                  right: 8px;
                  color: red; /* Add this line to set the color */
                  transition: 0.5s;
                }

                &:hover {
                  padding-right: 24px;
                  padding-left: 8px;

                  &:after {
                    opacity: 1;
                  }
                }
              `}
                href={hrefs?.enjoy ?? "#"}
                preset="youtube"
              >
                Enjoy the content
              </TheButton>
            </Buttons>
          </ExtendedFlex>
      </Screen>
    </Box>
  );
};

const ExtendedFlex = styled(Flex)<IStyles>`
  @media (min-width: 800px) {
    padding-bottom: calc(192px - 24px);
  }
  ${({ styles }) => styles}
`;

const Box = styled.div`
  ${$stylesReset}
  background-color: #0b0e16;
`;

const Screen = styled.div`
  ${$stylesReset}
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
`;

const Content = styled.div`
  ${$stylesReset}
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 654px;
  margin: 24px;
  @media (min-width: 800px) {
    padding-top: calc(142px - 24px);
  }
`;

const Hero = styled.div`
  ${$stylesReset}
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-end;
  width: 329px;
  height: 271px;
  @media (min-width: 800px) {
    width: 483px;
    height: 324px;
  }
`;

const Text = styled.div`
  ${$stylesReset}
  color: rgba(210, 234, 255, 0.75);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: center;
`;

const TextWGTV = styled(Text)``;

const TextVIP = styled(Text)`
  max-width: 652px;
`;

const Img = styled(Image)<IStyles>`
  ${({ styles }) => styles}
`;

const Buttons = styled.div`
  ${$stylesReset}
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
  color: #17141a;
  font-size: 14px;
  text-align: left;
`;

