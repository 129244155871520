import { IStyles } from "components/common";
import numeral from "numeral";
import styled from "styled-components";

export interface IThePointsBalance {
  value: number | null | undefined;
  isFixed?: boolean;
  precision?: number;
}

const fixed = (format: string, value: number, length: number = 1): string => {
  const fixed = numeral(value).format(format).padStart(length, "0");
  return fixed;
};

const formatNumber = (value: number | string, precision: number = 2): string | number => {
  const strValue = value.toString();
  const parts = strValue.split(".");

  const wholeNumber = parts[0];
  const decimal = parts[1];

  const formattedWholeNumber = addCommasToThousandCount(Number(wholeNumber));

  if (decimal) {
    const formattedDecimal = decimal.replace(/0+$/, "");
    if (formattedDecimal.length > 0) {
      return `${formattedWholeNumber}.${formattedDecimal.substring(0, precision)}`;
    }
  }

  return formattedWholeNumber;
};

const addCommasToThousandCount = (value: number | string): string | number => {
  const strValue = value.toString();
  const chars = strValue.split("");

  const reversedChars = chars.reverse();
  const formattedChars = [];

  for (let i = 0; i < reversedChars.length; i++) {
    if (i !== 0 && i % 3 === 0) {
      formattedChars.push(",");
    }
    formattedChars.push(reversedChars[i]);
  }

  const formattedValue = formattedChars.reverse().join("");

  return formattedValue;
};

export const ThePointsBalance: React.FC<IStyles & IThePointsBalance> = ({
  value,
  isFixed,
  precision = 2,
  ...props
}): React.ReactElement => {
  return (
    <>
      {value !== null && value !== undefined ? (
        isFixed ? (
          fixed("0,0", value)
        ) : (
          formatNumber(value)
        )
      ) : (
        <Label {...props}>API DOWN</Label>
      )}
    </>
  );
};

const Label = styled.div<IStyles>`
  font-size: 0.6rem;
  font-style: italic;
  text-wrap: nowrap;
  ${({ styles }) => styles}
`;
