import React from "react";
import styled, { css } from "styled-components";
import { IStyles } from "components";
import { Flex } from "components/common";



type LeaderboardPrizesHeaderProps = {
  setSelectedTab: (tab: string) => void;
  selectedTab: string;
};

const LeaderboardPrizesHeader: React.FC<LeaderboardPrizesHeaderProps> = ({
  setSelectedTab,
  selectedTab,
}) => {
  return (
    <Flex width="100%">
      <BoxItem>
        <Item
          onClick={(event: React.MouseEvent) => {
            event.preventDefault();
            setSelectedTab("tiered");
          }}
          isActive={selectedTab === "tiered"}
        >
          <Title>TIERED</Title>
        </Item>
      </BoxItem>
      <BoxItem>
        <Item
          onClick={(event: React.MouseEvent) => {
            event.preventDefault();
            setSelectedTab("random");
          }}
          isActive={selectedTab === "random"}
        >
          <Title>RANDOM (OPTIONAL)</Title>
        </Item>
      </BoxItem>
    </Flex>
  );
};

const Item = styled.div<{ isActive?: boolean }>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: rgba(210, 234, 255, 0.75);
  white-space: nowrap;
  transition: border-bottom 0.3s ease-in-out;

  &:hover {
    border-bottom: 1px #3b8be9 solid;
  }

  ${({ isActive }) =>
    isActive
      ? css`
          border-bottom: 1px #3b8be9 solid;
        `
      : null}
`;

export const BorderBottomDiv = styled.div`
  border-bottom: 1px solid #1d2431;
  margin: auto;
  width: 100%;
  height: 100%;
`;

export const ExtendedFlex = styled(Flex)`
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
`;

export const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  width: 100%;
  margin-right: 20px;
`;

const Title = styled.div<IStyles & { isMobile?: boolean }>`
  box-sizing: border-box;
  color: #d2eaff;
  font-family: "Roboto";
  font-size: 18px;
  margin-bottom: 5px;
  font-style: italic;
  font-weight: 800;
  letter-spacing: 0.02em;
  line-height: 21px;
  text-transform: uppercase;
  ${({ isMobile }) =>
    isMobile
      ? css`
          text-align: center;
          width: 100%;
        `
      : null}
`;

export default LeaderboardPrizesHeader;
