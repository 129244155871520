import React from "react";
import { $styles, $stylesReset, Button, ButtonBlue, Flex, IStyles } from "components/common";
import styled, { css } from "styled-components";
import Image from "next/image";
import { TheFieldset } from "components/TheComponents";

interface RecoveryKeyModalProps {
  onClose: () => void;
  onBack: () => void;
}

const RecoveryKeyModal: React.FC<RecoveryKeyModalProps> = ({
  onClose,
  onBack,
}): React.ReactElement => {
  const [payload, setPayload] = React.useState<string>("");

  const ButtonFlex = styled(Flex)`
    margin-top: 15px !important;
    margin: auto;
    @media (min-width: 600px) {
      width: 50%;
    }
  `;

  const numProps = { pattern: "^-?[0-9]*$", type: "text" };

  return (
    <Flex  justify="center" column>
      <Image src={"/images/HeaderComponent/logo.svg"} width={136} height={36} alt="logo" />
      <Title
        styles={css`
          text-align: center;
        `}
      >
        Recovery Key For MFA
      </Title>
      <Flex style={{ margin: "20px 0px" }} width="100%" align="center" column>
        <Text>
          Enter one of the recovery codes you saved while setting the Multi-Factor Authentication
          method.
        </Text>
        <BoxItem>
          <TheFieldset valid={!!payload.length} title="Recovery Code">
            <input
              {...numProps}
              placeholder="Enter Recovery Code"
              value={payload}
              onChange={(e) => setPayload(e.target.value)}
            />
          </TheFieldset>
        </BoxItem>
        <ButtonFlex>
          <Button
            styles={css`
              margin: auto;
            `}
            isDark
            onClick={onBack}
          >
            <ButtonBlue isDark>Back</ButtonBlue>
          </Button>
          <Button
            styles={css`
              margin: auto;
            `}
            onClick={onClose}
          >
            <ButtonBlue>Sign In</ButtonBlue>
          </Button>
        </ButtonFlex>
      </Flex>
    </Flex>
  );
};

export default RecoveryKeyModal;

const Title = styled.div<IStyles>`
  font-family: Roboto;
  font-size: 20px;
  font-style: italic;
  font-weight: 900;
  line-height: 23px;
  letter-spacing: 0.03em;
  text-align: left;
  color: rgba(210, 234, 255, 1);
  padding: 10px 0px;

  text-transform: uppercase;

  ${$styles}
`;
const Text = styled.div<IStyles>`
  ${$stylesReset}
  font-family: Roboto;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-bottom: 20px;
  ${$styles};
`;

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 20px;

  ${$styles}
`;
