import React from "react";

import { TheModalBox } from "components";
import {
  $styles,
  $stylesReset,
  Button,
  ButtonBlue,
  DualRingInner,
  DualRingLoader,
  Flex,
  IStyles,
} from "components/common";
import styled, { css } from "styled-components";
import Image from "next/image";
import QRCode from "react-qr-code";
import CodeInput from "./CodeInput";
import { useAppContext } from "contexts";
import { triggerNotification } from "components/TheComponents/Notification";
import RecoveryCodesModal from "./RecoveryCodesModal";
import AuthEnabledModal from "./AuthEnabledModal";

interface QRСodeModalProps {
  onClose: () => void;
}
interface QRData {
  secret: string;
  authUrl: string;
}

const QRСodeModal: React.FC<QRСodeModalProps> = ({ onClose }) => {
  const { accessFetch, setShowQrModal, refetchProfile: refetchUser } = useAppContext();

  const [payload, setPayload] = React.useState<string>("");
  const [data, setData] = React.useState<QRData | null>(null);
  const [showQr, setShowQr] = React.useState<boolean>(true);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [enableMFALoading, setEnableMFALoading] = React.useState<boolean>(false);

  const [recoveryCodes, setRecoveryCodes] = React.useState<string[]>([]);

  const [showRecoveryCodes, setShowRecoveryCodes] = React.useState<boolean>(false);
  const [showSuccess, setShowSuccess] = React.useState<boolean>(false);

  const [isValid, setIsValid] = React.useState<boolean>(true);

  const secret = data?.secret;

  const checkIsValid = React.useCallback(() => {
    if (payload.length === 6) {
      setIsValid(true);
      return true;
    }
    setIsValid(false);
    return false;
  }, [payload]);

  const enableMFA = React.useCallback(async () => {
    if (!checkIsValid()) {
      return;
    }
    try {
      setEnableMFALoading(true);
      const response = await accessFetch("/user/enable-2fa", {
        method: "POST",
        body: JSON.stringify({
          otpPassword: payload,
          mfaSecret: secret,
        }),
      });
      const mfaData = await response?.json();
      setRecoveryCodes(mfaData?.recoveryCodes);
      if (response && (response.status === 200 || response.status === 201)) {
        setShowQr(false);
        refetchUser();
        setShowRecoveryCodes(true);
      } else {
        triggerNotification({ text: mfaData?.message, type: "error" });
      }
      setEnableMFALoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [accessFetch, secret, payload, checkIsValid, refetchUser]);

  React.useEffect(() => {
    async function fetchData() {
      try {
        const response = await accessFetch("/user/generate-2fa");
        const fetchedData = await response?.json();

        setData(fetchedData);

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [accessFetch]);

  const modalBoxProps = {
    hasBlur: true,
    isFixed: true,
    hasBorder: true,
    showClose: true,
    onClose: () => onClose && onClose(),
  };

  const modalStyles = css`
    width: 325px;
    height: ${(showQr && "800px") || (showRecoveryCodes && "800px") || "400px"};
    display: block;

    @media (min-width: 600px) {
      width: 615px;
      height: ${(showQr && "590px") || (showRecoveryCodes && "520px") || "250px"};
    }
  `;

  const ButtonFlex = styled(Flex)`
    margin-top: 15px !important;
    margin: auto;
    @media (min-width: 600px) {
      width: 50%;
    }
  `;

  return (
    <TheModalBox styles={modalStyles} {...modalBoxProps}>
      {(isLoading && (
        <Flex align="center" justify="center" height="100%">
          <DualRingLoader>
            <DualRingInner />
          </DualRingLoader>
        </Flex>
      )) ||
        (showQr && data && (
          <Flex justify="center" column>
            <Image src={"/images/HeaderComponent/logo.svg"} width={170} height={32} alt="logo" />
            <Title
              styles={css`
                text-align: center;
              `}
            >
              Verify authentificator app
            </Title>
            <Flex style={{ margin: "20px 0px" }} width="100%" align="center" column>
              <Text>
                Scan the QR Code or enter the key into your authentificator app to retrieve a
                6-digit code, then enter it below.
              </Text>
              <Flex
                margin="20px 0px"
                align="center"
                height="100%"
                width="100%"
                justify="space-around"
              >
                <QRCode
                  bgColor="#d2eaff"
                  style={{ width: "150px", height: "150px" }}
                  value={data.authUrl || ""}
                />
                <Flex style={{ marginTop: 20 }} column>
                  <Text>Key: </Text>
                  <Text>{secret}</Text>
                </Flex>
              </Flex>
              <Text
                styles={css`
                  margin: 15px 0px;
                `}
              >
                Enter the 6-digit code displayed in your authentificator app:
              </Text>
              <CodeInput payload={payload} setPayload={setPayload} isValid={isValid} />
              <ButtonFlex>
                <Button
                  styles={css`
                    margin: auto;
                    width: 150px;
                    height: 44px;
                  `}
                  isLoading={enableMFALoading}
                  onClick={enableMFA}
                >
                  <ButtonBlue>
                    {enableMFALoading ? (
                      <DualRingLoader smallRing>
                        <DualRingInner smallRing />
                      </DualRingLoader>
                    ) : (
                      "Sign In"
                    )}
                  </ButtonBlue>
                </Button>
              </ButtonFlex>
            </Flex>
          </Flex>
        )) ||
        (showRecoveryCodes && recoveryCodes.length && (
          <RecoveryCodesModal
            recoveryCodes={recoveryCodes || []}
            onClose={() => {
              setShowRecoveryCodes(false);
              setShowSuccess(true);
            }}
          />
        )) ||
        (showSuccess && (
          <AuthEnabledModal
            onClose={() => {
              setShowSuccess(false);
              setShowQrModal(false);
            }}
          />
        ))}
    </TheModalBox>
  );
};

export default QRСodeModal;

const Title = styled.div<IStyles>`
  font-family: Roboto;
  font-size: 20px;
  font-style: italic;
  font-weight: 900;
  line-height: 23px;
  letter-spacing: 0.03em;
  text-align: left;
  color: rgba(210, 234, 255, 1);
  padding: 10px 0px;

  text-transform: uppercase;

  ${$styles}
`;
const Text = styled.div<IStyles>`
  ${$stylesReset}
  font-family: Roboto;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-bottom: 20px;
  ${$styles};
`;
