import React, { useEffect } from "react";
import { createContext, memo, PropsWithChildren, useState } from "react";
import { useMediaQuery } from "react-responsive";

export type IPageLayoutContext = {
  showNavSidebar: boolean;
  setShowNavSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  showChatSidebar: boolean;
  setShowChatSidebar: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PageLayoutInitialValues: IPageLayoutContext = {
  showNavSidebar: false,
  setShowNavSidebar: () => {},
  showChatSidebar: false,
  setShowChatSidebar: () => {},
};

const ShowPageLayoutContext = createContext<IPageLayoutContext>(PageLayoutInitialValues);

const ShowPageLayoutProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1400px)",
  });

  const [showNavSidebar, setShowNavSidebar] = useState<boolean>(false);
  const [showChatSidebar, setShowChatSidebar] = useState<boolean>(false);

  useEffect(() => {
    setShowNavSidebar(isDesktop);
  }, [isDesktop]);

  return (
    <ShowPageLayoutContext.Provider
      value={{
        showNavSidebar,
        setShowNavSidebar,
        showChatSidebar,
        setShowChatSidebar,
      }}
    >
      {children}
    </ShowPageLayoutContext.Provider>
  );
};

export function useShowPageLayoutContext() {
  const context = React.useContext(ShowPageLayoutContext);
  if (context === undefined) {
    throw new Error("usePageLayoutContext must be used within a ShowPageLayoutContext");
  }
  return context;
}

const ShowPageLayoutProviderMemo = memo(ShowPageLayoutProvider);

export default ShowPageLayoutProviderMemo;
