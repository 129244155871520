import React from "react";
import * as luxon from "luxon";
import numeral from "numeral";
import styled, { css } from "styled-components";
import {
  IStyles,
  ITheTableCell,
  TTheTableCellProps,
  TheIcon,
  TheTable,
} from "components";
import { IDtoLeaderboard } from "interfaces";
import Link from "next/link";
import { formatNumber } from "./utils/formatNumbers";

export type ILeaderboardTableItem = IDtoLeaderboard;

export type ILeaderboardTableOptions = {};

export interface ILeaderboardTableProps {
  $keys?: string[];
  leaderboards?: IDtoLeaderboard[];
  skip: number;
  onEdit?: (item: ILeaderboardTableItem) => void;
  onRemove?: (item: ILeaderboardTableItem) => void;
}

export const LeaderboardTable: React.FC<ILeaderboardTableProps> = ({
  $keys = ["#", "startDate", "durationDays", "endDate", "prize1", "prize2", "prize3", "action"],
  leaderboards,
  skip = 0,
  onEdit,
  onRemove,
}): React.ReactElement | null => {
  const setup = React.useMemo(
    () => [
      {
        $key: "#",
        render: (props: ITheTableCell<ILeaderboardTableItem, ILeaderboardTableOptions>) => {
          return <RowId>{skip + props.rowId + 1}</RowId>;
        },
        title: null,
      },
      {
        $key: "startDate",
        render: (props: ITheTableCell<ILeaderboardTableItem, ILeaderboardTableOptions>) => {
          const _: string | undefined = props.cell;
          return <Date>{_ ? luxon.DateTime.fromISO(_).toFormat("dd/MM/yyyy") : ""}</Date>;
        },
        title: <Title>Start date</Title>,
      },
      {
        $key: "durationDays",
        render: (props: ITheTableCell<ILeaderboardTableItem, ILeaderboardTableOptions>) => {
          const duration: number | undefined = props.cell;
          return (
            <Duration>
              {duration ? duration : "?"} {duration === 1 ? "day" : "days"}
            </Duration>
          );
        },
        title: <Title>Duration</Title>,
      },
      {
        $key: "endDate",
        render: (props: ITheTableCell<ILeaderboardTableItem, ILeaderboardTableOptions>) => {
          const _: string | undefined = props.cell;
          return <Date>{_ ? luxon.DateTime.fromISO(_).toFormat("dd/MM/yyyy") : ""}</Date>;
        },
        title: <Title>End date</Title>,
      },
      {
        $key: "prize1",
        render: (props: ITheTableCell<ILeaderboardTableItem, ILeaderboardTableOptions>) => {
          const _: number | undefined = props.cell;
          return <Prize data-value>{formatNumber(_ ? +_ : 0)}</Prize>;
        },
        title: (
          <Title>
            <Prize>Prize 1</Prize>
          </Title>
        ),
      },
      {
        $key: "prize2",
        render: (props: ITheTableCell<ILeaderboardTableItem, ILeaderboardTableOptions>) => {
          const _: number | undefined = props.cell;
          return <Prize data-value>{formatNumber(_ ? +_ : 0)}</Prize>;
        },
        title: (
          <Title>
            <Prize>Prize 2</Prize>
          </Title>
        ),
      },
      {
        $key: "prize3",
        render: (props: ITheTableCell<ILeaderboardTableItem, ILeaderboardTableOptions>) => {
          const _: number | undefined = props.cell;
          return <Prize data-value>{formatNumber(_ ? +_ : 0)}</Prize>;
        },
        title: (
          <Title>
            <Prize>Prize 3</Prize>
          </Title>
        ),
      },
      {
        $key: "action",
        render: (props: ITheTableCell<ILeaderboardTableItem, ILeaderboardTableOptions>) => {
          const onClickEdit = (event: React.MouseEvent) => {
            event.preventDefault();
            onEdit && props?.row && onEdit(props.row);
          };
          const onClickRemove = (event: React.MouseEvent) => {
            event.preventDefault();
            onRemove && props?.row && onRemove(props.row);
          };
          return (
            <>
              <Link href="#" onClick={onClickEdit}>
                <TheIcon icon="action:edit" size={16} />
              </Link>
              &nbsp;
              <Link href="#" onClick={onClickRemove}>
                <TheIcon icon="action:remove" size={16} />
              </Link>
            </>
          );
        },
        title: null,
      },
    ],
    [skip, onEdit, onRemove]
  );

  const cellProps: TTheTableCellProps<ILeaderboardTableItem, ILeaderboardTableOptions> = ({
    isHead,
    $key,
  }) => {
    if (isHead) {
      if (["#", "action"].includes($key)) {
        return { styles: cssCell };
      }
    } else if (["prize1", "prize2", "prize3"].includes($key)) {
      return { styles: cssCellPrize };
    }
    return {};
  };

  return (
    <Box>
      <TheTable<ILeaderboardTableItem, ILeaderboardTableOptions>
        $keys={$keys}
        $setup={setup}
        rows={leaderboards ?? []}
        CellProps={cellProps}
      />
    </Box>
  );
};



const cssCell = css`
  width: 1px;
`;

const cssCellPrize = css`
  width: 128px;
  text-align: right !important;
`;

const Box = styled.div<IStyles>`
  box-sizing: border-box;
  text-align: left;

  ${({ styles }) => styles}
`;

const RowId = styled.div<IStyles>`
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 8px;
  background: rgba(35, 163, 255, 0.05);
  border: 2px solid #0b101b;
  border-radius: 41px;
  ${({ styles }) => styles}
`;

const Title = styled.div<IStyles>`
  color: rgba(210, 234, 255, 0.75);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: left;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  ${({ styles }) => styles}
`;

const Date = styled.div<IStyles>`
  color: #d2eaff;
  font-family: "Rubik";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  ${({ styles }) => styles}
`;

const Duration = styled.div<IStyles>`
  color: #d2eaff;
  font-family: "Rubik";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  ${({ styles }) => styles}
`;

const Prize = styled.div<IStyles>`
  font-family: "Rubik";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  text-align: right;
  &[data-value] {
    color: #5ebd3d;
  }
  &[data-value]::before {
    content: "$";
  }
  ${({ styles }) => styles}
`;
