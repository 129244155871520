import React from "react";
import styled, { css } from "styled-components";
import { TheButton, TheCheckbox, TheIcon, IStyles, $styles } from "components";
import Link from "next/link";
import { useRouter } from "next/router";
import { useLocalStorage } from "usehooks-ts";

const Box = styled.div<IStyles>`
  box-sizing: border-box;
  max-width: 586px;
  z-index: 99;

  // text
  color: rgba(210, 234, 255, 0.75);
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: center;

  ${$styles}
`;

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${$styles}
`;

const BoxForm = styled.form<IStyles>`
  box-sizing: border-box;
`;

const Label = styled.label<IStyles>`
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;

  ${$styles}
`;

const Logo = styled.div<IStyles>`
  --width: 170px;
  --height: 32px;
  box-sizing: border-box;
  width: var(--width);
  height: var(--height);
  background-clip: border-box;
  background-image: url("/images/TwitchLoginFrameComponent/WRewards.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: var(--width) var(--height);

  ${$styles}
`;

const Title = styled.div<IStyles>`
  color: #d2eaff;
  font-family: "Roboto";
  font-size: 20px;
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.03em;
  line-height: 23px;
  text-transform: uppercase;

  ${$styles}
`;

const Hr = styled.div<IStyles>`
  box-sizing: border-box;
  width: 100%;
  height: 1px;
  background: rgba(210, 234, 255, 0.05);

  ${$styles}
`;

export interface ITwitchLoginLayout {
  onLogin: () => void;
  onClose: (action: string) => void;
  withoutHeader?: boolean;
}

export const TwitchLoginLayout: React.FC<ITwitchLoginLayout> = ({
  onLogin,
  withoutHeader,
}): React.ReactElement => {
  const [checked, setChecked] = React.useState<boolean>(true);
  const router = useRouter();
  const [_, setRedirectFrom] = useLocalStorage("redirect-from-path", "/", {
    initializeWithValue: true,
  });
  const onLabelClick = React.useMemo(() => {
    return (checked: boolean, setChecked: Function) => (event: React.MouseEvent) => {
      event.preventDefault();
      const targetElement = event.target as Element;
      if (!targetElement.closest("a")) {
        setChecked(!checked);
      }
    };
  }, []);

  const onCheckboxChange = React.useMemo(() => {
    return () => (event: React.ChangeEvent) => {
      event.preventDefault();
    };
  }, []);

  const onSubmit = React.useMemo(() => {
    return (event: React.MouseEvent) => {
      event.preventDefault();
      if (checked && onLogin) {
        let redirectFrom = router.pathname;
        const dynamicRouteParamName = router.pathname
          .match(/\[.*?\]/g)
          ?.map((param) => param.replace(/\[|\]/g, ""));

        if (dynamicRouteParamName) {
          const params = dynamicRouteParamName?.map<string>(
            (param) => router.query[param] as string
          );
          if (params) {
            let replacementIndex = 0;
            redirectFrom = redirectFrom.replace(/\[.*?\]/g, (match) => {
              // Если есть заменяющее слово, используем его, иначе оставляем исходное
              const replacement = params[replacementIndex] || match;
              replacementIndex++;
              return replacement;
            });
          }
          const containsAnyDymaicParams = redirectFrom.match(/\[.*?\]/g);
          if (containsAnyDymaicParams) redirectFrom = redirectFrom.replace(/\[.*?\]/g, "");
        }

        setRedirectFrom(redirectFrom);
        onLogin();
      }
    };
  }, [onLogin, checked, setRedirectFrom, router]);
  return (
    <Box>
      <BoxForm>
        {withoutHeader === false ? null : (
          <>
            <BoxItem
              styles={css`
                margin-top: 16px;
              `}
            >
              <Logo />
            </BoxItem>
            <BoxItem
              styles={css`
                margin: 40px 0;
              `}
            >
              <Hr />
            </BoxItem>
          </>
        )}
        <BoxItem
          styles={css`
            margin: 0;
          `}
        >
          <Title>Welcome!</Title>
        </BoxItem>
        <BoxItem
          styles={css`
            margin-top: 16px;
            padding: 0 9px;
          `}
        >
          Make sure you have a Twitch account so you can login, join the Wrewards community and
          discover all sorts of treats and perks!
        </BoxItem>
        <BoxItem
          styles={css`
            margin: 34px 0 40px;
          `}
        >
          <Hr />
        </BoxItem>
        <BoxItem
          styles={css`
            margin: 0;
          `}
        >
          <Label
            onClick={onLabelClick(checked, setChecked)}
            styles={css`
              margin: 0;
              justify-content: flex-start;
              align-items: center;
              gap: 22px;
            `}
          >
            <TheCheckbox checked={checked} preset="violet" onChange={onCheckboxChange()} />
            <BoxItem
              styles={css`
                max-width: 440px;
                justify-content: flex-start;
                text-align: left;
                display: inline-box;
              `}
            >
              I confirm I&lsquo;m over 18 years old and that I have read and agree to the{" "}
              <Link href="/terms-of-service">Terms and Conditions</Link>.
            </BoxItem>
          </Label>
        </BoxItem>
        <BoxItem
          styles={css`
            margin-top: 40px;
          `}
        >
          <TheButton preset="violet" width={"100%"} onClick={onSubmit}>
            <TheIcon icon="twitch:small:violet" size={16} />
            Log in via Twitch
          </TheButton>
        </BoxItem>
      </BoxForm>
    </Box>
  );
};
