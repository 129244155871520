import Image from "next/image";
import React from "react";
import styled, { css } from "styled-components";
import { IStyles } from "components";
import { useAppContext } from "contexts";
import { IDtoLeaderboard } from "interfaces";
import { Button, ButtonBlue } from "components/common";
import { triggerNotification } from "components/TheComponents/Notification";
import VerifierProvider, { useVerifierContext } from "contexts/VerifierProvider";

export interface ILeaderboardRemoveFormProps {
  leaderboard: IDtoLeaderboard;
  onRemove: (leaderboard: IDtoLeaderboard) => void;
  onClose: () => void;
}

const LeaderboardRemoveForm: React.FC<ILeaderboardRemoveFormProps> = ({
  leaderboard,
  onRemove,
  onClose,
}): React.ReactElement => {
  const { accessFetch, accessURL } = useAppContext();
  const { verify } = useVerifierContext();

  const [hasError, setHasError] = React.useState<string | null>(null);

  const doSave = async ({ otp }: { otp: string }) => {
    try {
      const leaderboardId = leaderboard.id;
      const url = accessURL(`/leaderboard/${leaderboardId}`) as URL;
      const response = await accessFetch(url.href, {
        method: "DELETE",
        headers: {
          "x-otp": otp,
        },
      });
      const json = await response?.json();

      if (response && (response.status === 200 || response.status === 201)) {
        triggerNotification({ text: "Leaderboard Updated!", type: "success" });
        onRemove && onRemove(leaderboard);
      } else {
        setHasError(json.message);
        triggerNotification({ text: json.message, type: "error" });
      }
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <Box>
      <BoxItem
        styles={css`
          margin-bottom: 16px;
        `}
      >
        <Text1>
          <Image src="/images/logos/logo.svg" width={170} height={32} alt="Logo" />
        </Text1>
      </BoxItem>
      <BoxItem>
        <Text1>Are you sure you want to delete this leaderboard ?</Text1>
      </BoxItem>
      <BoxItem>
        <Text2>
          You`re about to delete this leaderboard permanently. This action cannot be undone. Are you
          absolutely sure you want to proceed?
        </Text2>
      </BoxItem>
      <BoxItem>{hasError ? <Warn>{hasError}</Warn> : null}</BoxItem>
      <BoxItem>
        <Actions>
          <Button isDark onClick={onClose} height={44}>
            <ButtonBlue isDark style={{ whiteSpace: "nowrap" }} transparent>
              Cancel
            </ButtonBlue>
          </Button>
          <Button height={44}>
            <ButtonBlue
              onClick={(e) => {
                e.preventDefault();
                verify(({ otp }) => doSave({ otp }));
              }}
              style={{ whiteSpace: "nowrap" }}
              transparent
            >
              Delete
            </ButtonBlue>
          </Button>
        </Actions>
      </BoxItem>
    </Box>
  );
};

export const LeaderboardRemoveFormLayout: React.FC<ILeaderboardRemoveFormProps> = (props) => {
  return (
    <VerifierProvider>
      <LeaderboardRemoveForm {...props} />
    </VerifierProvider>
  );
};

// export const LeaderboardRemoveFormLayout = withApp(LeaderboardRemoveForm);

const Text1 = styled.div`
  font-family: "Roboto";
  font-style: italic;
  font-weight: 900;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #d2eaff;
  text-align: center;
`;

const Text2 = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.02em;
  color: rgba(210, 234, 255, 0.75);
  text-align: center;
`;

const Actions = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const Box = styled.div<IStyles>`
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  max-width: 543px;

  ${({ styles }) => styles}
`;

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  /* display: flex; */
  /* justify-content: flex-start; */
  /* align-items: center; */
  width: 100%;
  /* margin: 0 0 16px; */

  ${({ styles }) => styles}
`;

const Warn = styled.div<IStyles>`
  box-sizing: border-box;
  color: red;
  font-weight: bold;

  ${({ styles }) => styles}
`;
