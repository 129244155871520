import React from "react";
import styled, { css } from "styled-components";
import {
  IStyles,
  ITheButton,
  ITheTableCell,
  TTheTableCellProps,
  TTheTableRowProps,
  TheButton,
  TheTable,
} from "components";
import { IDtoLeaderboard, IDtoLeaderboardUser, TheLeaderboardSourceKeys } from "interfaces";
import { PRIZES_OUTSIDE_ADDITIONAL_PRIZES_COUNT } from "helpers/constants";
import { Flex } from "components/common";
import Image from "next/image";
import { formatNumber } from "./utils/formatNumbers";

export type ILeaderboardUserTableItem = IDtoLeaderboardUser & {
  prize: number;
  prizeColor: string;
  prizeId?: string;
};

export type ILeaderboardUserTableOptions = {};

export interface ILeaderboardUsersTableProps {
  $keys?: string[];
  leaderboard?: IDtoLeaderboard | null;
  leaderboardUsers: IDtoLeaderboardUser[];
  usersSkip?: number;
  usersTake?: number;
  selectedTab?: string;
  onBan?: (item: ILeaderboardUserTableItem, rowId: number) => void;
  onRepickAttempt?: ({ prizeId }: { prizeId: string }) => void;
  onUnban?: (item: ILeaderboardUserTableItem, rowId: number) => void;
  isSingleUser?: boolean;
}

export const LeaderboardUsersTable: React.FC<ILeaderboardUsersTableProps> = ({
  $keys = [
    "#",
    "refereeId",
    "displayName",
    "value",
    "prize",
    "randomPrize",
    "bann",
    "repick",
    "prizeId",
  ],
  leaderboard,
  leaderboardUsers,
  usersSkip = 0,
  selectedTab,
  onBan,
  onUnban,
  onRepickAttempt,
  isSingleUser,
}): React.ReactElement | null => {
  const setup = React.useMemo(
    () => [
      {
        $key: "#",
        render: (props: ITheTableCell<ILeaderboardUserTableItem, ILeaderboardUserTableOptions>) => {
          return <RowId>{props.row?.position || usersSkip + props.rowId + 1}</RowId>;
        },
        title: null,
      },
      {
        $key: "refereeId",
        render: (props: ITheTableCell<ILeaderboardUserTableItem, ILeaderboardUserTableOptions>) => {
          const refereeId: string | null = props.cell;
          return (
            <Title style={{ color: "#d2eaff" }}>
              {refereeId === "-1" ? "HIDDEN" : refereeId || "TBD"}
            </Title>
          );
        },
        title: <Title>ID</Title>,
      },
      {
        $key: "displayName",
        render: (props: ITheTableCell<ILeaderboardUserTableItem, ILeaderboardUserTableOptions>) => {
          const _: string | undefined = props.cell;
          return <User>{_ ? _ : "TBD"}</User>;
        },
        title: <Title>User</Title>,
      },
      {
        $key: "value",
        render: (props: ITheTableCell<ILeaderboardUserTableItem, ILeaderboardUserTableOptions>) => {
          const casinoProvider: string | undefined = leaderboard?.casinoProvider;

          switch (casinoProvider) {
            case TheLeaderboardSourceKeys.gamdom:
            case TheLeaderboardSourceKeys.packdraw:
            case TheLeaderboardSourceKeys.clash:
            case TheLeaderboardSourceKeys.cases:
              return (
                <Value>
                  <span>$</span>
                  {formatNumber(props.row?.wageredTotal ? +props.row.wageredTotal : 0)}
                </Value>
              );
            case TheLeaderboardSourceKeys.hypedrop:
              return (
                <Value>
                  <span>$</span>
                  {formatNumber(props.row?.deposited ? +props.row?.deposited : 0)}
                </Value>
              );
            case TheLeaderboardSourceKeys.wrewards:
              return (
                <Value>
                  <Image src="/images/coins/g-coin.svg" width={18} height={18} alt="coin" />
                  {formatNumber(props.row?.wageredTotal ? +props.row.wageredTotal : 0)}
                </Value>
              );

            default:
              return null;
          }
        },
        title: () => {
          const casinoProvider: string | undefined = leaderboard?.casinoProvider;

          switch (casinoProvider) {
            case TheLeaderboardSourceKeys.gamdom:
            case TheLeaderboardSourceKeys.packdraw:
            case TheLeaderboardSourceKeys.clash:
            case TheLeaderboardSourceKeys.cases:
              return (
                <Title
                  styles={css`
                    text-align: center;
                  `}
                >
                  Wagered
                </Title>
              );
            case TheLeaderboardSourceKeys.hypedrop:
              return (
                <Title
                  styles={css`
                    text-align: center;
                  `}
                >
                  Deposited
                </Title>
              );
            case TheLeaderboardSourceKeys.wrewards:
              return (
                <Title
                  styles={css`
                    text-align: center;
                  `}
                >
                  Points
                </Title>
              );

            default:
              return null;
          }
        },
      },
      {
        $key: "prize",
        render: (props: ITheTableCell<ILeaderboardUserTableItem, ILeaderboardUserTableOptions>) => {
          const row: ILeaderboardUserTableItem | null = props.row;
          return (
            <Prize style={row?.prizeColor ? { color: row.prizeColor } : {}}>
              {formatNumber(row?.prize ? +row.prize : 0)}
            </Prize>
          );
        },
        title: (
          <Title
            styles={css`
              text-align: right;
            `}
          >
            Prize
          </Title>
        ),
      },
      {
        $key: "bann",
        render: (props: ITheTableCell<ILeaderboardUserTableItem, ILeaderboardUserTableOptions>) => {
          const banned: boolean | undefined = props.row?.banned;
          const propsButton: IStyles & ITheButton = {
            preset: "dark-blue",
            width: null,
            height: null,
            styles: css`
              padding: 9px 18px;
            `,
          };
          if (banned === true) {
            const onClick = (event: React.MouseEvent) => {
              event.preventDefault();
              if (onUnban && props?.row) {
                onUnban(props.row, props.rowId);
              }
            };
            return (
              <TheButton {...propsButton} onClick={onClick}>
                Unban
              </TheButton>
            );
          } else if (banned === false) {
            const onClick = (event: React.MouseEvent) => {
              event.preventDefault();
              if (onBan && props?.row) {
                onBan(props.row, props.rowId);
              }
            };
            return (
              <TheButton {...propsButton} onClick={onClick}>
                Ban
              </TheButton>
            );
          }
          return null;
        },
        title: null,
      },
      {
        $key: "delete",
        render: () => {
          const propsButton: IStyles & ITheButton = {
            preset: "dark-blue",
            width: null,
            height: null,
            styles: css`
              padding: 9px 18px;
            `,
          };
          return <TheButton {...propsButton}>Delete</TheButton>;
        },
        title: null,
      },
      ...(selectedTab === "random"
        ? [
            {
              $key: "repick",
              render: (
                props: ITheTableCell<ILeaderboardUserTableItem, ILeaderboardUserTableOptions>
              ) => {
                const propsButton: IStyles & ITheButton = {
                  preset: "dark-blue",
                  width: 150,
                  height: 30,
                  styles: css`
                    padding: 9px 18px;
                  `,
                };
                const isRowHasDisplayName: boolean = !!props.row?.displayName;
                const prizeId: string | undefined = props.row?.prizeId;

                return isRowHasDisplayName ? (
                  <Flex width="100%" justify="flex-end">
                    <TheButton
                      onClick={(e) => {
                        e.preventDefault();
                        if (onRepickAttempt && prizeId) {
                          onRepickAttempt({ prizeId });
                        }
                      }}
                      {...propsButton}
                    >
                      Repick
                    </TheButton>
                  </Flex>
                ) : null;
              },
              title: null,
            },
          ]
        : []),
    ],
    [usersSkip, leaderboard, onBan, onUnban, selectedTab, onRepickAttempt]
  );

  const rows = leaderboardUsers?.length
    ? leaderboardUsers.map((user: IDtoLeaderboardUser, index: number) => {
        const additionalPrizes = leaderboard?.additionalPrizes || [];
        const userIndex = user.position ? user.position - 1 : usersSkip + index;

        let prize = 0;
        let prizeColor = "#5ebd3d";

        if (userIndex === 0) {
          prize = leaderboard?.prize1 || 0;
        } else if (userIndex === 1) {
          prize = leaderboard?.prize2 || 0;
        } else if (userIndex === 2) {
          prize = leaderboard?.prize3 || 0;
        } else if (userIndex < additionalPrizes.length + PRIZES_OUTSIDE_ADDITIONAL_PRIZES_COUNT) {
          prize = additionalPrizes[userIndex - 3].amount || 0;
        }

        if (user.randomPrize) {
          prize = +user.randomPrize;
          prizeColor = "#3984dd";
        }

        return {
          ...user,
          prize,
          prizeColor,
        };
      })
    : [];

  const cellProps: TTheTableCellProps<ILeaderboardUserTableItem, ILeaderboardUserTableOptions> = ({
    isHead,
    isBody,
    $key,
    row,
  }) => {
    if (isHead) {
      return ["#", "bann"].includes($key) ? { styles: cssCell } : {};
    } else if (isBody) {
      return row?.banned ? { styles: cssBannedCell } : {};
    }
    return {};
  };

  const rowProps: TTheTableRowProps<ILeaderboardUserTableItem, ILeaderboardUserTableOptions> = ({
    isBody,
    row,
  }) => {
    if (isBody) {
      return row?.banned ? { styles: cssBannedRow } : {};
    }
    return {};
  };

  return (
    <Box isHiddenHead={!!isSingleUser}>
      {isSingleUser && (
        <Title
          styles={css`
            position: absolute;
            top: 1rem;
            left: 50%;
            transform: translateX(-50%);
          `}
        >
          Your Position
        </Title>
      )}
      <TheTable<ILeaderboardUserTableItem, ILeaderboardUserTableOptions>
        $keys={$keys}
        $setup={setup}
        rows={rows}
        CellProps={cellProps}
        RowProps={rowProps}
      />
    </Box>
  );
};

//  TO DO LOADING RING

const cssCell = css`
  width: 1px;
`;

const cssBannedCell = css`
  border-top: 1px solid red;
  border-bottom: 1px solid red;
  &:first-child {
    border-left: 1px solid red;
  }
  &:last-child {
    border-right: 1px solid red;
  }
`;

const cssBannedRow = css``;

const Box = styled.div<IStyles & { isHiddenHead: boolean }>`
  box-sizing: border-box;
  text-align: left;
  position: relative;

  thead {
    visibility: ${({ isHiddenHead }) => (isHiddenHead ? "hidden" : "visible")};
  }

  thead td {
    text-transform: uppercase;
    padding: 0.25rem 1.5rem;
  }

  td {
    padding: 0.75rem 0.75rem;
  }

  tr td:not(:first-child, :last-child) {
    padding: 0.75rem 0.25rem;
  }

  @media (min-width: 800px) {
    tr td:nth-child(2) {
      min-width: 300px;
    }
    tr td:nth-child(4) {
      min-width: 200px;
    }
  }
  @media (min-width: 1024px) {
    td {
      padding: 0.75rem 1.5rem;
    }
  }

  ${({ styles }) => styles}
`;

const RowId = styled.div<IStyles>`
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 8px;

  background: rgba(35, 163, 255, 0.05);
  border: 2px solid #0b101b;
  border-radius: 41px;

  ${({ styles }) => styles}
`;

const Title = styled.div<IStyles>`
  color: rgba(210, 234, 255, 0.75);
  font-family: "Golos Text";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: left;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  text-transform: uppercase;

  ${({ styles }) => styles}
`;

const User = styled.div<IStyles>`
  color: #d2eaff;
  font-family: "Golos Text";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 21px;
  text-transform: uppercase;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ styles }) => styles}
`;

const Value = styled.div<IStyles>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: #d2eaff;
  font-family: "Rubik";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  text-align: center;

  span {
    font-size: 16px;
    color: #5ebd3d;
  }

  ${({ styles }) => styles}
`;

const Prize = styled.div<IStyles & { isRandom?: boolean }>`
  /* display: flex;
  align-items: center;
  justify-content: flex-end; */

  color: #5ebd3d;
  font-family: "Rubik";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  text-align: right;

  &::before {
    content: "$";
  }

  ${({ styles }) => styles}
`;
