import Crown from "./components/icons/Crown";
import Gift from "./components/icons/Gift";
import Others from "./components/icons/Others";
import Support from "./components/icons/Support";
import Contact from "./components/icons/Contact";
import { ReactNode } from "react";

export const menuItems: ITopMenuItem[] = [
  {
    name: "Admin",
    href: "/admin",
    type: "admin",
    isDesktopOnly: false,
  },
  {
    name: "All bonuses",
    href: "/all-bonuses",
    image: <Gift />,
  },
  {
    name: "Leaderboards",
    href: "/leaderboards",
    image: <Crown />,
  },
  {
    name: "All Rewards",
    image: <Gift />,
    items: [
      {
        name: "All Bonuses",
        href: "/all-bonuses",
      },
      {
        name: "Raffles",
        href: "/raffles",
      },
      {
        name: "Points Shop",
        href: "/points-shop",
      },
      {
        name: "Calendar",
        href: "/advent-calendar",
      },
      {
        name: "Leaderboards",
        href: "/leaderboards",
      },
      {
        name: "Pachinko",
        href: "/pachinko",
      },
    ],
  },
  {
    name: "Others",
    image: <Others />,
    items: [
      {
        name: "Hunt Tracker",
        href: "/hunt-tracker",
      },
    ],
  },
];

export const addMenuItems: ITopMenuItem[] = [
  {
    name: "Support",
    href: "/faq",
    image: <Support />,
  },
  {
    name: "Contact",
    href: "/contact-us",
    image: <Contact />,
  },
];

export const metaMenuItems: ITopMenuItem[] = [
  {
    name: "Privacy Policy",
    href: "/privacy-policy",
  },
  {
    name: "Terms of Service",
    href: "/terms-of-service",
  },
];

export interface ITopMenuItem {
  name: string;
  href?: string;
  type?: string;
  image?: ReactNode;
  isMobileOnly?: boolean;
  isDesktopOnly?: boolean;
  items?: ITopMenuItem[];
}