import React from "react";
import styled from "styled-components";
import { TwitchEmbed } from "react-twitch-embed";
import Image from "next/image";
import TheLiveStreamPictures from "./TheLiveStreamPictures";
import { Flex } from "components/common";
import { FlexProps } from "components/common/types";
import Link from "next/link";
import { IAppContext, withApp } from "contexts";
import {
  HyusLinks,
  HYUS_USERNAME,
  WatchGamesTVLinks,
  WATCH_GAMES_USERNAME,
  STREAMING_PLATFORMS,
  WREWARDS_USERNAME,
  WRewardsLinks,
  PKLE_USERNAME,
  PkleLinks,
} from "helpers/constants";
import { Stream } from "helpers/types";
import { KickButton } from "components/pachinko/Main";
import { TheIcon, TheIconLink } from "components/TheComponents";

export const TwitchButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 16px 50px;
  margin: 50px 0px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background-color: #683dc3;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #6f29b6;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(145, 70, 255, 0.5);
  }

  &:active {
    background-color: #551fa8;
  }
`;

const Ellipse = styled.div`
  position: absolute;
  width: 639px;
  height: 214px;
  left: 25%;
  top: 348px;

  background: linear-gradient(
    270.01deg,
    rgba(219, 0, 255, 0.3) 2.53%,
    rgba(0, 178, 255, 0.3) 99.99%
  );
  filter: blur(150px);
`;

const SocialsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 50%;
`;
const Socials = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 16px 0 40px;

  @media (max-width: 768px) {
    gap: 15px;
  }
`;

const AdviceText = styled.h1`
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: rgba(210, 234, 255, 0.75);
`;

const ExpandedFlex = styled(Flex)<FlexProps>`
  overflow-x: clip;
  border-radius: 12px;
  @media (max-width: 1280px) {
    width: 100%;
  }
`;

const TheLiveStream: React.FC<IAppContext> = ({ liveStreams }): JSX.Element => {
  const watchgamestvLiveStream = liveStreams?.find(
    (el: Stream) => el.isLive === true && el.streamer === WATCH_GAMES_USERNAME
  );

  const hyusLiveStream = liveStreams?.find(
    (el: Stream) => el.isLive === true && el.streamer === HYUS_USERNAME
  );

  const wrewardsLiveStream = liveStreams?.find(
    (el: Stream) => el.isLive === true && el.streamer === WREWARDS_USERNAME
  );

  const pkleLiveStream = liveStreams?.find(
    (el: Stream) => el.isLive === true && el.streamer === PKLE_USERNAME
  );

  const liveStreamToShow =
    watchgamestvLiveStream || wrewardsLiveStream || hyusLiveStream || pkleLiveStream;
  const linkToLiveStream =
    (watchgamestvLiveStream && WatchGamesTVLinks[watchgamestvLiveStream.platform]) ||
    (wrewardsLiveStream && WRewardsLinks[wrewardsLiveStream.platform]) ||
    (hyusLiveStream && HyusLinks[hyusLiveStream.platform]) ||
    (pkleLiveStream && PkleLinks[pkleLiveStream.platform]) ||
    WatchGamesTVLinks.kick;

  return (
    <Flex
      width="100%"
      background="linear-gradient(180deg, #0C1121 0%, rgba(12, 17, 33, 0) 104.13%), url(/images/HuntTrackerComponent/low-opacity-bg.png);"
      justify="center"
      column
      padding="40px 0px 0px 0px"
    >
      <Ellipse />
      <TheLiveStreamPictures platform={liveStreamToShow?.platform || STREAMING_PLATFORMS.KICK} />
      <Flex style={{ marginTop: 50 }} width="100%" justify="center" align="center" column>
        <Flex align="center" column>
          <Image alt="guy" width={120} height={113} src="/images/HuntTrackerComponent/guy.png" />
          <Flex align="center">
            <Image alt="play" width={56} height={58} src="/images/HuntTrackerComponent/play.svg" />
            <h1 style={{ textAlign: "center", marginLeft: "8px" }}>
              <span style={{ color: "#3B8BE9" }}>LIVE</span> STREAM
            </h1>
          </Flex>
          <AdviceText>Enjoy the WGTV Live Content!</AdviceText>
        </Flex>
      </Flex>
      <ExpandedFlex width="55%" justify="center" style={{ margin: "auto" }}>
        {(liveStreamToShow?.platform === STREAMING_PLATFORMS.TWITCH && (
          <TwitchEmbed
            channel={liveStreamToShow?.streamer}
            id="twitch-embed"
            width="100%"
            withChat={false}
          />
        )) ||
          (liveStreamToShow?.platform === STREAMING_PLATFORMS.KICK && (
            <iframe
              src={`https://player.kick.com/${liveStreamToShow?.streamer}`}
              style={{ width: "100%", aspectRatio: "16/9", borderRadius: 12 }}
              frameBorder="0"
              scrolling="no"
              allowFullScreen={true}
            ></iframe>
          )) || (
            <Flex
              height={500}
              width="100%"
              background="#0B0E16"
              column
              justify="center"
              align="center"
              style={{ borderRadius: 12 }}
            >
              <Flex height="50%" justify="flex-end" align="center" column>
                <h1
                  style={{
                    fontFamily: "Roboto",
                    fontStyle: "italic",
                    fontWeight: 900,
                    fontSize: "30px",
                    lineHeight: "35px",
                    letterSpacing: "0.03em",
                    textTransform: "uppercase",
                    color: "#8AA7E1",
                  }}
                >
                  CURRENTLY OFFLINE
                </h1>
                <AdviceText
                  style={{
                    textAlign: "center",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "150%",
                    letterSpacing: "0.02em",
                    color: "rgba(210, 234, 255, 0.75)",
                  }}
                >
                  Press the button below to visit Kick Channel
                </AdviceText>
              </Flex>
              <SocialsWrapper>
                <AdviceText
                  style={{
                    textAlign: "center",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "150%",
                    letterSpacing: "0.02em",
                    color: "rgba(210, 234, 255, 0.75)",
                  }}
                >
                  Join my socials to find out when the next stream is
                </AdviceText>
                <Socials>
                  <TheIconLink target="_blank" href={WatchGamesTVLinks.discord}>
                    <TheIcon icon="discord:social" />
                  </TheIconLink>
                  <TheIconLink target="_blank" href={WatchGamesTVLinks.youtube}>
                    <TheIcon icon="youtube:social" />
                  </TheIconLink>
                  <TheIconLink target="_blank" href={WatchGamesTVLinks.twitter}>
                    <TheIcon icon="twitter:social" />
                  </TheIconLink>
                  <TheIconLink
                    data-active={
                      watchgamestvLiveStream ? watchgamestvLiveStream.platform === "kick" : false
                    }
                    target="_blank"
                    href={WatchGamesTVLinks.kick}
                  >
                    <TheIcon icon="kick:social" />
                  </TheIconLink>
                  <TheIconLink
                    target="_blank"
                    data-active={
                      watchgamestvLiveStream ? watchgamestvLiveStream.platform === "twitch" : false
                    }
                    href={WatchGamesTVLinks.twitch}
                  >
                    <TheIcon icon="twitch:social" />
                  </TheIconLink>
                  <TheIconLink target="_blank" href={WatchGamesTVLinks.instagram}>
                    <TheIcon icon="instagram:social" />
                  </TheIconLink>
                </Socials>
              </SocialsWrapper>
            </Flex>
          )}
      </ExpandedFlex>
      <Flex width="100%" justify="center">
        {liveStreamToShow?.platform === STREAMING_PLATFORMS.TWITCH ? (
          <Link href={linkToLiveStream}>
            <TwitchButton>
              <Image
                width={18}
                height={18}
                style={{ marginRight: 3 }}
                alt="Twitch"
                src="/images/logos/twitch-logo.png"
              ></Image>
              Follow{" "}
              {(liveStreamToShow.streamer === HYUS_USERNAME && "HYUSLIVE") ||
                (liveStreamToShow.streamer === PKLE_USERNAME && "PKLE") ||
                "WGTV"}{" "}
              Twitch
            </TwitchButton>
          </Link>
        ) : (
          <Link target="_blank" href={linkToLiveStream}>
            <KickButton>
              <Image
                width={18}
                height={18}
                style={{ marginRight: 15 }}
                alt="Twitch"
                src="/images/PachinkoComponent/kick.svg"
              />
              Follow{" "}
              {(liveStreamToShow?.streamer === HYUS_USERNAME && "HYUSLIVE") ||
                (liveStreamToShow?.streamer === PKLE_USERNAME && "PKLE") ||
                "WGTV"}{" "}
              Kick
            </KickButton>
          </Link>
        )}
      </Flex>
    </Flex>
  );
};

export default withApp(TheLiveStream);
