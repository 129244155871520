import Image from "next/image";
import React from "react";
import styled, { css } from "styled-components";
import { $stylesReset, IStyles, TheIcon } from "components";
import { SlidingVideos } from "./SlidingVideos";

interface FeedInfo {
  url: string;
  title: string;
  link: string;
  author: string;
  description: string;
  image: string;
}

interface Enclosure {
  link: string;
  type: string;
  thumbnail: string;
}

export interface Item {
  title: string;
  pubDate: string;
  link: string;
  guid: string;
  author: string;
  thumbnail: string;
  description: string;
  content: string;
  enclosure: Enclosure;
  categories: string[];
}

interface FeedResponse {
  status: string;
  feed: FeedInfo;
  items: Item[];
}

export interface IYoutubeFeedComponent {
  id?: string;
  highlights?: Item[];
  latest?: Item[];
  range?: number;
}

export const YoutubeFeedComponent: React.FC<IYoutubeFeedComponent & IStyles> = ({
  id,
  highlights = [],
  latest = [],
  range: rangeInit = 4,
}): JSX.Element => {
  const boxRef = React.useRef<HTMLDivElement | null>(null);

  const [indexHighlights, setIndexHighlights] = React.useState<number>(0);
  const [indexLatest, setIndexLatest] = React.useState<number>(0);
  const [range, setRange] = React.useState<number>(rangeInit);


  React.useEffect(() => {
    const onResize = () => {
      if (boxRef.current) {
        const node = boxRef.current;
        const { offsetWidth } = node;
        let range = 1;
        if (offsetWidth >= (344 + 24) * 3 + 344 * 0.5) {
          range = 4;
        } else if (offsetWidth >= (344 + 24) * 2 + 344 * 0.5) {
          range = 3;
        } else if (offsetWidth >= (344 + 24) * 1 + 344 * 0.5) {
          range = 2;
        } else if (offsetWidth >= (344 + 24) * 0 + 344 * 0.5) {
          range = 1;
        }
        setRange(range);
      }
    };

    onResize();

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  React.useEffect(() => {
    setIndexHighlights(0);
  }, [highlights, setIndexHighlights]);

  React.useEffect(() => {
    setIndexLatest(0);
  }, [latest, setIndexLatest]);

  return (
    <Box id={id} ref={boxRef}>
      <Screen>
        <Content range={range}>
          <Img src="/images/icons/youtube-feed-box.svg" width={88} height={78} alt="gift" />
          <TextTitle>
            Check out WGTV &nbsp;
            <span data-blue>latest content</span>
          </TextTitle>
          <BoxH3>
            <TheIcon icon="youtube:small" size={16} />
            <span data-grow>Latest videos</span>
            {/* {indexLatest > 0 ? (
              <TheIconLink
                href="#"
                onClick={onClick(indexLatest - 1, setIndexLatest)}
                styles={cssLinkArrow}
              >
                <TheIcon icon="arrow:small:left:active" size={16} styles={cssArrow} />
              </TheIconLink>
            ) : (
              <TheIconLink href="#" onClick={onClick(-1)} styles={cssLinkArrow}>
                <TheIcon icon="arrow:small:left:unactive" size={16} styles={cssArrow} />
              </TheIconLink>
            )} */}
            {/* {indexLatest < latest.length - range - 1 ? (
              <TheIconLink
                href="#"
                onClick={onClick(indexLatest + 1, setIndexLatest)}
                styles={cssLinkArrow}
              >
                <TheIcon icon="arrow:small:right:active" size={16} styles={cssArrow} />
              </TheIconLink>
            ) : (
              <TheIconLink href="#" onClick={onClick(-1)} styles={cssLinkArrow}>
                <TheIcon icon="arrow:small:right:unactive" size={16} styles={cssArrow} />
              </TheIconLink>
            )} */}
          </BoxH3>
          <CardsFrame
            styles={css`
              --Cards-offset-index: ${indexLatest};
            `}
            range={range}
          >
            <SlidingVideos videos={latest} duration={latest.length * 5000} />
          </CardsFrame>
          <BoxH3>
            <TheIcon icon="star:small" size={16} />
            <span data-grow>Highlights videos</span>
          </BoxH3>
          <CardsFrame
            styles={css`
              --Cards-offset-index: ${indexHighlights};
            `}
            range={range}
          >
            <SlidingVideos videos={highlights} duration={highlights.length * 7000} />
          </CardsFrame>
        </Content>
      </Screen>
    </Box>
  );
};

export const fetcherYoutubeFeedComponent = async (): Promise<IYoutubeFeedComponent> => {
  let highlights: Item[] = [];
  let latest: Item[] = [];

  try {
    [highlights, latest] = await Promise.all(
      [
        `https://api.rss2json.com/v1/api.json?rss_url=https://www.youtube.com/feeds/videos.xml?playlist_id=PLKiKtzS61PLZ7DFdJiNU4i9YA6dSu7iGL&api_key=${process.env.NEXT_PUBLIC_YOUTUBE_VIDEOS_API_KEY}`,
        `https://api.rss2json.com/v1/api.json?rss_url=https://www.youtube.com/feeds/videos.xml?channel_id=UCMpAWk-kF478wyR44Gobz8g&api_key=${process.env.NEXT_PUBLIC_YOUTUBE_VIDEOS_API_KEY}`,
      ].map(async (url: string) => {
        const response = await fetch(url);
        const json: FeedResponse = await response.json();
        const { items } = json;
        return items;
      })
    );
  } catch (error) {
    console.error(error);
  }

  return { highlights, latest };
};

const Box = styled.div`
  ${$stylesReset}
  background-color: #0b0e16;
`;

const Screen = styled.div`
  ${$stylesReset}
  position: relative;
  max-width: 1920px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  overflow: hidden;
`;

const Content = styled.div<IStyles & { range?: number }>`
  ${$stylesReset}
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  ${({ range = 4 }) =>
    range > 4
      ? css`
          max-width: 1280px;
        `
      : range === 4
      ? css`
          max-width: 1280px;
        `
      : range === 3
      ? css`
          max-width: 910px;
        `
      : range === 2
      ? css`
          max-width: 540px;
        `
      : range === 1
      ? css`
          max-width: 344px;
        `
      : css`
          max-width: calc(344px * 0.5);
        `}
  margin: 24px;
  ${({ styles }) => styles}
`;

const Text = styled.div`
  ${$stylesReset}
  color: rgba(210, 234, 255, 0.75);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: center;
`;

const TextTitle = styled(Text)`
  color: rgb(210, 234, 255); // #d2eaff;
  font-size: 40px;
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  & [data-blue] {
    color: #3b8be9;
  }
  @media (max-width: 800px) {
    color: #d2eaff;
    font-size: 28px;
    /* line-height: 130%;
		letter-spacing: 0.84px; */
  }
  min-width: 300px;
`;

const Img = styled(Image)<IStyles>`
  ${({ styles }) => styles}
`;

// const cssLinkArrow = css`
//   width: 28px;
//   height: 28px;
//   padding: 8px;
//   border: 1px solid rgba(210, 234, 255, 0.05);
//   border-radius: 8px;
// `;

// const cssArrow = css``;

const BoxH3 = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;
  min-width: 300px;
  margin: 8px 0 24px;
  color: rgba(210, 234, 255, 0.75);
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 150%;
  font-weight: 500;

  & [data-grow] {
    flex-grow: 1;
    text-align: left;
  }
`;

const CardsFrame = styled.div<IStyles & { range?: number }>`
  --Card-zoom: calc(1 / 1.4);
  --Card-width: calc(480px * var(--Card-zoom));
  --Card-height: calc(360px * var(--Card-zoom));
  --Card-gap: 24px;
  --Cards-offset-index: 0;

  box-sizing: border-box;
  position: relative;
  ${({ range = 4 }) =>
    range > 1
      ? css`
          width: calc(var(--Card-width) * ${range} + var(--Card-gap) * ${range - 1});
          margin-right: calc(-1 * var(--Card-width) / 2);
        `
      : css`
          width: calc(var(--Card-width));
          margin-right: 0;
        `}
  height: 330px;
  overflow: hidden;

  ${({ styles }) => styles}
`;
