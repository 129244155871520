import React from "react";
import styled, { css } from "styled-components";
import { $stylesReset, IStyles, TheIcon, TheIconLink } from "components";
import Link from "next/link";
import { scrollToElement } from "helpers/scrollToElement";
import { Fade, FadeProps } from "react-awesome-reveal";

interface IFaq {
  active?: boolean;
  answer: any;
  question: string;
  animation: string;
}

export interface IFrequentlyAskedQuestionsComponent {
  id?: string;
  isSeparatePage?: boolean;
}

export const FrequentlyAskedQuestionsComponent: React.FC<IFrequentlyAskedQuestionsComponent> = ({
  id,
  isSeparatePage = false,
}): JSX.Element => {
  const featuredCreatorsComponentId = "FeaturedCreators";

  const [items, setItems] = React.useState<IFaq[]>([
    {
      answer: (
        <div>
          <p>You can earn points in the following ways:</p>
          <ol>
            <li>
              <strong>Interact with the stream and get rewarded:</strong>
              <p>
                Tune in to our{" "}
                <Link
                  onClick={(e) => {
                    if (!isSeparatePage) {
                      e.preventDefault();
                      scrollToElement(featuredCreatorsComponentId);
                    }
                  }}
                  href={
                    isSeparatePage
                      ? `/#${featuredCreatorsComponentId}`
                      : `#${featuredCreatorsComponentId}`
                  }
                  style={{ textDecoration: "underline" }}
                  rel="noopener noreferrer"
                >
                  exciting streams
                </Link>{" "}
                and follow to start accumulating points. The more you interact, the more points
                you&apos;ll earn. So chat it up, follow the channel, and participate in giveaways
                for even more points!
              </p>
            </li>

            <li>
              <strong>Special raffles on stream:</strong>
              <p>
                We&apos;ll be hosting special raffles on our stream. That&apos;s right, you have
                multiple chances to win prizes just by tuning in and watching the fun.
              </p>
            </li>
            <li>
              <strong>Earn money from your favorite streamers big wins:</strong>
              <p>
                When your streamer hits x100, you get paid! It&apos;s a great way to support your
                favorite streamers and make some points at the same time.
              </p>
            </li>
          </ol>

          <p>Any questions? Hit up our support team!</p>

          <p>Happy earning!</p>
        </div>
      ),
      question: "How do I earn points",
      animation: "right",
    },
    {
      answer: (
        <div>
          <p>Looking for ways to use your coins? Here are some exciting options!</p>
          {/* <ol> */}
          <p>
            You can indulge yourself by purchasing unique prizes in our{" "}
            <Link
              style={{ textDecoration: "underline" }}
              href="/points-shop"
              rel="noopener noreferrer"
            >
              Points Shop
            </Link>
            , including physical items, discounts, and more.
          </p>
          <p>
            Or, if you&apos;re feeling lucky, why not join our exclusive{" "}
            <Link href="/raffles" style={{ textDecoration: "underline" }}>
              Raffles
            </Link>
            ? Either way, there&apos;s always something rewarding waiting for you!
          </p>
          {/* </ol> */}
          <p>
            If you have any questions or encounter any issues, please contact our support team for
            assistance.
          </p>
          <p>Happy spending!</p>
        </div>
      ),
      question: "How to spend points",
      animation: "left",
    },
    {
      answer: (
        <div>
          <li style={{ listStyleType: "none" }}>1.Wager Using Code WGTV or WatchGamesTV:</li>
          <p>
            To participate, simply wager under the code{" "}
            <span style={{ fontSize: "bold" }}>WGTV</span> or WatchGamesTV on{" "}
            <Link
              target="_blank"
              style={{ textDecoration: "underline" }}
              href="https://gamdom.com/r/wgtv"
              rel="noopener noreferrer"
            >
              Gamdom
            </Link>{" "}
            ,{" "}
            <Link
              target="_blank"
              style={{ textDecoration: "underline" }}
              href="https://hypedrop.com/r/WGTV"
              rel="noopener noreferrer"
            >
              Hypedrop
            </Link>{" "}
            or{" "}
            <Link
              target="_blank"
              style={{ textDecoration: "underline" }}
              href="https://packdraw.com/?ref=wgtv"
              rel="noopener noreferrer"
            >
              PackDraw
            </Link>{" "}
            .
          </p>
          <li style={{ listStyleType: "none" }}>2.Monthly Reset:</li>
          <p>
            At the end of each month the top users with the most wagered are paid out cash prizes
            and the leaderboards are reset. Chance for complementary prizes are also available for
            those that wager the required amounts stated on leadearboard page.
          </p>
          <p>Stay competitive! The Leaderboard is refreshed every 12 hours.</p>
          <p>
            Please note, to ensure a fair gaming environment, refrain from misusing platforms reward
            system. Each user will be manually checked for complying with leaderboard rules, and
            abuse.
          </p>
          <li style={{ listStyleType: "none" }}>3.HIDDEN PROFILES:</li>
          <p>
            If you want to compete and earn prizes, please do not hide your profile. Users with HIDDEN PROFILES will not be shown on leaderboard.
          </p>
        </div>
      ),
      question: "How to participate in leaderboard",
      animation: "right",
    },
    {
      answer: (
        <div>
          <p>
            When it&apos;s time for a{" "}
            <Link
              style={{ textDecoration: "underline" }}
              href="/pachinko"
              rel="noopener noreferrer"
            >
              Pachinko
            </Link>{" "}
            round, WatchGamesTV will announce a giveaway on the stream. Viewers will have the
            opportunity to participate by typing a keyword, which will enter them into a raffle for
            a chance to play.
          </p>

          <p>
            Players in the Pachinko round have the chance to win fantastic rewards, including bonus
            buys, cash prizes, and points that can be used in the stream store.
          </p>

          <p>
            Please note that any form of inactivity or a breach of our{" "}
            <Link
              style={{ textDecoration: "underline" }}
              href="/terms-of-service"
              rel="noopener noreferrer"
            >
              Terms of Service (TOS)
            </Link>{" "}
            will void the winner&apos;s status, and another person will be selected in their place.
          </p>

          <p>
            If you have any questions or need further assistance, please feel free to contact our
            support team.
          </p>

          <p>Enjoy the excitement of Pachinko rounds!</p>
        </div>
      ),
      question: "What is pachinko",
      animation: "left",
    },
    {
      answer: (
        <div>
          <p>
            To participate in our exciting{" "}
            <Link href="/raffles" style={{ textDecoration: "underline" }}>
              Raffles
            </Link>
            , follow these steps:
          </p>

          <ol>
            <li>
              <strong>Visit the Raffles Page:</strong>
              <p>
                Navigate to our{" "}
                <Link href="/raffles" style={{ textDecoration: "underline" }}>
                  Raffles page
                </Link>{" "}
                to view the available raffles.
              </p>
            </li>

            <li>
              <strong>Account Requirements:</strong>
              <p>All raffles require you to have an account on our platform.</p>
            </li>

            <li>
              <strong>Choose a Raffle:</strong>
              <p>
                Browse through the list of raffles and choose the one you&apos;d like to enter. We
                offer both free and non-free raffles, giving everyone a chance to win!
              </p>
            </li>

            <li>
              <strong>Participate:</strong>
              <p>
                Once you&apos;ve selected a raffle, click the &quot;Join&quot; button to enter.
                You&apos;ll be included in the raffle for a chance to win fantastic prizes!
              </p>
            </li>
          </ol>

          <p>
            Whether you&apos;re interested in free or non-free raffles, our platform offers
            opportunities for everyone to participate and win exciting rewards. Visit our{" "}
            <Link href="/raffles" style={{ textDecoration: "underline" }}>
              Raffles page
            </Link>{" "}
            now to get started!
          </p>

          <p>
            If you have any questions or need further assistance, please feel free to contact our
            support team .
          </p>

          <p>Good luck!</p>
        </div>
      ),
      question: "How to participate in raffles",
      animation: "right",
    },
    {
      answer: (
        <div>
          <p>
            You can easily check whether our creators are currently live by following these steps:
          </p>

          <ol>
            <li>
              <strong>Check the Website Header:</strong>
              <p>
                Simply look at the header of our website, where you&apos;ll find a live status
                indicator. When someone is currently live, it will display &quot;ONLINE.&quot; If no
                one is streaming, it will be marked as &quot;OFFLINE.&quot; You can also click on
                this indicator to directly access the live stream.
              </p>
            </li>

            <li>
              <strong>Explore Featured Creators:</strong>
              <p>
                Another way to check featured creators and their live statuses is by visiting{" "}
                <Link href="/#FeaturedCreators" style={{ textDecoration: "underline" }}>
                  Featured Creators
                </Link>{" "}
                page. Here, you&apos;ll find up-to-date information on which creators are currently
                live and can join their streams.
              </p>
            </li>
          </ol>

          <p>
            Our live status indicator in the website header provides real-time information about the
            streaming status of our talented creators. Stay connected and join the live streams to
            interact with your favorite creators and enjoy their content!
          </p>

          <p>
            If you have any questions or need further assistance, please feel free to contact our
            support team.
          </p>
        </div>
      ),
      question: "How to check whether creators are live?",
      animation: "left",
    },
    {
      answer: (
        <div>
          <p>
            When you alter your Twitch or Kick username, your points might appear missing. This
            happens because you need to update your account name in our system.
          </p>
          <strong>To fix this:</strong>
          <ol>
            <li>
              <p>
                If you changed your Twitch username, visit{" "}
                <Link
                  target="_blank"
                  href="https://www.twitch.tv/watchgamestv"
                  style={{ textDecoration: "underline" }}
                >
                  twitch.tv/watchgamestv
                </Link>{" "}
              </p>
            </li>
            <li>
              <p>
                For a changed Kick username, navigate to{" "}
                <Link
                  target="_blank"
                  href="https://kick.com/watchgamestv"
                  style={{ textDecoration: "underline" }}
                >
                  kick.com/watchgamestv
                </Link>{" "}
              </p>
            </li>
            <li>
              <p>
                Once there, type the command !rename followed by your old username in the chat.
                Ensure all the words are in lowercase.
              </p>
            </li>
          </ol>
        </div>
      ),
      question: "Did you change your username and now can't see your points?",
      animation: "right",
    },
  ]);

  const onClick = React.useMemo(() => {
    return (idx: number) => (event: React.MouseEvent) => {
      event.preventDefault();
      setItems((items: IFaq[]): IFaq[] => {
        return items.map((item, index) => {
          if (index === idx) {
            return { ...item, active: !item?.active };
          }
          return { ...item, active: false };
        });
      });
    };
  }, [setItems]);

  return (
    <Box id={id}>
      <Screen>
        <Content>
          <TextTitle>
            <span>Frequently asked</span>
            &nbsp;
            <span data-blue>questions</span>
            <br />
            <br />
          </TextTitle>
          <Cards>
            {items.map((item, index) => {
              return (
                <Fade
                  direction={item.animation as FadeProps["direction"]}
                  style={{ width: "100%" }}
                  key={index}
                  triggerOnce
                >
                  <Card>
                    <CardQuestion data-active={item.active}>
                      <span data-grow>{item.question}</span>
                      {item.active ? (
                        <TheIconLink href="#" onClick={onClick(index)} styles={cssLinkArrow}>
                          <TheIcon icon="spoiler:up" size={16} styles={cssArrow} />
                        </TheIconLink>
                      ) : (
                        <TheIconLink href="#" onClick={onClick(index)} styles={cssLinkArrow}>
                          <TheIcon icon="spoiler:down" size={16} styles={cssArrow} />
                        </TheIconLink>
                      )}
                    </CardQuestion>
                    {item.active ? <CardAnswer>{item.answer}</CardAnswer> : null}
                  </Card>
                </Fade>
              );
            })}
          </Cards>
        </Content>
      </Screen>
    </Box>
  );
};

const cssArrow = css``;

const Box = styled.div`
  ${$stylesReset}
  background-color: #0b0e16;
`;

const Screen = styled.div`
  ${$stylesReset}
  position: relative;
  max-width: 1920px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  overflow: hidden;
`;

const Content = styled.div`
  ${$stylesReset}
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: 1018px;
  margin: 24px;
  margin-bottom: 48px;
`;

const Text = styled.div`
  ${$stylesReset}
  color: rgba(210, 234, 255, 0.75);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: center;
`;

const TextTitle = styled(Text)`
  color: rgb(210, 234, 255); // #d2eaff;
  font-size: 40px;
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  word-wrap: break-word;
  & [data-blue] {
    color: #3b8be9;
  }
  @media (max-width: 800px) {
    font-size: 28px;
  }
`;

const cssLinkArrow = css`
  width: 28px;
  height: 28px;
  padding: 8px;
  border: 1px solid rgba(210, 234, 255, 0.05);
  border-radius: 8px;
`;

const Cards = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
`;

const Card = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-flow: column;
  gap: 16px;
  width: 100%;
  padding: 19px 24px;

  background: radial-gradient(
      152.76% 130.7% at 50% 0%,
      rgba(101, 101, 101, 0.05) 0%,
      rgba(101, 101, 101, 0) 100%
    ),
    #101622;
  border: 1px solid rgba(210, 234, 255, 0.1);
  border-radius: 8px;
`;

const CardAnswer = styled.div<IStyles>`
  width: 100%;
  overflow: hidden;

  // text
  color: rgba(210, 234, 255, 0.75);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;

  // text flow
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;

  ${({ styles }) => styles}
`;

const CardQuestion = styled.div<IStyles>`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  // text
  color: rgba(210, 234, 255, 0.5);
  font-size: 16px;
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.03em;
  line-height: 19px;
  text-align: left;
  text-transform: uppercase;

  & [data-grow] {
    flex-grow: 1;
    text-align: left;
  }

  &[data-active="true"] {
    color: #d2eaff;
    text-shadow: 0px 0px 24px rgba(40, 113, 255, 0.5), 0px 0px 6px rgba(58, 113, 254, 0.46),
      0px 2px 4px #0b0e23;
  }

  ${({ styles }) => styles}
`;
