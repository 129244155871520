// import React from "react";
import { truncateText } from "helpers/truncateText";
import styled from "styled-components";
import { formatNumber } from "./utils/formatNumbers";

type LeaderboardRandomWinnerCardProps = {
  prizeNumber: number;
  prize: number;
  displayName?: string;
};

const LeaderboardRandomWinnerCard: React.FC<LeaderboardRandomWinnerCardProps> = ({
  prizeNumber,
  displayName,
  prize,
}) => {
  return (
    <Card number={prizeNumber}>
      <CardContent>
        {displayName ? (
          <DisplayName>{truncateText(displayName || "UnknownPlayer", 10)}</DisplayName>
        ) : null}
        <Prize data-text={`$${formatNumber(prize)}`}>${formatNumber(prize)}</Prize>
      </CardContent>
      <AnimatedBackground />
      <GradientBorder />
    </Card>
  );
};

const DisplayName = styled.span`
  font-family: "Golos Text";
  font-size: 16px;
  text-transform: uppercase;
  color: #e9f4ff;
`;

const Card = styled.div<{ number: number }>`
  position: relative;
  min-width: 150px;
  min-height: 200px;
  border-radius: 0.5rem;
  height: 100%;

  &::before {
    content: "${({ number }) => number}";
    font-family: "Roboto";
    font-style: italic;
    font-weight: 900;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-image: linear-gradient(
      0.01deg,
      rgba(57, 57, 80, 0.375) 0.01%,
      rgba(21, 21, 27, 0) 114.52%
    );
    background-color: #131620;

    border-radius: 40px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #131620;
    z-index: 10;

    text-shadow: 0px 0px 20px #2b7ae2;
  }

  &::after {
    content: url("/images/leaderboard/LeaderboardWinnerCard/medal.svg");
    font-family: "Roboto";
    font-style: italic;
    font-weight: 900;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    background-image: linear-gradient(
      0.01deg,
      rgba(57, 57, 80, 0.375) 0.01%,
      rgba(21, 21, 27, 0) 114.52%
    );
    background-color: #131620;

    border-radius: 40px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    text-shadow: 0px 0px 20px #2b7ae2;
  }
`;

const GradientBorder = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  padding: 2px;
  z-index: 0;
  border-radius: inherit;

  &::before {
    content: "";
    display: block;
    position: absolute;
    border-radius: inherit;
    z-index: 0;
    inset: -2px;
    background: linear-gradient(180deg, #2884e0 0%, rgba(0, 0, 0, 0) 100%);
  }
`;
const CardContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  z-index: 10;
`;

const Prize = styled.div`
  position: relative;
  font-family: "Roboto", sans-serif;
  font-size: 52px;
  font-style: italic;
  font-weight: 900;
  background: linear-gradient(
    79.49deg,
    #2b7ae2 -4.55%,
    #4fc9f4 11.54%,
    #87e9ff 15.63%,
    #4fd1f4 19.58%,
    #2884e0 35.67%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;

  &::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    text-shadow: -1px -1px 0 rgba(0, 209, 255, 0.1), 1px 1px 0 #85e9ff;
  }
`;

const AnimatedBackground = styled.div`
  --image: url("/images/leaderboard/LeaderboardWinnerCard/Vector_v2.svg");
  --gradient: linear-gradient(180deg, rgba(57, 57, 80, 0.75) 0%, rgba(24, 28, 44, 0.75) 140.57%);

  position: absolute;
  inset: 0;
  z-index: 1;
  display: flex;

  border-radius: inherit;
  overflow: hidden;
  background-image: var(--gradient);
  background-color: #131620;

  &::before,
  &::after {
    content: "";
    position: absolute;
    inset: -100px;
    display: block;
    z-index: 0;
    width: 600px;
    height: 600px;
    border-radius: inherit;
    background-size: contain;
    background-image: var(--image);
    opacity: 0.1;
  }

  &::after {
    transform-origin: center top;
    animation: diagonalMove1 30s linear infinite;
  }
  /* fix rotation */
  &::before {
    margin-top: 3rem;
    transform-origin: center right;
    animation: diagonalMove2 30s linear infinite;
  }
`;

export default LeaderboardRandomWinnerCard;
