import Image from "next/image";
import React from "react";
import styled, { css } from "styled-components";
import {
  $styles,
  $stylesReset,
  IStyles,
  TheCheckbox,
  TheFieldset,
  TheIcon,
  TheNextScroll,
} from "components";
import { Button, ButtonBlue, DualRingInner, DualRingLoader } from "components/common";
import { BeAGuestDasboard, IAppContext, withApp } from "contexts";
import { triggerNotification } from "components/TheComponents/Notification";
import { socket } from "socket";
import { SocketEvent } from "helpers/constants";
import { ExceptionHandlingType } from "types/apiServiceTypes";

export interface IBeAGuestComponent {
  id?: string;
  nextScrollHref?: string;
}

const BeAGuestComponent: React.FC<IBeAGuestComponent & IAppContext> = ({
  id,
  accessFetch,
  onDiscordRedirect,
  loginModalProps,
  profile,
  nextScrollHref = "#",
}): React.ReactElement => {
  const { onOpenLogin } = loginModalProps;
  const profileDiscordId = profile?.discordId;
  const [message, setMessage] = React.useState<string>("");
  const [dashboard, setDashboard] = React.useState<BeAGuestDasboard>({
    totalParticipants: 0,
    probability: 0,
  });
  const [haveMicrophone, setHaveMicrophone] = React.useState<boolean>(true);
  const [haveWebcam, setHaveWebcam] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<any>({});

  React.useEffect(() => {
    socket.on(SocketEvent.StreamGuestsDashboardUpdate, (data) => {
      setDashboard(data);
    });

    return () => {
      socket.off(SocketEvent.StreamGuestsDashboardUpdate, () =>
        console.log("Not listening to StreamGuestsDashboardUpdate")
      );
    };
  }, []);

  const onTextChange = React.useMemo(() => {
    return (setValue: Function) => (event: React.ChangeEvent) => {
      event.preventDefault();
      const { target } = event;
      if (target instanceof HTMLInputElement) {
        const { value } = target as HTMLInputElement;
        setValue(value);
      } else if (target instanceof HTMLTextAreaElement) {
        const { value } = target as HTMLTextAreaElement;
        setValue(value);
      }
    };
  }, []);

  const fetchDashboard = React.useCallback(async () => {
    const response = await accessFetch(
      "/pick-a-guest/dashboard",
      undefined,
      ExceptionHandlingType.SILENT
    );
    const data = await response?.json();
    setDashboard(data);
  }, [accessFetch]);

  React.useEffect(() => {
    fetchDashboard(); // Initial data fetch
  }, [fetchDashboard]);

  const onLabelClink = React.useMemo(() => {
    return (checked: boolean, setChecked: Function) => (event: React.MouseEvent) => {
      event.preventDefault();
      setChecked(!checked);
    };
  }, []);

  const onCheckboxChange = React.useMemo(() => {
    return () => (event: React.ChangeEvent) => {
      event.preventDefault();
    };
  }, []);

  const onLinkDiscordAttempt = () => {
    if (profile) {
      onDiscordRedirect();
    } else {
      onOpenLogin();
    }
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (message.length > 3000) {
      const message = "Message is too long";
      newErrors.message = message;
      triggerNotification({ text: message, type: "error" });
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const becomeParticipant = async (event: React.MouseEvent) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      setIsLoading(true);
      const response = await accessFetch(
        "/pick-a-guest/participate",
        {
          method: "POST",
          body: JSON.stringify({
            message: message,
            microphone: haveMicrophone,
            camera: haveWebcam,
          }),
        },
        ExceptionHandlingType.AUTOMATIC
      );
      const data = await response?.json();

      if (response && (response.status === 200 || response.status === 201)) {
        triggerNotification({ text: "Request sent!", type: "success" });
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  function formatProbability(probability: number) {
    // Round the probability to 2 decimal places
    const roundedProbability = Math.round(probability * 100) / 100;

    // Check if the rounded probability is less than 0.01
    if (roundedProbability < 0.01) {
      return "< 0.01";
    } else {
      return roundedProbability.toFixed(2);
    }
  }

  return (
    <Box id={id}>
      <Screen>
        <Img
          src="/images/BeAGuestComponent/bg-lines.svg"
          width={1920}
          height={980}
          alt="light"
          styles={css`
            position: absolute;
            top: 0;
          `}
        />
        <Content>
          <div data-left>
            <BoxH1>
              Want to &nbsp;
              <span data-blue>be a Guest</span>
              &nbsp; of next WGTV video?
            </BoxH1>
            <BoxP1>
              <div>
                Once a week one of the WGTV subscribers will get a chance to spend Watch&apos;s
                balance along side Watch in a youtube video.
              </div>
              <div>&nbsp;</div>
              <div>Feel that it should be you ?</div>
              <div>&nbsp;</div>
              <div>
                Fill in the form on the right and you might be the one calling the shots on the next
                video!
              </div>
            </BoxP1>
            <BoxTP>
              <span data-row>
                <span>
                  <TheIcon icon="person:small" size={32} />
                </span>
                <span data-column>
                  <span>Total assigns</span>
                  <span data-value>{dashboard?.totalParticipants}</span>
                </span>
              </span>
              <span data-row data-colored>
                <span>
                  <TheIcon icon="cube:small" size={32} />
                </span>
                <span data-column>
                  <span>Probability</span>
                  <span data-value>
                    {dashboard?.totalParticipants > 0
                      ? formatProbability(dashboard.probability)
                      : "0.00"}
                    %
                  </span>
                </span>
              </span>
            </BoxTP>
          </div>
          <div data-right>
            <BoxFormDecoration>
              <BoxForm>
                <BoxH2>Get invited for WGTV video!</BoxH2>
                <BoxP2>You will be automatically added to a roll !</BoxP2>
                {profileDiscordId ? (
                  <TheFieldset
                    disabled={true}
                    valid={!errors.discordId}
                    title="Discord Name"
                    styles={css`
                      margin-bottom: 16px;
                      background-image: url("/images/icons/discord-purple.png");
                      background-repeat: no-repeat;
                      background-size: 20px 15px;
                      background-position: 3% center;
                      opacity: 0.9;
                      padding-left: 35px;
                    `}
                  >
                    <input
                      type="text"
                      placeholder={errors.discordId ? errors.discordId : "Discord Id"}
                      value={profile?.discordUsername || ""}
                      onChange={() => {}}
                    />
                  </TheFieldset>
                ) : (
                  <Button
                    styles={css`
                      margin: 0 2px 16px;
                      border: 1px solid #3b8be9;
                      background: #101622;
                      height: 50px;
                      padding: 10px 22px;
                    `}
                    height={50}
                    isDark
                    onClick={onLinkDiscordAttempt}
                  >
                    <ButtonBlue isDark>
                      <Image
                        src="/images/icons/discord-purple.png"
                        height={15}
                        width={19}
                        alt="image"
                      />
                      Connect
                    </ButtonBlue>
                  </Button>
                )}
                <TheFieldset
                  empty={!message}
                  valid={!errors.message}
                  disabled={!profileDiscordId}
                  title="Message"
                  styles={css`
                    margin-bottom: 16px;
                  `}
                >
                  <textarea
                    placeholder={errors.message ? errors.message : "Message (Optional)"}
                    value={message}
                    onChange={onTextChange(setMessage)}
                  />
                </TheFieldset>
                <BoxP2>Does not effect your chance*</BoxP2>
                <BoxP2>
                  <Label onClick={onLabelClink(haveMicrophone, setHaveMicrophone)}>
                    <TheCheckbox
                      preset="dark-blue"
                      checked={haveMicrophone}
                      onChange={onCheckboxChange()}
                    />
                    <span data-checked={haveMicrophone}>I have a good microphone</span>
                  </Label>
                </BoxP2>
                <BoxP2>
                  <Label onClick={onLabelClink(haveWebcam, setHaveWebcam)}>
                    <TheCheckbox
                      preset="dark-blue"
                      checked={haveWebcam}
                      onChange={onCheckboxChange()}
                    />
                    <span data-checked={haveWebcam}>I have a nice webcam</span>
                  </Label>
                </BoxP2>
                <Button
                  disabled={!profileDiscordId}
                  isLoading={isLoading}
                  onClick={(event: React.MouseEvent) => {
                    becomeParticipant(event);
                  }}
                  style={{ height: 50, cursor: "pointer", width: "100%" }}
                >
                  <ButtonBlue style={{ whiteSpace: "nowrap" }} transparent>
                    {isLoading ? (
                      <DualRingLoader smallRing>
                        <DualRingInner smallRing />
                      </DualRingLoader>
                    ) : (
                      "Send Request"
                    )}
                  </ButtonBlue>
                </Button>
                {/* <BoxP2>
                  <Label
                    style={!iAllow ? { color: "#e93b3b" } : {}}
                    onClick={onLabelClink(iAllow, setIAllow)}
                  >
                    <TheCheckbox
                      preset="dark-blue"
                      checked={iAllow}
                      onChange={onCheckboxChange(iAllow, setIAllow)}
                    />
                    <span data-checked={iAllow}>
                      I allow WRewards to collect my email and send me notifications.
                    </span>
                  </Label>
                </BoxP2> */}
              </BoxForm>
            </BoxFormDecoration>
          </div>
        </Content>
        <Next>
          <TheNextScroll
            href={nextScrollHref}
            styles={css`
              z-index: 100;
            `}
          >
            explore bonuses
          </TheNextScroll>
        </Next>
      </Screen>
    </Box>
  );
};

export default withApp(BeAGuestComponent);

const Box = styled.div`
  ${$stylesReset}
  background-color: #0b0e16;
`;

const Screen = styled.div`
  ${$stylesReset}
  position: relative;
  max-width: 1920px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  overflow: hidden;
  background-image: url("/images/BeAGuestComponent/light.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
`;

const Content = styled.div`
  ${$stylesReset}
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 24px;
  gap: 40px;

  & [data-left] {
    box-sizing: border-box;
    display: inline-block;
    max-width: 480px;
    margin: 0 16px;
    text-align: center;
  }

  & [data-right] {
    box-sizing: border-box;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    max-width: 480px;
    margin: 0 16px;
  }

  @media (min-width: 1860px) {
    flex-direction: row;
    justify-content: center;
    gap: 60px;
    max-width: 1020px;
    height: calc(980px - 230px - 181px); // 569px
    margin: 230px auto 181px;

    & [data-left] {
      text-align: left;
    }

    & [data-right] {
      padding-left: 90px;
    }
  }
`;

const Img = styled(Image)<IStyles>`
  ${$styles}
`;

const Text = styled.div`
  ${$stylesReset}
  color: rgba(210, 234, 255, 0.75);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: center;
`;

const Next = styled(Text)`
  position: absolute;
  bottom: 0;
  width: 100%;
  @media (max-width: 1860px) {
    display: none;
  }
`;

const BoxH1 = styled.div<IStyles>`
  color: #d2eaff;
  font-family: "Roboto";
  font-size: 40px;
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.03em;
  line-height: 130%;
  text-transform: uppercase;

  & [data-blue] {
    color: rgba(59, 139, 233, 1);
  }

  ${$styles}
`;

const BoxP1 = styled.div<IStyles>`
  box-sizing: border-box;
  margin: 56px 0 111px;
  color: rgba(210, 234, 255, 0.75);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;

  ${$styles}
`;

const BoxTP = styled.div<IStyles>`
  box-sizing: border-box;
  display: inline-flex;

  // border
  border: 1px solid #46d3f2;
  border-radius: 8px;

  // text
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;

  & [data-row] {
    display: inline-flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 180px;
    height: 82px;
  }

  & [data-column] {
    display: inline-flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
  }

  & [data-colored] {
    background: linear-gradient(265.52deg, #4796f2 -1.78%, #47d3f2 85.44%);
    color: #13171f;
    border-radius: 6px;
  }

  & [data-value] {
    font-family: "Rubik";
    font-size: 24px;
    font-weight: 600;
    line-height: 150%;
  }

  ${$styles}
`;

const BoxFormDecoration = styled.div<IStyles>`
  box-sizing: border-box;
  position: relative;

  &::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    right: -135px;
    top: 250px;
    width: 199px;
    height: 212px;
    background-clip: border-box;
    background-image: url("/images/BeAGuestComponent/video.png");
    background-position: center;
    background-repeat: no-repeat;
    animation: float 6s ease-in-out infinite;
  }

  &::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    z-index: 3;
    left: -80px;
    top: 20px;
    width: 118px;
    height: 121px;
    background-clip: border-box;
    background-image: url("/images/BeAGuestComponent/bonus.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: calc(118px * 0.9) calc(121px * 0.9);
    animation: float 4s ease-in-out infinite;
  }

  @media (max-width: 666px) {
    &::before {
      display: none;
    }
  }
  @media (max-width: 550px) {
    &::after {
      display: none;
    }
  }

  ${$styles}
`;

const BoxForm = styled.form<IStyles>`
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  width: 390px;
  /* height: 571px; */
  padding: 40px;

  // background
  background: radial-gradient(
      152.76% 130.7% at 50% 0%,
      rgba(101, 101, 101, 0.05) 0%,
      rgba(101, 101, 101, 0) 100%
    ),
    #151c2c;

  // border
  border: 1px solid rgba(210, 234, 255, 0.1);
  border-radius: 8px;

  ${$styles}
`;

const BoxH2 = styled.div<IStyles>`
  color: #d2eaff;
  font-family: "Roboto";
  font-size: 20px;
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.03em;
  line-height: 23px;
  text-transform: uppercase;

  ${$styles}
`;

const BoxP2 = styled.div<IStyles>`
  box-sizing: border-box;
  margin: 16px 0;

  // text
  color: rgba(210, 234, 255, 0.5);
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;

  & [data-checked="true"] {
    color: #d2eaff;
  }

  ${$styles}
`;

const Label = styled.label<IStyles>`
  box-sizing: border-box;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
  cursor: pointer;

  ${$styles}
`;
