import React, { useEffect } from "react";
import NavLink from "./NavLink";
import { Nav } from "../../styles";
import { ITopMenuItem, menuItems } from "../../dto";
import { filterNavItems } from "../../utils/filterNavItems";
import { useAppContext } from "contexts";
import { useShowPageLayoutContext } from "contexts/ShowPageLayoutContext";

const Navigation = () => {
  const { profile, isAdmin, isFeatureEnabled, featureFlags } = useAppContext();
  const [items, setItems] = React.useState<ITopMenuItem[]>([]);
  const [itemKey, setItemKey] = React.useState<string>("");
  const [isOpenDropdown, setIsOpenDropdown] = React.useState<boolean>(false);
  const { showNavSidebar } = useShowPageLayoutContext();

  useEffect(() => {
    const newItems = filterNavItems(menuItems, isFeatureEnabled, profile, isAdmin);
    setItems(newItems);
  }, [profile, isFeatureEnabled, featureFlags, isAdmin]);

  const onToggle = () => {
    setIsOpenDropdown((prev) => !prev);
  };

  return (
    <Nav show={showNavSidebar}>
      {items.map((item, index) => {
        return (
          <NavLink
            key={index}
            item={item}
            handleCheckboxChange={onToggle}
            itemKey={itemKey}
            setItemKey={setItemKey}
          />
        );
      })}
    </Nav>
  );
};

export default Navigation;
