import React from "react";
import { ITheHeaderProfile } from "interfaces";
import Image from "next/image";
import { ThePointsBalance } from "../../../../components/TheComponents/ThePointsBalance";
import {
  DesktopView,
  Tile,
  Text,
  MobileView,
  CoinFlex,
  CalendarLink,
  MobileCoins,
  ExchangeBtn,
  MenuBtn,
  MenuListStyled,
  BalanceTile,
  ProfileDisplayName,
  ProfileButton,
} from "./styles";
import Calendar from "../icons/Calendar";
import ArrowDownIcon from "../../../../../public/images/icons/arrow-down.svg";
import { Menu, MenuButton, MenuList } from "@chakra-ui/react";
import ChakraModal from "components/TheComponents/Modal";
import ExchangeModal from "components/games/ExchangeBanner/ExchangeModal";
import { triggerNotification } from "components/TheComponents/Notification";
import { DateTime } from "luxon";
import { useRouter } from "next/router";

const HeaderProfile = ({
  profile,
  onOpenLogout,
}: ITheHeaderProfile & {
  onOpenLogout: () => void;
}): React.ReactElement => {
  const router = useRouter();
  const [isShowModal, setIsShowModal] = React.useState(false);
  const [currentMonthIsAugust, setCurrentMonthIsAugust] = React.useState(false);

  React.useEffect(() => {
    const currentUTCMonth = DateTime.utc().month;
    setCurrentMonthIsAugust(currentUTCMonth === 8); // August is month 8
  }, []);

  const exchange = () => {
    if (profile) {
      setIsShowModal(true);
    } else {
      triggerNotification({ text: "Please, login for exchage points", type: "error" });
    }
  };

  return (
    <>
      <DesktopView>
        <CalendarLink href="/advent-calendar">
          <Calendar fill="#F2C94C" />
          <span>
          {currentMonthIsAugust ? "Free 200K" : "Free 250K"}
            <br />
            Calendar
          </span>
        </CalendarLink>
        <Menu placement="auto" isLazy>
          {({ isOpen }) => (
            <>
              <MenuButton isActive={isOpen} as={MenuBtn}>
                <div>
                  <Image alt="g-coin" src="/images/icons/balance.svg" width={26} height={26} />
                </div>
                Balance
                <div className="icon">
                  <ArrowDownIcon />
                </div>
              </MenuButton>
              <MenuList padding={0} background={"transparent"}>
                <MenuListStyled>
                  <div className="title">Your points balance</div>
                  <div className="content">
                    <BalanceTile>
                      <Image alt="w-coin" src="/images/coins/w-coin.svg" width={24} height={24} />
                      <div>
                        <small>W-Points</small>
                        <Text>
                          <ThePointsBalance value={profile?.pointsBalance} isFixed />
                        </Text>
                      </div>
                    </BalanceTile>
                    <BalanceTile>
                      <Image alt="g-coin" src="/images/coins/g-coin.svg" width={24} height={24} />
                      <div>
                        <small>G-Points</small>
                        <Text>
                          <ThePointsBalance value={profile?.gPointsBalance} />
                        </Text>
                      </div>
                    </BalanceTile>

                    <ExchangeBtn onClick={exchange}>
                      <Image alt="g-coin" src="/images/icons/exchange.svg" width={24} height={24} />
                      <span>Exchange Points</span>
                    </ExchangeBtn>
                  </div>
                </MenuListStyled>
              </MenuList>
            </>
          )}
        </Menu>

        <Tile>
          <ProfileButton
            onClick={() => {
              router.push("/profile");
            }}
          >
            <Image
              alt="profile"
              src={profile?.profileImageUrl ?? ""}
              width={24}
              height={24}
              style={{
                borderRadius: "50%",
              }}
            />
            <ProfileDisplayName>{profile?.displayName}</ProfileDisplayName>
          </ProfileButton>
          <Image
            alt="logout"
            style={{ cursor: "pointer", marginLeft: 12 }}
            onClick={onOpenLogout}
            width={20}
            height={20}
            src="/images/icons/logout.png"
          />
        </Tile>
      </DesktopView>
      <MobileView>
        <Tile>
          <ProfileButton
            onClick={() => {
              router.push("/profile");
            }}
          >
            <Image
              alt="profile"
              src={profile?.profileImageUrl ?? ""}
              width={24}
              height={24}
              style={{
                borderRadius: "50%",
              }}
            />
            <Text>{profile?.displayName}</Text>
          </ProfileButton>
        </Tile>
        <MobileCoins>
          <Tile>
            <CoinFlex>
              <Image alt="w-coin" src="/images/coins/w-coin.svg" width={24} height={24} />
              <Text>
                <ThePointsBalance value={profile?.pointsBalance} isFixed />
              </Text>
            </CoinFlex>
          </Tile>

          <Tile>
            <CoinFlex>
              <Image alt="g-coin" src="/images/coins/g-coin.svg" width={24} height={24} />
              <Text>
                <ThePointsBalance value={profile?.gPointsBalance} />
              </Text>
            </CoinFlex>
          </Tile>
        </MobileCoins>
      </MobileView>

      <ChakraModal
        content={
          <ExchangeModal
            onClose={() => {
              setIsShowModal(false);
            }}
          />
        }
        isOpen={isShowModal}
        onClose={() => setIsShowModal(false)}
      />
    </>
  );
};

export default HeaderProfile;
