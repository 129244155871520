import React from "react";
import styled, { css } from "styled-components";
import {
  $stylesReset,
  IStyles,
  Ident,
  TheIcon,
  LeaderboardTopWinnerCardList,
  Pages,
} from "components";
import { ILeaderboardWithWinnerDto, TheLeaderboardSourceKeys } from "interfaces";
import { Button, ButtonBlue, Flex, NoEntitiesText } from "components/common";
import { useRouter } from "next/router";
import { SlideSwitcher } from "components";

export interface ILeaderboardTopWinnersBlock {
  title?: JSX.Element | string;
  switcher: string;
  setSwitcher: (switcher: string) => void;
  leaderboards: ILeaderboardWithWinnerDto[];
  showBackground?: boolean;
  showBlockHeaderLogo?: boolean;
  showBlockHeader?: boolean;
  showSwitcher?: boolean;
  showPages?: boolean;
  showViewHistoryButton?: boolean;
  range?: number;
  skip?: number;
  take?: number;
  total?: number;
  onPage?: (index: number) => void;
  doHref?: (leaderboard?: ILeaderboardWithWinnerDto) => string;
}

const casinoProviderBackgrounds = {
  [TheLeaderboardSourceKeys.gamdom]: css`
    background: linear-gradient(
        180deg,
        rgba(11, 14, 22, 0) -22.19%,
        rgba(22, 101, 36, 0.2) 91.27%,
        rgba(22, 101, 36, 0) 139.18%
      ),
      url("/images/leaderboard/LeaderboardWinnerBlock/bg-gambom.svg");
  `,
  [TheLeaderboardSourceKeys.hypedrop]: css`
    background: linear-gradient(
        180deg,
        rgba(11, 14, 22, 0) -22.19%,
        rgba(22, 101, 36, 0.2) 91.27%,
        rgba(22, 101, 36, 0) 139.18%
      ),
      url("/images/leaderboard/LeaderboardWinnerBlock/bg-hypedrop.svg");
  `,
  [TheLeaderboardSourceKeys.packdraw]: css`
    background: linear-gradient(to bottom, rgba(29, 22, 51, 1) 0%, rgba(29, 22, 51, 0) 100%),
      url("/images/leaderboard/LeaderboardWinnerBlock/bg-packdraw.png");
    background-size: 100% auto;
    @media (max-width: 900px) {
      background-size: contain;
    }
  `,
};

export const LeaderboardTopWinnersBlock: React.FC<ILeaderboardTopWinnersBlock> = ({
  title = <>You can check out the previous leaderboards and top winners below.</>,
  switcher = TheLeaderboardSourceKeys.gamdom,
  setSwitcher,
  leaderboards,
  showBackground = false,
  showBlockHeaderLogo = false,
  showBlockHeader = false,
  showSwitcher = true,
  showPages = false,
  showViewHistoryButton = false,
  range,
  skip,
  take,
  total,
  onPage,
  doHref,
}): React.ReactElement => {
  const router = useRouter();

  return (
    <Box casinoProvider={switcher} showBackground={showBackground}>
      {showBlockHeaderLogo && showBlockHeader ? (
        <>
          <Ident height={40} />
          <BoxItem>
            <TheIcon icon="wcrown:40" size={40} />
          </BoxItem>
          <Ident height={8} />
          <TextTitle>
            <span data-blue>LEADERBOARD</span>
          </TextTitle>
          <Ident height={16} />
          <TextSubTitle>History</TextSubTitle>
          <Ident height={32} />
        </>
      ) : showBlockHeader ? (
        <>
          <Ident height={87} />
          <TextTitle>
            <span data-blue>LEADERBOARD</span>
          </TextTitle>
          <Ident height={16} />
          <TextSubTitle>History</TextSubTitle>
          <Ident height={32} />
        </>
      ) : null}
      {showSwitcher ? (
        <>
          <BoxItem>
            <SlideSwitcher title={title} value={switcher} onChange={setSwitcher} />
          </BoxItem>
          <Ident height={46} />
        </>
      ) : null}
      <BoxItem>
        {leaderboards?.length ? (
          <LeaderboardTopWinnerCardList leaderboards={leaderboards} doHref={doHref} />
        ) : (
          <NoEntitiesText>No leaderboard history</NoEntitiesText>
        )}
      </BoxItem>
      <Ident height={40} />
      {showPages && leaderboards?.length ? (
        <>
          <Ident height={72} />
          <BoxItem styles={cssList}>
            <Pages
              name="Items"
              range={range}
              skip={skip}
              take={take}
              total={total}
              showInfo={false}
              onClick={onPage}
            />
          </BoxItem>
          <Ident height={101} />
        </>
      ) : null}
      {showViewHistoryButton ? (
        <>
          <Ident height={56} />
          <Flex width="100%" justify="center">
            <Button
              isDark
              style={{ height: 50, width: 150, whiteSpace: "nowrap" }}
              onClick={(event: React.MouseEvent) => {
                event.preventDefault();
                router.push("/leaderboards/history");
              }}
            >
              <ButtonBlue isDark style={{ whiteSpace: "nowrap" }} transparent>
                View History
              </ButtonBlue>
            </Button>
          </Flex>
          <Ident height={77} />
        </>
      ) : null}
    </Box>
  );
};

const cssList = css`
  max-width: 1007px;
  align-items: left;
  margin: 0 auto;
`;

const Box = styled.div<IStyles & { casinoProvider?: string; showBackground?: boolean }>`
  box-sizing: border-box;
  ${({ casinoProvider, showBackground }) =>
    showBackground && casinoProvider && casinoProviderBackgrounds[casinoProvider]}
  background-repeat: no-repeat;
  ${({ styles }) => styles}
`;

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  text-align: center;
  ${({ styles }) => styles}
`;

const Text = styled.div`
  ${$stylesReset}
  color: rgba(210, 234, 255, 0.75);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: center;
`;

const TextSubTitle = styled(Text)`
  color: rgb(210, 234, 255); // #d2eaff;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  @media (max-width: 800px) {
    color: #d2eaff;
    font-size: 28px;

  }
`;

const TextTitle = styled(Text)`
  color: rgb(210, 234, 255); // #d2eaff;
  font-size: 40px;
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  & span {
    background: linear-gradient(
      88.51deg,
      #2b7ae2 -11.93%,
      #4fc9f4 32.84%,
      #87e9ff 44.22%,
      #4fd1f4 55.23%,
      #2884e0 100%
    );
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media (max-width: 800px) {
    color: #d2eaff;
    font-size: 28px;

  }
`;
