import React, { useEffect } from "react";
import { ExchangeRateDto, IUserBalanceUpdate, IUserDto } from "interfaces";
import { AppContext, TAppAccessURL } from "./AppContext";
import { useRouter } from "next/router";
import { triggerNotification } from "components/TheComponents/Notification";
import { socket } from "socket";
import { Raffle } from "types/raffles";
import { Stream } from "helpers/types";
import { ADMIN_ROLES, SocketEvent } from "helpers/constants";
import { useDisclosure } from "@chakra-ui/react";
import useCurrentUser, { useUserClientActions } from "hooks/user/useCurrentUser";
import { ExceptionHandlingType } from "types/apiServiceTypes";
import useFeatureFlags from "hooks/featureFlag/useFeatureFlags";
import apiService from "services/apiService";

interface IAppProviderProps {
  children?: React.ReactNode;
}

export const AppProvider: React.FC<IAppProviderProps> = ({ children }): JSX.Element => {
  const router = useRouter();

  const { logout } = useUserClientActions();
  const { isOpen: isProfileOpen, onOpen: onOpenProfile, onClose: onCloseProfile } = useDisclosure();
  const { isOpen: isLoginOpen, onOpen: onOpenLogin, onClose: onCloseLogin } = useDisclosure();
  const { data: featureFlags } = useFeatureFlags();

  const [showQrModal, setShowQrModal] = React.useState<boolean>(false);

  useEffect(() => {
    apiService.init(logout);
    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, [logout]);

  const { data: profile, isFetching: isLoading, refetch } = useCurrentUser();

  const [userBalance, setUserBalance] = React.useState<
    Pick<IUserDto, "gPointsBalance" | "pointsBalance">
  >({});

  const [newRaffle, setNewRaffle] = React.useState<Raffle | null>(null);
  const [endingRaffle, setEndingRaffle] = React.useState<Raffle | null>(null);
  const [liveStreams, setLiveStreams] = React.useState<Stream[]>([]);
  const [rates, setRates] = React.useState<ExchangeRateDto | null>(null);

  const updateBalance = React.useCallback(async (balance: IUserBalanceUpdate) => {
    if (balance?.balanceType === "G_POINT") {
      setUserBalance((oldBalance) => ({
        ...(oldBalance! || {}),
        gPointsBalance: balance.balance,
      }));
    }
    if (balance?.balanceType === "POINTS") {
      setUserBalance((oldBalance) => ({
        ...(oldBalance! || {}),
        pointsBalance: balance.balance,
      }));
    }
  }, []);

  React.useEffect(() => {
    socket.on(SocketEvent.RaffleEnded, (data) => {
      triggerNotification({
        text: `Raffle ${data?.title} ended`,
        type: "info",
      });
    });

    socket.on(SocketEvent.StreamsStatusesCheck, (data) => {
      const onlineStreams = data?.filter((el: Stream) => el.isLive === true);
      setLiveStreams(onlineStreams);
    });

    socket.on(SocketEvent.NewRaffle, (data) => {
      setNewRaffle(data);
    });

    socket.on(SocketEvent.BalanceUpdate, (data) => {
      updateBalance(data);
    });

    socket.on(SocketEvent.RaffleRouletteStarts, (data) => {
      triggerNotification({
        text: `Raffle #${data?.id} ${data?.title} Roulette Starts`,
        type: "raffleRoulette",
        raffleData: { id: data?.id, title: data?.title },
      });
    });

    socket.on(SocketEvent.RaffleEndsSoon, (data) => {
      setEndingRaffle(data);
      triggerNotification({
        type: "timer",
        timerData: data,
      });
    });
  }, []);

  const isAdmin = profile?.roles?.some((userRole) =>
    ADMIN_ROLES.some((adminRole) => adminRole.name === userRole.name)
  );

  // TODO globally removing step by step
  // Using useCallback to memoize accessFetch and accessURL
  const accessFetch = React.useCallback(
    (
      input: string | URL,
      init?: RequestInit,
      exceptionHandlingType: ExceptionHandlingType = ExceptionHandlingType.MANUAL
    ) => apiService.request(input, init, exceptionHandlingType),
    []
  );
  const accessURL = React.useCallback(appAccessURL(), []);

  React.useEffect(() => {
    const getStreamsStatuses = async () => {
      const streamsResponse = await accessFetch(
        `/stats/streams-statuses`,
        undefined,
        ExceptionHandlingType.SILENT
      );
      const streamsData = await streamsResponse?.json();
      const onlineStreams = streamsData?.filter((el: Stream) => el.isLive === true);
      setLiveStreams(onlineStreams);
    };

    getStreamsStatuses();
  }, [accessFetch]);

  const onDiscordRedirect = React.useMemo(() => {
    return async () => {
      try {
        const url = accessURL(`/auth/discord`) as URL;
        router.push(url.href);
      } catch (error) {
        console.log({ error });
      }
    };
  }, [accessURL, router]);

  // Fetch and subscribe on exchange rate
  const fetchRate = React.useCallback(async () => {
    const url = accessURL(`/balance/rates`) as URL;
    const response = await accessFetch(url.href, undefined, ExceptionHandlingType.SILENT);
    const data = await response?.json();
    setRates(data as ExchangeRateDto);
  }, [accessFetch, accessURL]);

  React.useEffect(() => {
    fetchRate();
    socket.on(SocketEvent.RatesUpdate, setRates);
  }, [fetchRate]);

  const isFeatureEnabled = (code: string) => {
    return featureFlags?.find?.((flag) => flag.code === code)?.enabled ?? false;
  };

  return (
    <AppContext.Provider
      value={{
        apiService: apiService,
        accessFetch,
        accessURL,
        showQrModal,
        setShowQrModal,
        profile: profile ? { ...profile, ...userBalance } : null,
        featureFlags,
        setRates,
        rates,
        isAdmin,
        liveStreams,
        newRaffle,
        profileProps: { isProfileOpen, onOpenProfile, onCloseProfile },
        loginModalProps: { isLoginOpen, onOpenLogin, onCloseLogin },
        endingRaffle,
        onDiscordRedirect,
        isFeatureEnabled,
        isProfileLoading: isLoading,
        refetchProfile: refetch,
        setUserBalance,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export function useAppContext() {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within a AppProvider");
  }
  return context;
}

/**
 * Create accessURL binded to domain (from env.NEXT_PUBLIC_APP_BE_ORIGIN)
 * if not one is present in input value
 *
 * @returns TAppAccessURL
 *
 */
function appAccessURL(): TAppAccessURL {
  const urlOrigin: string = process.env.NEXT_PUBLIC_APP_BE_ORIGIN ?? "http://localhost:4000";
  return (input: string, origin: string = urlOrigin): URL => {
    const url = new URL(input, origin);
    return url;
  };
}
