import React from "react";

import { useAppContext } from "contexts";
import { IDtoLeaderboardUser, TheLeaderboardSourceKeys } from "interfaces";
import { useQuery } from "@tanstack/react-query";

interface useLeaderboardUsersParams {
  casinoProvider?: string;
  leaderboardId?: string;
  take?: number;
}

export default function useLeaderboardUsers({
  casinoProvider,
  leaderboardId,
  take = 10,
}: useLeaderboardUsersParams) {
  const { accessFetch } = useAppContext();

  return useQuery<IDtoLeaderboardUser[]>({
    queryKey: ["leaderboard-users", { leaderboardId, take, casinoProvider }],
    queryFn: async () => {
      const sort =
        casinoProvider === TheLeaderboardSourceKeys.hypedrop
          ? "deposited:desc"
          : "wageredTotal:desc";

      const idsUrl = `/leaderboard-user/${leaderboardId}/public?sort=${sort}&skip=${(0).toString()}&take=${take}`;
      const activeUrl = `/leaderboard-user/active/${casinoProvider}/public?sort=${sort}&skip=${(0).toString()}&take=${take}`;

      const usersResponse = await accessFetch(leaderboardId ? idsUrl : activeUrl);

      const usersData = await usersResponse?.json();

      return usersData?.items ?? [];
    },
    retry: false,
    enabled: !!leaderboardId || !!casinoProvider,
    placeholderData: (previousData) => previousData,
    initialData: [],
  });
}
