export type TTheLeaderboardSourceKeys = "GAMDOM" | "HYPEDROP" | "PACKDRAW" | "WREWARDS" | "CLASH" | "CASES";

export const TheLeaderboardSourceKeys: {
  [key: string]: TTheLeaderboardSourceKeys;
} = {
  gamdom: "GAMDOM",
  hypedrop: "HYPEDROP",
  packdraw: "PACKDRAW",
  wrewards: "WREWARDS",
  clash: "CLASH",
  cases: "CASES",
};
