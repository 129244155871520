import Link from "next/link";
import styled from "styled-components";

export const Root = styled.div`
  position: relative;

  @media (max-width: 1280px) {
    display: flex;
    flex-direction: column;
  }
`;

export const NavItem = styled(Link)<{ selected: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  background: var(--top-menu-bg);
  gap: 8px;
  cursor: pointer;

  &:hover {
    background: #293049;
  }

  ${({ selected }) =>
    selected
      ? `
        border-radius: 6px 6px 0px 0px;
        background: #293049;
        `
      : "border-radius: 6px;"}

  text-wrap: nowrap;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #e9f4ff;

  &[data-mobile-only="true"] {
    display: none;
  }

  &[data-active="true"] {
    color: #3b8be9;
  }

  & path {
    ${({ selected }) => (selected ? `fill: #3B8BE9;` : `fill: #E9F4FFBF; opacity: 0.5;`)}
  }

  @media (max-width: 1280px) {
    &[data-mobile-only="true"] {
      display: flex;
    }
    &[data-desktop-only="true"] {
      display: none;
    }
  }
`;

export const DropDown = styled.div<{ show?: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  @media (max-width: 1280px) {
    position: static;
  }

  & ${NavItem} {
    background: #1d2234;
    border: 1px solid #e9f4ff0d;
    border-radius: 0;

    &:hover {
      background: #293049;
    }
  }

  ${({ show }) =>
    show
      ? `
      max-height: 100vh;
      transition: max-height 0.25s ease-in;
        `
      : `
          max-height: 0;
          transition: max-height 0.15s ease-out;
          overflow: hidden;  
    `}

  @media (max-width: 1280px) {
    flex-basis: 0;
    overflow: hidden;
    transition: all 0.5s ease;
    & {
      ${({ show }) =>
        show
          ? `
            flex: 1;
            display: flex;
            width: 100%;
            & > div {
            width: 100%;
            }
            `
          : ``}
    }
  }
`;
