import React from "react";
import styled, { css } from "styled-components";
import { $stylesReset, IStyles } from "./$styles";
import { fixed } from "helpers";

export interface ITotalGivenCounter {
  length?: number;
  value?: number;
}

export interface ITotalGivenCounterItem {
  isDelimiter?: boolean;
  isGreat?: boolean;
  value?: string;
}

export const TotalGivenCounter: React.FC<ITotalGivenCounter & IStyles> = ({
  length = 1,
  value = 0,
  styles,
}): JSX.Element => {
  const items: ITotalGivenCounterItem[] = React.useMemo(() => {
    const format = "0,0";
    const formated = fixed(format, value, length);

    const stringWithoutLeadingZeros = formated.replace(/^0+/, "");

    const normalAmount = stringWithoutLeadingZeros.length;

    const values = formated.split("").reverse();

    return values
      .map((value: string, index: number, items: string[]): ITotalGivenCounterItem => {
        if (!Number.isInteger(+value)) {
          return {};
        }
        const itemPrev = index < items.length - 1 ? +items[index + 1] : 0;
        const isDelimiter = !Number.isInteger(itemPrev);
        const isGreat = normalAmount <= index;
        return { isDelimiter, isGreat, value };
      })
      .filter((item: ITotalGivenCounterItem) => {
        return item?.value;
      })
      .reverse();
  }, [length, value]);

  return (
    <Box styles={styles}>
      <Border>
        <List>
          <DigitUnit>$</DigitUnit>
          {items
            .filter((item) => {
              return item?.value;
            })
            .map(({ isGreat, isDelimiter, value = "" }, index) => (
              <DigitValue key={index} isGreat={isGreat} isDelimiter={isDelimiter}>
                {value}
              </DigitValue>
            ))}
        </List>
      </Border>
    </Box>
  );
};

const Box = styled.div<IStyles>`
  ${$stylesReset}
  animation: float ease 10s infinite;
  ${({ styles }) => styles}
`;

const Border = styled.div`
  ${$stylesReset}
  display: inline-block;
  background-image: linear-gradient(0deg, #3b8be9 0%, #1c2536 100%);
  border-radius: 8px;
`;

const List = styled.div`
  display: inline-flex;
  gap: 1px;
  margin: 1px;
  padding: 24px;
  overflow: hidden;
  background: linear-gradient(0deg, #101622, #101622),
    radial-gradient(
      152.76% 130.7% at 50% 0%,
      rgba(101, 101, 101, 0.05) 0%,
      rgba(101, 101, 101, 0) 100%
    ),
    linear-gradient(0deg, #3b8be9 0%, #1c2536 100%);
  border-radius: 8px;
`;

const Digit = styled.div`
  --height: 120px;
  --width: 120px;
  ${$stylesReset}
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: var(--width);
  height: var(--height);
  padding: 16px;
  box-sizing: border-box;
  font-weight: 500;
  line-height: 30px;
  text-transform: uppercase;
  @media (max-width: 1600px) {
    --height: 48px;
    --width: 40px;
  }
`;

const DigitUnit = styled(Digit)`
  font-family: Rubik;
  font-style: normal;
  font-size: 96px;

  background: linear-gradient(265.52deg, #4796f2 -1.78%, #47d3f2 85.44%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-shadow: 0px 0px 48px rgba(40, 113, 255, 0.5), 0px 0px 5px rgba(58, 113, 254, 0.46), 0px 2px 4px #010103; */

  @media (max-width: 1600px) {
    font-size: 40px;
  }
`;

const DigitValue = styled(Digit)<ITotalGivenCounterItem>`
  color: #d2eaff;
  font-family: Rubik;
  font-size: 64px;

  /* background: radial-gradient(50% 50% at 50% 50%, rgba(101, 101, 101, 0.05), rgba(101, 101, 101, 0)), #101622; */
  background: linear-gradient(
      rgba(13, 21, 40, 0.03),
      rgba(135, 171, 250, 0.04) 50.52%,
      rgba(17, 32, 65, 0.04)
    ),
    rgb(11, 16, 27);
  color: rgb(210, 234, 255);
  text-shadow: rgba(40, 113, 255, 0.5) 0px 0px 24px, rgba(58, 113, 254, 0.46) 0px 0px 6px,
    rgb(11, 14, 35) 0px 2px 4px;

  &:first-child {
    border-radius: 0 8px 8px 0;
  }
  &:last-child {
    border-radius: 8px 0 0 8px;
  }
  &:not(:first-child) {
    border-right: 1px solid rgba(27, 32, 53, 1);
  }

  ${({ isGreat }) =>
    isGreat
      ? css`
          color: rgba(210, 234, 255, 0.25);
        `
      : null}

  ${({ isDelimiter }) =>
    isDelimiter
      ? css`
          &::before {
            content: ",";
            position: absolute;
            left: -0.2em;
            margin-bottom: -0.4em;
            z-index: 1;
          }
        `
      : null}

	@media (max-width: 1600px) {
    font-size: 20px;
  }
`;
