import Link from "next/link";
import { PulsingIndicator } from "components/common";
import { Stream } from "helpers/types";
import {
  HyusLinks,
  WatchGamesTVLinks,
  HYUS_USERNAME,
  WATCH_GAMES_USERNAME,
  PKLE_USERNAME,
  PkleLinks,
  WREWARDS_USERNAME,
  WRewardsLinks,
} from "helpers/constants";
import { Card } from "./styles";

const StreamIndicator: React.FC<{ liveStreams: Stream[] }> = ({ liveStreams }) => {
  const isStream = !!liveStreams?.length;

  const watchgamestvLiveStream = liveStreams?.find(
    (el: Stream) => el.isLive === true && el.streamer === WATCH_GAMES_USERNAME
  );

  const hyusLiveStream = liveStreams?.find(
    (el: Stream) => el.isLive === true && el.streamer === HYUS_USERNAME
  );

  const wrewardsLiveStream = liveStreams?.find(
    (el: Stream) => el.isLive === true && el.streamer === WREWARDS_USERNAME
  );

  const pkleLiveStream = liveStreams?.find(
    (el: Stream) => el.isLive === true && el.streamer === PKLE_USERNAME
  );

  const linkToLiveStream =
    (watchgamestvLiveStream && WatchGamesTVLinks[watchgamestvLiveStream.platform]) ||
    (wrewardsLiveStream && WRewardsLinks[wrewardsLiveStream.platform]) ||
    (hyusLiveStream && HyusLinks[hyusLiveStream.platform]) ||
    (pkleLiveStream && PkleLinks[pkleLiveStream.platform]) ||
    "/";

  const linkTarget = linkToLiveStream !== "/" ? "_blank" : "_self";
  return (
    <Link target={linkTarget} href={linkToLiveStream}>
      <Card live={isStream}>
        <PulsingIndicator live={isStream} />
        {isStream ? <div>Live</div> : null}
      </Card>
    </Link>
  );
};

export default StreamIndicator;
