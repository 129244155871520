import { FooterComponent } from "./FooterComponent";
import styled from "styled-components";
import NavSidebar from "./NavSidebar";
import { useShowPageLayoutContext } from "contexts/ShowPageLayoutContext";
import Backdrop from "./Backdrop";
import { useMediaQuery } from "react-responsive";
import ChatSidebar from "./ChatSidebar";
import dynamic from "next/dynamic";
import Navigation from "./HeaderComponent/components/Navigation";
import { useEffect, useState } from "react";

const HeaderComponent = dynamic(() => import("layout/HeaderComponent"), {
  ssr: false,
});

const PageLayout = ({ children }: any) => {
  const isMobile = useMediaQuery({
    query: "(max-width: 1399px)",
  });

  const { showNavSidebar } = useShowPageLayoutContext();
  const { showChatSidebar } = useShowPageLayoutContext();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsLoaded(true), 600);
  }, []);

  return (
    <Page style={isLoaded ? { opacity: 1 } : { opacity: 0 }}>
      <HeaderComponent id="Header" />

      <PageContent>
        <Navigation />
        <ContentWrapper>
          <CurrentContentWrapper>{children}</CurrentContentWrapper>
          <FooterComponent id="Footer" />
        </ContentWrapper>
      </PageContent>

      <Backdrop show={(showNavSidebar || showChatSidebar) && isMobile} />

      <ChatSidebar show={showChatSidebar} />
    </Page>
  );
};

const Page = styled.div`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  transition: opacity 0.4s;
  opacity: 0;
`;

const PageContent = styled.div`
  display: flex;
  width: 100%;
`;

const CurrentContentWrapper = styled.div`
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  width: calc(100% - var(--sidebar-width));
  position: relative;
  left: var(--sidebar-width);
  padding-top: var(--header-height);
  @media (max-width: 1400px) {
    width: 100%;
    left: 0px;
    position: relative;
    height: auto;
    overflow: hidden;
  }
`;

export default PageLayout;
