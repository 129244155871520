import React from "react";
import styled from "styled-components";
import { $stylesReset } from "components";
import TheLiveStream from "components/hunt-tracker/LiveStream/TheLiveStream";
import { MainSection } from "components/pachinko/Main";
import { Prizes } from "components/pachinko/Prizes";
import TopTenTableComponent from "components/pachinko/Table/TopTenTableComponent";
import { Fade } from "react-awesome-reveal";

export interface IPachinkoComponent {
  id?: string;
  mainOnly?: boolean;
}

const Box = styled.div`
  ${$stylesReset}
  overflow:hidden;
  background-color: #0b0e16;
`;

export const PachinkoComponent: React.FC<IPachinkoComponent> = ({ id, mainOnly }): JSX.Element => {
  return (
    <Box id={id}>
      <MainSection mainOnly={mainOnly} />
      {!mainOnly && (
        <>
          <Prizes />
          <Fade triggerOnce>
            <TopTenTableComponent />
          </Fade>
          <Fade triggerOnce>
            <TheLiveStream />
          </Fade>
        </>
      )}
    </Box>
  );
};
