import Image from "next/image";
import React from "react";
import styled, { css, keyframes } from "styled-components";
import {
  $stylesReset,
  IStyles,
  Hr,
  TheNextScroll,
  TotalGivenCounter,
  TotalGivenReward,
} from "components";
import { IAppContext, withApp } from "contexts";
import { socket } from "socket";
import { SocketEvent } from "helpers/constants";
import { Fade } from "react-awesome-reveal";
import { ExceptionHandlingType } from "types/apiServiceTypes";

export interface ITotalGivenAwayComponent {
  id?: string;
  nextScrollHref?: string;
}

interface StatsProps {
  leaderboards: number;
  total: number;
  pointsShop: number;
  raffles: number;
  pachinko: number;
}
const TotalGivenAwayComponent: React.FC<ITotalGivenAwayComponent & IAppContext> = ({
  id,
  nextScrollHref = "#",
  accessFetch,
}): JSX.Element => {
  const [stats, setStats] = React.useState<StatsProps>({
    raffles: 0,
    pointsShop: 0,
    pachinko: 0,
    leaderboards: 0,
    total: 0,
  });
  const fetchData = React.useCallback(async () => {
    const response = await accessFetch("/stats", undefined, ExceptionHandlingType.SILENT);
    const data = await response?.json();
    setStats(data);
  }, [accessFetch]);

  React.useEffect(() => {
    socket.on(SocketEvent.DashboardStatsUpdate, (data) => {
      setStats(data);
    });

    return () => {
      socket.off(SocketEvent.DashboardStatsUpdate, () =>
        console.log("Not listening to DashboardStatsUpdate")
      );
    };
  }, []);

  React.useEffect(() => {
    fetchData(); // Initial data fetch
  }, [fetchData]);

  const styles = css`
    padding: 20px;
    gap: 12px;
    width: 100%;
    @media (max-width: 700px) {
      flex-directrion: column;
      flex-direction: column;
      height: 188px;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  `;

  const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

  const RotatingImage = styled.img`
    ${css`
      position: absolute;
      top: 0;
      animation: ${rotate360} infinite 40s linear;
    `}
  `;

  return (
    <Box id={id}>
      <Screen>
        <RotatingImage
          src="/images/TotalGivenAwayComponent/$bg.svg"
          width={1255 * 1.5}
          height={514 * 1.5}
          alt="bg"
        />
        <Content>
          <Img src="/images/icons/gift-box.svg" width={83} height={88} alt="gift" />
          <TextTitle>
            TOTAL &nbsp;
            <span data-blue>GIVEN AWAY</span>
          </TextTitle>
          <TextWGTV>
            <br />
            to WGTV COMUNITY
            <br />
            <br />
            <br />
          </TextWGTV>
          <TotalGivenCounter length={7} value={stats?.total} />
          <TotalRewards>
            <br />
            <Hr text="Different Rewards Stats" />
            <br />
            <TotalRewardList>
              <AnimatedScrollContainer delay={250} triggerOnce direction="up">
                <TotalGivenReward
                  styles={styles}
                  img={
                    <Img
                      src="/images/TotalGivenAwayComponent/Crown.png"
                      width={62}
                      height={62}
                      alt="crown"
                    />
                  }
                  title="Leaderboard"
                  value={stats?.leaderboards}
                />
              </AnimatedScrollContainer>

              <AnimatedScrollContainer delay={450} triggerOnce direction="up">
                <TotalGivenReward
                  styles={styles}
                  img={
                    <Img
                      src="/images/TotalGivenAwayComponent/Coin.png"
                      width={62}
                      height={62}
                      alt="coin"
                    />
                  }
                  title="Points Shop"
                  value={stats?.pointsShop}
                />
              </AnimatedScrollContainer>

              <AnimatedScrollContainer delay={550} triggerOnce direction="up">
                <TotalGivenReward
                  styles={styles}
                  img={
                    <Img
                      src="/images/TotalGivenAwayComponent/Tickets.png"
                      width={62}
                      height={62}
                      alt="tickets"
                    />
                  }
                  title="Raffles"
                  value={stats?.raffles}
                />
              </AnimatedScrollContainer>
              <AnimatedScrollContainer delay={600} triggerOnce direction="up">
                <TotalGivenReward
                  styles={styles}
                  img={
                    <Img
                      src="/images/TotalGivenAwayComponent/Crown.png"
                      width={62}
                      height={62}
                      alt="crown"
                    />
                  }
                  title="Pachinko"
                  value={stats?.pachinko}
                />
              </AnimatedScrollContainer>
            </TotalRewardList>
          </TotalRewards>
          <Next>
            <TheNextScroll href={nextScrollHref}>SPEND WGTV BALANCE</TheNextScroll>
          </Next>
        </Content>
      </Screen>
    </Box>
  );
};

export default withApp(TotalGivenAwayComponent);

const AnimatedScrollContainer = styled(Fade)`
  width: calc(48.5%);

  @media (max-width: 700px) {
    width: 100%;
  }

  @media (max-width: 400px) {
    width: 291px;
  }
`;

const Box = styled.div`
  ${$stylesReset}
  background-color: #0b0e16;
`;

const Screen = styled.div`
  ${$stylesReset}
  position: relative;
  max-width: 1920px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  overflow: hidden;
`;

const Content = styled.div`
  ${$stylesReset}
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 654px;
  margin: 24px;
  @media (min-width: 800px) {
    padding-bottom: 112px;
  }
`;

const TotalRewards = styled.div`
  ${$stylesReset}
  max-width: calc( 50% * 2 + 16px );
`;

const TotalRewardList = styled.div`
  ${$stylesReset}
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 16px;
  width: 100%;
`;

const Text = styled.div`
  ${$stylesReset}
  color: rgba(210, 234, 255, 0.75);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: center;
`;

const TextWGTV = styled(Text)`
  color: rgba(210, 234, 255, 0.5);
  font-size: 20px;
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.03em;
  text-transform: uppercase;
`;

const TextTitle = styled(Text)`
  color: rgb(210, 234, 255); // #d2eaff;
  font-size: 40px;
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  & [data-blue] {
    color: #3b8be9;
  }
  @media (max-width: 800px) {
    color: #d2eaff;
    font-size: 28px;
    /* line-height: 130%;
		letter-spacing: 0.84px; */
  }
`;

const Img = styled(Image)<IStyles>`
  ${({ styles }) => styles}
`;

const Next = styled(Text)`
  position: absolute;
  bottom: 0;
  width: 100%;
  @media (max-width: 800px) {
    display: none;
  }
`;
