import React from "react";
import styled, { css } from "styled-components";
import { $stylesReset, IStyles } from "./$styles";

export type TPageType = "first" | "prev" | "page" | "more" | "next" | "last";

export type TPageProps = (props: IPageProps) => Partial<IPageProps>;

export interface IPageProps extends IStyles {
  children?: React.ReactElement | string | number;
  index?: number;
  isCurrent?: boolean;
  isDisabled?: boolean;
  isType?: TPageType;
  title?: string;
  onClick?: (event: React.MouseEvent) => void;
}

export interface IPagesProps extends IStyles {
  name?: string;
  range?: number;
  skip?: number;
  take?: number;
  total?: number;
  showInfo?: boolean;
  onClick?: (index: number) => void;
}

/**
 * Create pagination block
 */
export const Pages: React.FC<IPagesProps> = ({
  name = "?",
  range = 3,
  skip = 0,
  take = 1,
  total = 0,
  showInfo = true,
  onClick,
  styles,
}): React.ReactElement => {
  const pageFirst = 0;
  const pageLast = Math.max(Math.ceil(total / take) - 1, pageFirst);
  const pageCurrent = Math.floor(skip / take);
  const pagePrev = Math.max(pageCurrent - 1, pageFirst);
  const pageNext = Math.min(pageCurrent + 1, pageLast);
  const pageTitle = (index: number) => `Page ${index + 1}`;

  const onPageClick = (index: number) => (event: React.MouseEvent) => {
    event.preventDefault();
    onClick && onClick(index);
  };

  const indexes: number[] = Array(2 * range + 1)
    .fill(0)
    .map((_, index: number) => index + pageCurrent - range)
    .filter((index: number) => pageFirst <= index && index <= pageLast);

  const pages: IPageProps[] = indexes.map((index: number) => ({
    index,
    isCurrent: pageCurrent === index,
    isType: "page",
    title: pageTitle(index),
    onClick: onPageClick(index),
  }));

  const prevProps: IPageProps | null =
    indexes.length > 0 && pagePrev < pageCurrent
      ? { isType: "prev", onClick: onPageClick(pagePrev) }
      : indexes.length > 0
        ? { isDisabled: true, isType: "prev" }
        : null;

  const nextProps: IPageProps | null =
    indexes.length > 0 && pageCurrent < pageNext
      ? { isType: "next", onClick: onPageClick(pageNext) }
      : indexes.length > 0
        ? { isDisabled: true, isType: "next" }
        : null;

  const firstProps: IPageProps | null =
    indexes.length > 0 && pageFirst < Math.min(...indexes)
      ? {
        isType: "first",
        title: pageTitle(pageFirst),
        onClick: onPageClick(pageFirst),
      }
      : null;

  const lastProps: IPageProps | null =
    indexes.length > 0 && Math.max(...indexes) < pageLast
      ? {
        isType: "last",
        title: pageTitle(pageLast),
        onClick: onPageClick(pageLast),
      }
      : null;

  const firstMoreProps: IPageProps | null =
    indexes.length > 0 && pageFirst + 1 < Math.min(...indexes)
      ? { isType: "more", title: "..." }
      : null;

  const lastMoreProps: IPageProps | null =
    indexes.length > 0 && Math.max(...indexes) < pageLast - 1
      ? { isType: "more", title: "..." }
      : null;

  /**
  * Calculate the number of items on the current page
  */
  const startIndex = skip;
  const endIndex = Math.min(skip + take, total);
  const currentPageItems = endIndex - startIndex;

  return (
    <Box styles={styles} style={{
      flexDirection: "column",
      alignItems: "center",
    }}>
      {showInfo ? (
        <Info>
          <Name>{name}</Name>
          <Total>{total}</Total>
        </Info>
      ) : null}
      <List>
        {prevProps && <Prev {...prevProps} style={{ transform: "rotate(-90deg)" }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12.8 9.31622C12.4338 8.89467 11.5688 8.89458 11.2024 9.31606L7.1471 13.9812C6.60184 14.6084 7.68812 15.3047 8.51484 14.8578L11.4332 13.2804C11.7694 13.0986 12.2358 13.0988 12.5718 13.2807L15.4845 14.8578C16.311 15.3053 17.3982 14.6092 16.853 13.9816L12.8 9.31622Z" fill="currentColor" />
          </svg>
        </Prev>}
        {firstProps && <Page {...firstProps}>{pageFirst + 1}</Page>}
        {firstMoreProps && <More {...firstMoreProps}>...</More>}
        {pages.map(({ index = 0, isCurrent, title, onClick }) => {
          const pageProps = { isCurrent, title, onClick };
          return (
            <Page key={index} {...pageProps}>
              {index + 1}
            </Page>
          );
        })}
        {lastMoreProps && <More {...lastMoreProps}>...</More>}
        {lastProps && <Page {...lastProps}>{pageLast + 1}</Page>}
        {nextProps && <Next {...nextProps} style={{ transform: "rotate(90deg)" }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12.8 9.31622C12.4338 8.89467 11.5688 8.89458 11.2024 9.31606L7.1471 13.9812C6.60184 14.6084 7.68812 15.3047 8.51484 14.8578L11.4332 13.2804C11.7694 13.0986 12.2358 13.0988 12.5718 13.2807L15.4845 14.8578C16.311 15.3053 17.3982 14.6092 16.853 13.9816L12.8 9.31622Z" fill="currentColor" />
          </svg>
        </Next>}
      </List>

      <p style={{
        color: "rgba(200, 215, 245, 0.50)",
        fontSize: "14px",
        fontWeight: "500"
      }}>
        {currentPageItems} of {total}
      </p>
    </Box>
  );
};

const Box = styled.div<IStyles>`
  ${$stylesReset}
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${({ styles }) => styles}
`;

const Info = styled.div<IStyles>`
  ${$stylesReset}
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  ${({ styles }) => styles}
`;

const Name = styled.div<IStyles>`
  ${$stylesReset}
  display: inline-block;
  color: rgba(210, 234, 255, 0.5);
  font-family: "Roboto";
  line-height: 16px;
  text-transform: uppercase;
  ${({ styles }) => styles}
`;

const Total = styled.div<IStyles>`
  ${$stylesReset}
  display: inline-block;
  color: rgba(210, 234, 255, 0.75);
  font-family: "Rubik";
  line-height: 30px;
  ${({ styles }) => styles}
`;

const List = styled.div<IStyles>`
  ${$stylesReset}
  flex-grow: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  gap: 4px;
  ${({ styles }) => styles}
`;

const Page = styled.div<IStyles & IPageProps>`
  ${$stylesReset}
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid transparent;
  border-radius: 6px;
  color: rgba(210, 234, 255, 0.75);
  font-family: "Rubik";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  cursor: pointer;

  svg {
    color: inherit;
    transition: color 0.2s;
  }
 
  ${({ isCurrent, isDisabled, onClick }) =>
    isCurrent && !isDisabled && onClick
      ? css`
          border-color: #3b8be9 !important;
        `
      : !isDisabled && onClick
        ? css`
            color: #d2eaff;
            
            &:hover {
              color: var(--pure-sky);
            }
        `
        : isDisabled ? css`
            cursor: not-allowed;
            opacity: 0.5;
        ` : null}
        
  ${({ styles }) => styles}
`;

const Prev = styled(Page)`
  margin-right: 11px;
  width: 24px;
  height: 24px;
  background-color: rgba(35, 163, 255, 0.05);
  border-radius: 8px;
  color: rgba(210, 234, 255, 0.5);
`;

const More = styled(Page)`
  color: rgba(210, 234, 255, 0.5);
`;

const Next = styled(Page) <IPageProps>`
  margin-left: 11px;
  width: 24px;
  height: 24px;
  background-color: rgba(35, 163, 255, 0.05);
  border-radius: 8px;
  color: rgba(210, 234, 255, 0.5);
`;
