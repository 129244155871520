import React from "react";
import styled from "styled-components";
import Image from "next/image";
import { IDtoLeaderboard } from "interfaces";
import LeaderboardRandomWinnerCard from "./LeaderboardRandomWinnerCard";
import { Fade } from "react-awesome-reveal";
import { formatNumber } from "./utils/formatNumbers";
import useLeaderboardRandomWinners from "./hooks/useLeaderboardRandomWinners";
import { AnimatedNumber } from "./LeaderboardWinnersBlock";

interface LeaderboardRandomWinnersDisplayProps {
  leaderboardId: string;
  leaderboard?: IDtoLeaderboard;
}

const calculateRandomPrizeSum = (leaderboard: IDtoLeaderboard): number => {
  return leaderboard.randomPrizes.reduce((sum, value) => sum + value.amount, 0);
};

const defaultTakeStep = 5;

const LeaderboardRandomWinnersDisplay: React.FC<LeaderboardRandomWinnersDisplayProps> = ({
  leaderboardId,
  leaderboard,
}) => {
  const [skip, _] = React.useState(0);
  const [take, setTake] = React.useState(defaultTakeStep);

  const {
    data: { items: randomWinners, total },
  } = useLeaderboardRandomWinners({ leaderboardId, take, skip });

  const onSeeMore = () => {
    setTake(take + defaultTakeStep);
  };

  const collapseRandomWinners = () => {
    setTake(defaultTakeStep);
  };

  const isAllWinnersShown = take > total;

  return (
    <ContainerFlex>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Image
          style={{ marginTop: 2 }}
          src="/images/leaderboard/leaderboard-clever.svg"
          height={32}
          width={32}
          alt="image"
        />
        {leaderboard ? (
          <Fade direction="left" delay={130} triggerOnce>
            <TotalPrize>
              <span>$</span>
              <AnimatedNumber number={calculateRandomPrizeSum(leaderboard)}/>
              {/* {formatNumber()} */}
            </TotalPrize>
            <Subtitle>{`${leaderboard.randomPrizes.length} Random winners`}</Subtitle>
          </Fade>
        ) : null}
      </div>
      <AdaptiveGrid>
        {randomWinners?.map(({ prizeNumber, amount, id, leaderboardUser }) => (
          <LeaderboardRandomWinnerCard
            key={id}
            prizeNumber={prizeNumber}
            prize={amount}
            displayName={leaderboardUser?.displayName}
          />
        ))}
      </AdaptiveGrid>
      {total > 6 ? (
        <div>
          <ShowMoreButton
            onClick={() => {
              if (isAllWinnersShown) {
                collapseRandomWinners();
              } else {
                onSeeMore();
              }
            }}
          >
            <Image
              style={{ marginRight: 8 }}
              src="/images/LeaderboardComponent/eye.png"
              alt="plus"
              width={20}
              height={20}
            />
            <span>{isAllWinnersShown ? "Hide" : "Show More"}</span>
          </ShowMoreButton>
        </div>
      ) : null}
    </ContainerFlex>
  );
};

export default LeaderboardRandomWinnersDisplay;

export const ShowMoreButton = styled.button`
  border: 1px solid rgba(36, 39, 70, 0.5);
  border-radius: 8px;
  cursor: pointer;
  background: rgba(36, 39, 70, 0.5);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  min-width: 150px;
  gap: 6px;

  color: rgba(210, 234, 255, 0.75);

  span {
    flex-grow: 1;
    text-align: center;
  }
`;

const ContainerFlex = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1008px;
  gap: 40px;
  margin: 80px auto;
  justify-content: center;
  align-items: center;
`;

const AdaptiveGrid = styled.div`
  width: 100%;
  display: grid;
  gap: 56px 16px;
  min-width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(170px, calc((1008px / 5) - 16px)));
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  color: rgba(210, 234, 255, 0.75);
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: center;
`;

const Subtitle = styled(Text)`
  text-transform: uppercase;
  font-size: 14px;
`;

const TotalPrize = styled(Text)`
  font-size: 6rem;
  font-style: italic;
  color: #e9f4ff;

  span {
    background: linear-gradient(
      88.51deg,
      #2b7ae2 -11.93%,
      #4fc9f4 32.84%,
      #87e9ff 44.22%,
      #4fd1f4 55.23%,
      #2884e0 100%
    );

    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;
