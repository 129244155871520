import React from "react";
import { $styles, $stylesReset, Flex, IStyles } from "components/common";
import styled, { css } from "styled-components";
import { LeaderboardRandomWinnersPreviewProps } from "./LeaderboardRandomWinnersPreview";

const LeaderboardLearnMoreLayout: React.FC<
  LeaderboardRandomWinnersPreviewProps & { isHypedrop: boolean }
> = ({
  randomPrizeThreshold,
  randomWinnersCount,
  additionalPrizesCount,
  randomPrizes,
  isHypedrop,
}): React.ReactElement => {
  //

  return (
    <Flex justify="center" column>
      <Text
        styles={css`
          margin: 10px 0px 20px;
        `}
      >
        Join our monthly leaderboard and even if you are not qualified in the top{" "}
        {additionalPrizesCount} prizes you still have a chance to win by{" "}
        {isHypedrop ? "depositing" : "wagering"} minimum amount of ${randomPrizeThreshold}.
        <br />
        <br />
        And in the end of the month we gonna pick {randomWinnersCount}{" "}
        {randomWinnersCount === 1 ? "user" : "users"} to win such prizes.
      </Text>
      <Flex column width="100%" justify="center" align="center">
        {randomPrizes?.map((el: any, idx: number) => (
          <Card key={idx}>
            <PrizeNumber>Prize {el.prizeNumber}</PrizeNumber>
            <Amount>
              <span style={{ color: "rgba(94, 189, 61, 1)" }}>$</span>
              {el.amount}
            </Amount>
          </Card>
        ))}
      </Flex>
    </Flex>
  );
};

export default LeaderboardLearnMoreLayout;

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  gap: 8px;
  width: 90%;
  border: 1px #1b2136 solid;
  margin-top: 10px;

  background: radial-gradient(
      152.76% 130.7% at 50% 0%,
      rgba(101, 101, 101, 0.05) 0%,
      rgba(101, 101, 101, 0) 100%
    ),
    #151c2c;
  border-radius: 8px;
`;
const PrizeNumber = styled.div`
  font-family: Roboto;
  font-size: 18px;
  font-style: italic;
  color: white;
  font-weight: 800;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: left;
`;
const Amount = styled.div`
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
`;

const Text = styled.div<IStyles>`
  ${$stylesReset}
  display: inline-block;
  color: #a2b5c8;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: left;

  ${$styles}
`;
