import React from "react";
import styled, { css } from "styled-components";
import { Hr, Ident, IStyles } from "components";
import Countdown, { zeroPad } from "react-countdown";

export interface ILeaderboardEndsIn {
  title?: string;
  value: Date | string | null;
}

export const LeaderboardEndsIn: React.FC<ILeaderboardEndsIn> = ({
  title = "Leaderboard ends in",
  value,
}): React.ReactElement => {
  const givenDate = value?.toString() || "";

  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
  }: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }) => {
    return (
      <EndsItems>
        {[days, hours, minutes, seconds].map((el, index) => (
          <EndsItem key={index} style={{ whiteSpace: "nowrap" }}>
            <div data-value>{el === days ? el : zeroPad(el)}</div>
            <div>{Object.keys({ days, hours, minutes, seconds })[index]}</div>
          </EndsItem>
        ))}
      </EndsItems>
    );
  };

  return (
    <Box>
      <BoxItem
        styles={css`
          display: block;
        `}
      >
        <Ident height={16} />
        <Hr
          text={title}
          styles={css`
            font-family: "Golos Text";
            text-transform: uppercase;
          `}
        />
        <Ident height={16} />
      </BoxItem>
      <BoxItem>
        <Countdown date={givenDate} renderer={renderer} />
      </BoxItem>
    </Box>
  );
};

const Box = styled.div<IStyles>`
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
  ${({ styles }) => styles}
`;

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  ${({ styles }) => styles}
`;

const EndsItems = styled.div<IStyles>`
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  @media (max-width: calc( 480px - 1px )) {
    gap: 8px;
  }
  ${({ styles }) => styles}
`;

const EndsItem = styled.div<IStyles>`
  --size: 100px;
  --gap: 22px;
  box-sizing: border-box;
  display: inline-flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  /* gap: var(--gap); */
  width: var(--size);
  height: var(--size);
  padding: 16px var(--gap);
  text-transform: uppercase;

  //
  background: rgba(36, 39, 70, 0.25);
  border-radius: 8px;

  // text
  color: rgba(210, 234, 255, 0.5);
  font-family: "Golos Text";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: center;

  & [data-value] {
    color: #d2eaff;
    /* font-family: "Rubik"; */
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 47px;
  }

  & > div:last-child {
    padding-top: 0.5rem;
    border-top: 1px solid rgba(233, 244, 255, 0.2);
    width: 100%;
  }
  @media (max-width: calc( 480px - 1px )) {
    --size: 6.25em;
    --gap: 1em;
    font-size: 12px;
    & [data-value] {
      font-size: 2.5em;
    }
  }
  ${({ styles }) => styles}
`;
