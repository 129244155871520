import { IStyles } from "components/common";
import Image from "next/image";
import styled from "styled-components";
import { Slide as UISlide } from "@chakra-ui/react";
import { DesktopView, MobileView } from "./components/HeaderProfile/styles";

export const Root = styled.div`
  position: relative;
`;

export const Slide = styled(UISlide)<{ in: boolean }>`
  z-index: 1000 !important;
  top: var(--header-height) !important;
  @media (min-width: 1540px) {
    display: none;
  }

  ${({ in: isOpen }) => (isOpen ? "" : "display: none;")}
`;

export const Header = styled.div<{ fixed: boolean }>`
  ${({ fixed }) => (fixed ? "position: fixed;" : "position: relative;")}
  z-index: 12;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  background-color: var(--global-bg-dark-2);
  width: 100%;
  height: var(--header-height);
  padding: 12px 24px;
  left: 0;

  @media (max-width: 1540px) {
    justify-content: center;
  }

  // toggle
  & input[data-toggle] {
    display: none;
  }
  & input[data-toggle]:checked + label[data-button-container] > div[data-button]::before {
    margin-top: 0px;
    transform: rotate(405deg);
  }
  & input[data-toggle]:checked + label[data-button-container] > div[data-button] {
    background: transparent;
  }
  & input[data-toggle]:checked + label[data-button-container] > div[data-button]::after {
    margin-top: 0px;
    transform: rotate(-405deg);
  }
  & label[data-button-container] {
    position: relative;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    padding: 8px;
    cursor: pointer;
  }
  & div[data-button],
  & div[data-button]::after,
  & div[data-button]::before {
    display: block;
    position: absolute;
    height: 3.84px;
    width: 24px;
    border-radius: 2px;
    transition: transform 400ms cubic-bezier(1, 0, 0, 1); // cubic-bezier(0.23, 1, 0.32, 1)
    background-color: rgba(210, 234, 255, 0.75);
  }
  & div[data-button]::before {
    content: "";
    margin-top: calc(-2 * 3.84px);
  }
  & div[data-button]::after {
    content: "";
    margin-top: calc(2 * 3.84px);
  }
  @media (max-width: 1540px) {
    & label[data-button-container] {
      display: flex;
    }
    & input[data-toggle] ~ ul[data-list] {
      background-color: transparent;
      height: 0;
      min-height: unset;
      transition-delay: 0;
      transition-duration: 400ms, 400ms, 400ms;
      transition-property: background-color, height, min-height;
      transition-timing-function: cubic-bezier(0.5, 0, 0.5, 1), cubic-bezier(0.5, 0, 0.5, 1),
        step-start; // cubic-bezier(0.42, 0, 0.58, 1), cubic-bezier(0.23, 1, 0.32, 1), step-end;
    }
    & input[data-toggle]:checked ~ label[data-button-container] {
      // background: rgba(35, 163, 255, 0.05);
      border-radius: 8px;
    }
    /* & input[data-toggle]:checked ~ label[data-button-container] > div[data-button], */
    & input[data-toggle]:checked ~ label[data-button-container] > div[data-button]::after,
    & input[data-toggle]:checked ~ label[data-button-container] > div[data-button]::before {
      background-color: #3b8be9;
    }
    & input[data-toggle]:checked ~ ul[data-list] {
      background-color: var(--top-menu-bg-burger);
      height: calc(100% - var(--top-menu-nav-height));
      min-height: calc(100vh - var(--top-menu-nav-height));
      transition-timing-function: cubic-bezier(0.5, 0, 0.5, 1), cubic-bezier(0.5, 0, 0.5, 1),
        step-end;
    }
  }
`;
interface NavSidebarProps {
  show: boolean;
}
export const Nav = styled.aside<NavSidebarProps>`
  display: flex;
  flex-direction: column;
  padding: 16px;
  height: 100%;
  top: calc(var(--header-height) + 1px);

  background-color: var(--global-bg-dark-1);
  border-top: 1px solid var(--global-bg-dark);
  position: fixed;
  min-width: var(--sidebar-width);
  left: 0;
  z-index: 7;
  transform: ${({ show }) => (show ? "translateX(0)" : "translateX(-100%)")};

  @media (max-width: 1400px) {
    left: 0;
    width: var(--sidebar-width);
    transition: transform 0.4s;

    height: calc(100vh - var(--header-height));
  }
`;

export const DropNav = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Logo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  z-index: 1;
`;

export const AdaptiveLogo = styled(Image)`
  width: 186px;
  height: 36px;
  @media (max-width: 401px) {
    width: 156px;
  }
  @media (min-width: 1281px) and (max-width: 1400px) {
    width: 136px;
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 1400px) {
    width: 100%;
  }

  @media (max-width: 1540px) {
    position: absolute;
    right: 16px;
    justify-content: space-between;

    & ${DesktopView}, & ${MobileView} {
      display: none;
    }
  }
`;

export const MenuToggler = styled.div`
  background: #151c2c;
  border-radius: 8px;
  @media (max-width: 1540px) {
    margin-left: auto;
  }
`;

export const DropDownBack = styled.div`
  background: #131620;
  width: 100vw;
  height: calc(100vh - var(--header-height));
  padding: 32px 16px;
  overflow: scroll;
`;

const ProfileButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 32px;
  width: 100%;
  border: 0;
  border-radius: 8px;

  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  letter-spacing: 0.01em;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.7;
  }
`;

export const LogoutButton = styled(ProfileButton)`
  background: #c842300d;
  color: #c84230;
`;

export const SignInButton = styled(ProfileButton)`
  background: #51cb490d;
  color: #51cb49;
`;

export const MobileHeaderBlock = styled.div`
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 2px solid #e9f4ff0d;
`;
