import * as luxon from "luxon";
import Link from "next/link";
import React from "react";
import styled, { css } from "styled-components";
import { Item } from "./YoutubeFeedComponent";
import { $stylesReset, IStyles } from "components";

const Wrapper = styled.div`
  display: flex;
  mask-image: linear-gradient(90deg, rgba(0, 0, 0, 1) 87%, rgba(0, 0, 0, 0.1) 92%, transparent 95%);
`;

const Section = styled.section<{ duration: number }>`
  display: flex;
  animation: slide ${({ duration }) => duration}ms linear infinite backwards;
`;

export const SlidingVideos = ({
  videos = [],
  duration = 50000,
}: {
  videos: Item[];
  duration: number;
}) => {
  return (
    <>
      <Wrapper>
        {Array.from({ length: 2 }).map((_, sectionIndex) => (
          <Section key={sectionIndex} duration={duration}>
            {videos.map((item, index) => (
              <Card key={index} href={item.link} target="_blank" index={index}>
                <CardThumbnail
                  styles={css`
                    background-image: url(${item.thumbnail});
                  `}
                />
                <CardTitle>{item.title}</CardTitle>
                <CardDate>{castDate(item.pubDate)}</CardDate>
              </Card>
            ))}
          </Section>
        ))}
      </Wrapper>
    </>
  );
};

const Card = styled(Link)<IStyles & { range?: number; index?: number; current?: number }>`
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  gap: 16px;
  width: var(--Card-width);
  height: var(--Card-height);
  margin: 0 0 28px;
  margin-right: 24px;
  ${({ styles }) => styles}
`;

const CardThumbnail = styled.div<IStyles>`
  ${$stylesReset}
  height: calc( var(--Card-height) / 3 * 4 ); // cutting black stripes
  // background
  background-clip: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: var(--Card-width) var(--Card-height); // 343px 273px
  ${({ styles }) => styles}
`;

const CardTitle = styled.div<IStyles>`
  color: rgba(210, 234, 255, 0.75);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: left;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  ${({ styles }) => styles}
`;

const CardDate = styled.div<IStyles>`
  color: rgba(210, 234, 255, 0.25);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: left;

  ${({ styles }) => styles}
`;

const castDate = (date: string) => {
  const formatIn = "yyyy-MM-dd hh:mm:ss";
  const formatOut = "dd/MM/yyyy";
  return luxon.DateTime.fromFormat(date, formatIn).toFormat(formatOut);
};
