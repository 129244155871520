import React from "react";
import styled from "styled-components";
import { Flex } from "components/common";
import Image from "next/image";
import { TitleText } from "../Prizes";
import { TopTenTable } from "./TopTenTable";
import { IAppContext, withApp } from "contexts";

const Container = styled.div`
  position: relative;
  max-width: 1920px;
  margin: auto;
  overflow: hidden;
  width: 80%;
  padding-bottom: 50px;
  @media (max-width: 1280px) {
    width: 90%;
  }
`;

const TableContainer = styled.div`
  position: relative;
  margin: auto;
  border-radius: 10px;
  font-size: 16px;
  text-align: center;
  background-color: #131925;
  padding-bottom: 20px;
`;

const TopTenTableComponent: React.FC<IAppContext> = ({ accessFetch }): React.ReactElement => {
  const [data, setData] = React.useState<any>(null);

  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await accessFetch(`/pachinko-winner?sort=totalPrize:desc&skip=0&take=10`);
        const data = await response?.json();
        setData(data.items);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [accessFetch]);

  return (
    <Container>
      <Flex justify="center" align="center" width="100%">
        <Image src="/images/PachinkoComponent/crown.png" height={60} width={60} alt="image" />
        <TitleText style={{ marginTop: 15 }}>TOP-10 LUCKIEST WINNERS</TitleText>
      </Flex>
      <TableContainer>
        <TopTenTable
          $keys={["place", "username", "totalPrize", "createdAt", "updateAt"]}
          users={data ?? []}
          isLoading={isLoading}
        />
      </TableContainer>
    </Container>
  );
};

export default withApp(TopTenTableComponent);
