import React from 'react';
import styled, { css, } from 'styled-components';
import {
	IStyles,
} from 'components';
import { ILeaderboardWithWinnerDto } from 'interfaces';
import { LeaderboardHistoryCard } from 'components/leaderboard/LeaderboardHistoryCard';

export interface ILeaderboardTopWinnerCardList {
	leaderboards: ILeaderboardWithWinnerDto[];
	doHref?: (leaderboard?: ILeaderboardWithWinnerDto) => string;
}

export const LeaderboardTopWinnerCardList: React.FC<ILeaderboardTopWinnerCardList> = ({
	leaderboards,
	doHref,
}): React.ReactElement => {

	if (!leaderboards.length) {
		return (<></>);
	}

	return (
		<Box>
			<List gap={ 24 }>
				{
					leaderboards.map((leaderboard, index) => {
						return (
							<Item key={ index } order={ index + 1 }>
								<LeaderboardHistoryCard leaderboard={leaderboard} doHref={doHref}/>
							</Item>
						);
					})
				}
			</List>
		</Box>
	);
};

const Box = styled.div<IStyles>`
	${ ({ styles }) => styles }
`;

const List = styled.div<IStyles & { gap?: number; }>`
	--gap: ${ ({ gap, }) => gap ?? 24 }px;
	--card-width: 315px;
	--cards-amount: 3;
	box-sizing: border-box;
	display: flex;
	flex-flow: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;
	gap: var(--gap);
	max-width: calc( ( var(--card-width) + var(--gap) ) * var(--cards-amount) );
	margin: 0 auto;
	${ ({ styles }) => styles }
`;

const Item = styled.div<IStyles & { order: number; }>`
	box-sizing: border-box;
	display: inline-block;
	${ ({ order, }) => css`order: ${ order };` }
	${ ({ styles }) => styles }
`;