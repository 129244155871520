import { IStyles } from "components/common";
import styled from "styled-components";

export const Card = styled.div<IStyles & { live?: boolean }>`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #e9f4ffbf;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;

  background: ${({ live }) => (live ? "#5ebd3d11" : "#C8423026")};

  ${({ styles }) => styles}
`;
