import React from "react";
import styled from "styled-components";
import { $styles, IStyles } from "./$styles";

const SelectInput = styled.input<IStyles>`
  color: #d2eaff;
  background: transparent;
  border: none;
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  width: calc(100% - 20px);
  &:focus {
    border: none;
    outline: none;
  }
  &::placeholder {
    color: #8999ab;
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.02em;
    line-height: 150%;
  }
  ${$styles}
`;

const Select = styled.div<IStyles>`
  width: 100%;
  height: 50px;
  padding: 15px;
  border: 1px solid rgba(36, 39, 70, 0.5);
  border-radius: 8px;
  background-color: #171b2d;
  color: #464e62;
  font-size: 16px;
  outline: none;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background-image: url("/images/PachinkoComponent/arrow-select.png");
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-position: 94% center;
  background-size: 10px 6px;
  &:hover {
    border-color: #3b8be9;
  }
  ${$styles}
`;

const SelectContainer = styled.div`
  position: relative;
`;

const Dropdown = styled.div`
  position: absolute;
  z-index: 1;
  background-color: #171b2d;
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  border: 1px solid rgba(36, 39, 70, 0.5);
`;

const DropdownItem = styled.div`
  height: 50px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-item: center;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  &:active {
    opacity: 0.5;
  }
`;

export type DropdownSelectProps<T> = {
  options: { label: string; value: T }[];
  onUpdateOptions?: () => void | Promise<void>;
  onSearch?: (value: string) => void | Promise<void>;
  selectedValue?: { label: string; value: T };
  onSelect?: (data: T) => void;
  customOption?: (
    key: string | number,
    option: { label: string; value: any },
    onSelect: (data: { label: string; value: any }) => void
  ) => React.ReactNode;
};

const DropdownSelect: React.FC<DropdownSelectProps<any>> = ({
  options,
  onUpdateOptions,
  onSearch,
  onSelect,
  selectedValue,
  customOption,
}) => {
  const [actualValue, setActualValue] = React.useState<{ label: string; value: any } | null>(
    selectedValue ?? { label: "Select...", value: null }
  );
  const [isOpenDropdown, setIsOpenDropdown] = React.useState<boolean>(false);
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const [searchValue, setSearchValue] = React.useState<string>("");

  const toggleDropdown = () => {
    setIsOpenDropdown((prev) => !prev);
  };

  const handleScroll = React.useCallback(async () => {
    if (onUpdateOptions) {
      const dropdownContainer = dropdownRef.current;

      if (
        dropdownContainer &&
        dropdownContainer.scrollTop + dropdownContainer.clientHeight ===
          dropdownContainer.scrollHeight
      ) {
        await onUpdateOptions();
      }
    }
  }, [onUpdateOptions]);

  React.useEffect(() => {
    const dropdownContainer = dropdownRef.current;

    if (dropdownContainer) {
      dropdownContainer.addEventListener("scroll", handleScroll);

      return () => {
        dropdownContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll, isOpenDropdown]);

  return (
    <SelectContainer>
      <Select onClick={toggleDropdown}>
        <SelectInput
          placeholder={actualValue?.label}
          disabled={!Boolean(onSearch)}
          value={searchValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchValue(e.target.value);
            onSearch?.(e.target.value);
            setIsOpenDropdown(true);
          }}
        />
      </Select>
      {isOpenDropdown && (
        <Dropdown ref={dropdownRef}>
          {options.map(
            (option: any, index: number) =>
              customOption?.(index, option, () => {
                setActualValue(option);
                onSelect?.(option);
                toggleDropdown();
              }) ?? (
                <DropdownItem
                  key={index}
                  onClick={() => {
                    setActualValue(option);
                    onSelect?.(option);
                    toggleDropdown();
                  }}
                >
                  {option.label}
                </DropdownItem>
              )
          )}
        </Dropdown>
      )}
    </SelectContainer>
  );
};

export default DropdownSelect;
