import { SVGProps } from "react";

export type GiftProps = SVGProps<SVGSVGElement>;

const Gift: React.FC<GiftProps> = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0997 6.10658H18.0354C18.3643 5.38073 18.3681 4.55538 18.0007 3.83568C17.1164 2.08848 14.6877 1.89874 13.5375 3.47116L11.9989 5.56212L10.4611 3.47271C9.30483 1.89089 6.87641 2.09834 5.99865 3.83258C5.62996 4.555 5.63374 5.38063 5.96256 6.10658H3.89298C3.11634 6.10658 2.49023 6.74062 2.49023 7.51726V9.0151C2.49023 9.79173 3.11634 10.4178 3.89298 10.4178H20.0997C20.8764 10.4178 21.5103 9.79173 21.5103 9.0151V7.51726C21.5103 6.74062 20.8764 6.10658 20.0997 6.10658ZM14.8152 4.40916C15.2653 3.79404 16.2327 3.85034 16.5875 4.55388C16.7562 4.88435 16.7439 5.25584 16.5519 5.57315C16.36 5.89201 16.0357 6.07697 15.6666 6.08162L13.5739 6.09555L14.8152 4.40916ZM7.41184 4.55079C7.76244 3.85566 8.72963 3.78975 9.18336 4.41071L10.424 6.09555L8.33591 6.08162C7.55968 6.07198 7.0507 5.25828 7.41184 4.55079Z"
        fill={props.fill ?? "#E9F4FF"}
      />
      <path
        d="M3.83008 19.2781C3.83008 20.5618 4.87614 21.6001 6.16009 21.6001H17.8416C19.1253 21.6001 20.1715 20.5618 20.1715 19.2781V12.0029H3.83008V19.2781Z"
        fill={props.fill ?? "#E9F4FF"}
      />
    </svg>
  );
};

export default Gift;
