import React from 'react';
import styled from 'styled-components';

const Chat = () => {
    return (
        <>
            <ChatHeader>
                Chat
            </ChatHeader>
        </>
    );
};

const ChatHeader = styled.div`
    display: flex;
    height: 48px;
    padding: 16px;
    color: var(--clouds-100);
    font-family: var(--font-family-golos);
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    background-color: var(--global-bg-dark-2);
`;

export default Chat;
