import React from "react";
import Image from "next/image";
import Link from "next/link";
import styled, { css } from "styled-components";
import { $stylesReset, IStyles, TheButton, TheIcon, TheIconLink } from "components";
import { Stream } from "helpers/types";
import { IAppContext, withApp } from "contexts";
import { Button } from "components/common";
import {
  HyusLinks,
  WatchGamesTVLinks,
  WATCH_GAMES_USERNAME,
  HYUS_USERNAME,
  WREWARDS_USERNAME,
  WRewardsLinks,
  PKLE_USERNAME,
  PkleLinks,
} from "helpers/constants";
import { ITheIconLink } from "interfaces";
import { Roll } from "react-awesome-reveal";

export interface IFeaturedCreatorsComponent {
  id?: string;
  nextScrollHref?: string;
}

const FeaturedCreatorsComponent: React.FC<IFeaturedCreatorsComponent & IAppContext> = ({
  id,
  liveStreams,
}): JSX.Element => {
  const watchgamestvLiveStream = liveStreams?.find(
    (el: Stream) => el.isLive === true && el.streamer === WATCH_GAMES_USERNAME
  );

  const hyusLiveStream = liveStreams?.find(
    (el: Stream) => el.isLive === true && el.streamer === HYUS_USERNAME
  );

  const wrewardsLiveStream = liveStreams?.find(
    (el: Stream) => el.isLive === true && el.streamer === WREWARDS_USERNAME
  );

  const pkleLiveStream = liveStreams?.find(
    (el: Stream) => el.isLive === true && el.streamer === PKLE_USERNAME
  );

  return (
    <Box id={id}>
      <Screen>
        <AbsoluteDiv />
        <Content>
          <Img src="/images/icons/gift-box.svg" width={83} height={88} alt="twitch" />
          <TextTitle>
            <span data-blue>Featured</span>
            &nbsp; creators
          </TextTitle>
          <Text>
            <br />
            Follow the socials to enjoy exclusive content & giveaways!
            <br />
            Watching streams earns you points!
            <br />
            <br />
            <br />
          </Text>
          <Roll style={{ width: "90%", margin: "auto" }} triggerOnce>
            <TheCreators>
              <TheCreator styles={cssCreator1}>
                {watchgamestvLiveStream ? (
                  <AbsoluteCard>
                    <Img
                      src="/images/FeaturedCreatorsComponent/live.png"
                      width={15}
                      height={15}
                      alt="stream"
                      styles={css`
                        margin-right: 5px;
                      `}
                    />
                    <LiveText>LIVE</LiveText>
                  </AbsoluteCard>
                ) : null}
                <TheCreatorFace styles={cssCreator1Face} />
                <TheCreatorName>Watchgamestv</TheCreatorName>
                <TheCreatorSocial>
                  <div data-stay>Stay up to date</div>
                  <div data-links>
                    <TheIconLink target="_blank" href={WatchGamesTVLinks.discord}>
                      <TheIcon icon="discord:social" />
                    </TheIconLink>
                    <TheIconLink target="_blank" href={WatchGamesTVLinks.youtube}>
                      <TheIcon icon="youtube:social" />
                    </TheIconLink>
                    <TheIconLink target="_blank" href={WatchGamesTVLinks.twitter}>
                      <TheIcon icon="twitter:social" />
                    </TheIconLink>
                    <TheIconLink
                      data-active={
                        watchgamestvLiveStream ? watchgamestvLiveStream.platform === "kick" : false
                      }
                      target="_blank"
                      href={WatchGamesTVLinks.kick}
                    >
                      <TheIcon icon="kick:social" />
                    </TheIconLink>
                    <TheIconLink
                      target="_blank"
                      data-active={
                        watchgamestvLiveStream
                          ? watchgamestvLiveStream.platform === "twitch"
                          : false
                      }
                      href={WatchGamesTVLinks.twitch}
                    >
                      <TheIcon icon="twitch:social" />
                    </TheIconLink>
                    <TheIconLink target="_blank" href={WatchGamesTVLinks.instagram}>
                      <TheIcon icon="instagram:social" />
                    </TheIconLink>
                  </div>
                  <MyBlueLink isLive={!!watchgamestvLiveStream}>
                    {watchgamestvLiveStream ? (
                      <TheButton
                        target="_blank"
                        href={WatchGamesTVLinks[watchgamestvLiveStream.platform]}
                        width={"100%"}
                      >
                        Watch stream
                      </TheButton>
                    ) : (
                      <Button
                        styles={css`
                          pointer-events: none;
                        `}
                      >
                        Watch stream
                      </Button>
                    )}
                  </MyBlueLink>
                </TheCreatorSocial>
              </TheCreator>
              <TheCreator styles={cssCreator2}>
                {hyusLiveStream ? (
                  <AbsoluteCard>
                    <Img
                      src="/images/FeaturedCreatorsComponent/live.png"
                      width={15}
                      height={15}
                      alt="stream"
                      styles={css`
                        margin-right: 5px;
                      `}
                    />
                    <LiveText>LIVE</LiveText>
                  </AbsoluteCard>
                ) : null}
                <TheCreatorFace styles={cssCreator2Face} />
                <TheCreatorName>hyus</TheCreatorName>
                <TheCreatorSocial>
                  <div data-stay>Stay up to date</div>
                  <div data-links>
                    <HyusIconLink target="_blank" href={HyusLinks.discord}>
                      <TheIcon icon="discord:social" />
                    </HyusIconLink>
                    <HyusIconLink target="_blank" href={HyusLinks.youtube}>
                      <TheIcon icon="youtube:social" />
                    </HyusIconLink>
                    <HyusIconLink target="_blank" href={HyusLinks.twitter}>
                      <TheIcon icon="twitter:social" />
                    </HyusIconLink>
                    <HyusIconLink
                      data-active={hyusLiveStream ? hyusLiveStream.platform === "kick" : false}
                      target="_blank"
                      href={HyusLinks.kick}
                    >
                      <TheIcon icon="kick:social" />
                    </HyusIconLink>
                    <HyusIconLink
                      data-active={hyusLiveStream ? hyusLiveStream.platform === "twitch" : false}
                      target="_blank"
                      href={HyusLinks.twitch}
                    >
                      <TheIcon icon="twitch:social" />
                    </HyusIconLink>
                    <HyusIconLink target="_blank" href={HyusLinks.instagram}>
                      <TheIcon icon="instagram:social" />
                    </HyusIconLink>
                  </div>
                  <MyOrangeLink isLive={!!hyusLiveStream}>
                    {hyusLiveStream ? (
                      <TheButton
                        target="_blank"
                        href={HyusLinks[hyusLiveStream.platform]}
                        width={"100%"}
                      >
                        Watch stream
                      </TheButton>
                    ) : (
                      <Button
                        styles={css`
                          pointer-events: none;
                        `}
                      >
                        Watch stream
                      </Button>
                    )}
                  </MyOrangeLink>
                </TheCreatorSocial>
              </TheCreator>

              <TheCreator styles={cssCreator3}>
                {pkleLiveStream ? (
                  <AbsoluteCard>
                    <Img
                      src="/images/FeaturedCreatorsComponent/live.png"
                      width={15}
                      height={15}
                      alt="stream"
                      styles={css`
                        margin-right: 5px;
                      `}
                    />
                    <LiveText>LIVE</LiveText>
                  </AbsoluteCard>
                ) : null}
                <TheCreatorFace styles={cssCreator3Face} />
                <TheCreatorName>PKLE</TheCreatorName>
                <TheCreatorSocial>
                  <div data-stay>Stay up to date</div>
                  <div data-links>
                    <PkleIconLink target="_blank" href={PkleLinks.discord}>
                      <TheIcon icon="discord:social" />
                    </PkleIconLink>
                    <PkleIconLink target="_blank" href={PkleLinks.youtube}>
                      <TheIcon icon="youtube:social" />
                    </PkleIconLink>
                    <PkleIconLink target="_blank" href={PkleLinks.twitter}>
                      <TheIcon icon="twitter:social" />
                    </PkleIconLink>
                    <PkleIconLink
                      data-active={pkleLiveStream ? pkleLiveStream.platform === "kick" : false}
                      target="_blank"
                      href={PkleLinks.kick}
                    >
                      <TheIcon icon="kick:social" />
                    </PkleIconLink>
                    <PkleIconLink
                      target="_blank"
                      data-active={pkleLiveStream ? pkleLiveStream.platform === "twitch" : false}
                      href={PkleLinks.twitch}
                    >
                      <TheIcon icon="twitch:social" />
                    </PkleIconLink>
                    <PkleIconLink target="_blank" href={PkleLinks.instagram}>
                      <TheIcon icon="instagram:social" />
                    </PkleIconLink>
                  </div>
                  <MyGreenLink isLive={!!pkleLiveStream}>
                    {pkleLiveStream ? (
                      <TheButton
                        target="_blank"
                        href={PkleLinks[pkleLiveStream.platform]}
                        width={"100%"}
                      >
                        Watch stream
                      </TheButton>
                    ) : (
                      <Button
                        styles={css`
                          pointer-events: none;
                        `}
                      >
                        Watch stream
                      </Button>
                    )}
                  </MyGreenLink>
                </TheCreatorSocial>
              </TheCreator>

              <TheCreator styles={cssCreator4}>
                {wrewardsLiveStream ? (
                  <AbsoluteCard>
                    <Img
                      src="/images/FeaturedCreatorsComponent/live.png"
                      width={15}
                      height={15}
                      alt="stream"
                      styles={css`
                        margin-right: 5px;
                      `}
                    />
                    <LiveText>LIVE</LiveText>
                  </AbsoluteCard>
                ) : null}
                <TheCreatorFace styles={cssCreator4Face} />
                <TheCreatorName>Wrewards</TheCreatorName>
                <TheCreatorSocial>
                  <div data-stay>Stay up to date</div>
                  <div data-links>
                    <TheIconLink target="_blank" href={WRewardsLinks.discord}>
                      <TheIcon icon="discord:social" />
                    </TheIconLink>
                    <TheIconLink target="_blank" href={WRewardsLinks.youtube}>
                      <TheIcon icon="youtube:social" />
                    </TheIconLink>
                    <TheIconLink target="_blank" href={WRewardsLinks.twitter}>
                      <TheIcon icon="twitter:social" />
                    </TheIconLink>
                    <TheIconLink
                      data-active={
                        wrewardsLiveStream ? wrewardsLiveStream.platform === "kick" : false
                      }
                      target="_blank"
                      href={WRewardsLinks.kick}
                    >
                      <TheIcon icon="kick:social" />
                    </TheIconLink>
                    <TheIconLink
                      target="_blank"
                      data-active={
                        wrewardsLiveStream ? wrewardsLiveStream.platform === "twitch" : false
                      }
                      href={WRewardsLinks.twitch}
                    >
                      <TheIcon icon="twitch:social" />
                    </TheIconLink>
                    <TheIconLink target="_blank" href={WRewardsLinks.instagram}>
                      <TheIcon icon="instagram:social" />
                    </TheIconLink>
                  </div>
                  <MyBlueLink isLive={!!wrewardsLiveStream}>
                    {wrewardsLiveStream ? (
                      <TheButton
                        target="_blank"
                        href={WRewardsLinks[wrewardsLiveStream.platform]}
                        width={"100%"}
                      >
                        Watch stream
                      </TheButton>
                    ) : (
                      <Button
                        styles={css`
                          pointer-events: none;
                        `}
                      >
                        Watch stream
                      </Button>
                    )}
                  </MyBlueLink>
                </TheCreatorSocial>
              </TheCreator>
            </TheCreators>
          </Roll>
        </Content>
      </Screen>
    </Box>
  );
};

export default withApp(FeaturedCreatorsComponent);

const Box = styled.div`
  ${$stylesReset}
  background-color: #0b0e16;
`;

const LiveText = styled.h1`
  font-family: Roboto;
  font-size: 16px;
  font-style: italic;
  font-weight: 900;
  line-height: 19px;
  letter-spacing: 0.03em;
  text-align: left;
  margin: 0;
`;

const cssHyusActive = css`
  border-color: #fe7503;
`;

const cssPkleActive = css`
  border-color: #a6f621;
`;

const HyusIconLink = styled(Link)<ITheIconLink>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border: 1px solid rgba(210, 234, 255, 0.25);
  border-radius: 62px;

  &:hover {
    ${cssHyusActive};
  }

  ${({ "data-active": active }) => (active ? cssHyusActive : null)}

  ${({ styles }) => styles}
`;

const PkleIconLink = styled(HyusIconLink)<ITheIconLink>`
  &:hover {
    ${cssPkleActive};
  }
  ${({ "data-active": active }) => (active ? cssPkleActive : null)}

  ${({ styles }) => styles}
`;

const Screen = styled.div`
  ${$stylesReset}

  position: relative;
  /* max-width: 1920px; */
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  overflow: hidden;
`;

const Content = styled.div`
  ${$stylesReset}
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 24px 0px;
  @media (min-width: 800px) {
    padding-bottom: 112px;
  }
`;

const Text = styled.div`
  ${$stylesReset}
  color: rgba(210, 234, 255, 0.75);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: center;
`;

const AbsoluteCard = styled.div`
  position: absolute;
  top: 20px;
  right: 15px;
  display: flex;
  align-items: center;
  justifycontent: center;
  border: 1px white solid;
  border-radius: 12px;
  padding: 5px 10px;
`;

const TextTitle = styled(Text)`
  color: rgb(210, 234, 255); // #d2eaff;
  font-size: 40px;
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  & [data-blue] {
    color: #3b8be9;
  }
  @media (max-width: 800px) {
    color: #d2eaff;
    font-size: 28px;
    /* line-height: 130%;
		letter-spacing: 0.84px; */
  }
`;

const Img = styled(Image)<IStyles>`
  ${({ styles }) => styles}
`;

const TheCreators = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  text-align: center;
`;

const TheCreator = styled.div<IStyles>`
  box-sizing: border-box;
  position: relative;
  width: 33%;
  max-width: 450px;
  height: 490px;
  overflow: hidden;
  background-color: #0b101b;
  background-origin: border-box;
  background-position: top;
  background-size: 100% 264px;
  background-repeat: no-repeat;
  border: 1px solid rgba(210, 234, 255, 0.1);
  border-radius: 8px;
  margin-bottom: 30px; /* Adjust the gap between rows */

  @media (max-width: calc( 1280px - 1px )) {
    width: 600px;
  }
  @media (max-width: calc( 650px - 1px )) {
    width: 450px;
  }
  @media (max-width: calc( 480px - 1px )) {
    width: 350px;
  }

  ${({ styles }) => styles}
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.03);
  }
`;

const TheCreatorFace = styled.div<IStyles>`
  display: inline-block;
  width: 191px;
  height: 178px;
  margin: 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;

  &[data-border] {
    border: 0.5px solid white;
  }

  ${({ styles }) => styles}
`;

const TheCreatorName = styled.div<IStyles>`
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  ${({ styles }) => styles}
`;
const AbsoluteDiv = styled.div`
  position: absolute;
  top: 0;
  display: block;
  width: 100vw;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(12, 14, 22, 0), rgba(12, 14, 22, 1)),
    url("/images/FeaturedCreatorsComponent/vector-new.png");
  animation: bgScrollX 100s linear infinite;
`;

const TheCreatorSocial = styled.div<IStyles>`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 226px;
  background: radial-gradient(50% 50% at 50% 50%, rgba(101, 101, 101, 0.05), rgba(101, 101, 101, 0)),
    #101622;
  font-size: 16px;

  & [data-stay] {
    margin-top: 32px;
    letter-spacing: 0.02em;
    line-height: 150%;
    font-weight: 500;
    color: rgba(210, 234, 255, 0.75);
    text-align: center;
  }

  /* Default styling for a single row */
  [data-links] {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 16px 0 40px;

    @media (max-width: 768px) {
      gap: 15px;
    }
  }

  ${({ styles }) => styles}
`;

const cssCreator1 = css`
  background-image: url("/images/FeaturedCreatorsComponent/vector2@2x.png");
`;

const cssCreator2 = css`
  background-image: url("/images/FeaturedCreatorsComponent/hyus_bg@2x.png");
`;

const cssCreator3 = css`
  background-image: url("/images/FeaturedCreatorsComponent/pkle-creator-bg.png");
`;

const cssCreator4 = css`
  background-image: url("/images/FeaturedCreatorsComponent/wrewards-creator-bg.png");
`;

const cssCreator1Face = css`
  background-image: url("/images/FeaturedCreatorsComponent/vector3@2x.png");
  background-size: 191px 178px;
`;

const cssCreator2Face = css`
  background-image: url("/images/FeaturedCreatorsComponent/hyus_new@2x.png");
  background-size: 148px 154px;
`;

const cssCreator3Face = css`
  background-image: url("/images/FeaturedCreatorsComponent/pkle-creator.png");
  background-size: 130px 130px;
`;

const cssCreator4Face = css`
  background-image: url("/images/FeaturedCreatorsComponent/wrewards-creator.svg");
  background-size: 113px 165px;
`;

const MyLink = styled.div`
  margin: 24px;
  & > a {
    box-shadow: inset 0px 1px 0px #8ac7ff;
  }
  color: #d2eaff;
  text-transform: uppercase;
`;

const MyBlueLink = styled(MyLink)<{ isLive: boolean }>`
  & > a {
    background: #3b8be9;
  }
  & > div {
    background: #3b8be9;
    opacity: 0.7;
  }
`;

const MyPurpleLink = styled(MyLink)<{ isLive: boolean }>`
  & > a {
    background: #e53be9;
    opacity: ${({ isLive }) => (isLive ? "1" : "0.7")};
  }
  & > div {
    background: #e53be9;
    opacity: ${({ isLive }) => (isLive ? "1" : "0.7")};
  }
`;

const MyOrangeLink = styled(MyLink)<{ isLive: boolean }>`
  & > a {
    background: #fe7503;
    opacity: ${({ isLive }) => (isLive ? "1" : "0.7")};
  }
  & > div {
    background: #fe7503;
    opacity: ${({ isLive }) => (isLive ? "1" : "0.7")};
  }
`;

const MyGreenLink = styled(MyLink)<{ isLive: boolean }>`
  & > a {
    background: #749b33;
    opacity: ${({ isLive }) => (isLive ? "1" : "0.7")};
  }
  & > div {
    background: #749b33;
    opacity: ${({ isLive }) => (isLive ? "1" : "0.7")};
  }
`;
